import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { FormattedMessage, FormattedDate } from 'react-intl';
import category from './Category';

let counter = 0;
function createData(oid, name, description, organization, created_at) {
  counter += 1;
  return { id: counter, oid, name, description, organization, created_at };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name', disablePadding: true, label: <FormattedMessage id="organizations.name" /> },
  { id: 'description', disablePadding: false, label: <FormattedMessage id="organizations.description" /> },
  { id: 'created_at', disablePadding: false, label: <FormattedMessage id="created" /> }
];

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              checkedIcon={<CheckBoxIcon fontSize="small" />} icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            />
          </TableCell>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} seleccionada
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            <FormattedMessage id="categories.list_title" />
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Eliminar">
            <IconButton aria-label="Eliminar" onClick={event => props.deleteAction()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={<FormattedMessage id="categories.add" />}>
            <IconButton aria-label={<FormattedMessage id="categories.add" />} onClick={props.action}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    cursor: 'pointer',
  },
  selectControl: {
    width: '100%',
  },
  dialogStyle: {
    borderRadius: 0
  }
});

class EnhancedTable extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      OIDselected: [],
      data: [],
      page: 0,
      name: '',
      description: '',
      organization_id: '',
      organizations: [],
      rowsPerPage: 5,
      fullWidth: true,
      maxWidth: 'sm',
      openDialog: false,
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="categories.add" />,
      openDelDialog: false
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.openDelDialog = this.openDelDialog.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDelClickClose = this.handleDelClickClose.bind(this);
  } 

  componentDidMount() {
    category.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) =>
      this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.name, n.description, n.organization, n.created_at)) }))
    );
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id), OIDselected: state.data.map(n => n.oid) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, oid, name, description, organization_id) => {
    this.setState({ openDialog: true, submitAction: 'edit', submitActionTitle: <FormattedMessage id="categories.editing" />, id: oid, name: name, description: description });
  };

  handleCheck = (event, id, oid) => {
    const { selected, OIDselected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newOIDSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newOIDSelected = newOIDSelected.concat(OIDselected, oid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newOIDSelected = newOIDSelected.concat(
        OIDselected.slice(0, selectedIndex),
        OIDselected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected, OIDselected: newOIDSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClickOpen() {

      this.setState(data => ({
        openDialog: true,
        submitAction: 'new',
        submitActionTitle: <FormattedMessage id="categories.add" />,
        id: '',
        name: '',
        organization_id: '',
        description: '',
      }))

  }

  handleClickClose() {
    this.setState({ openDialog: false, name: '', description: '', organization: '' });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
      category.create(this.state.name, this.state.description, localStorage.getItem('currentOrganization'), () => {
        category.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) => {
          this.setState(data => ({
              data: result.map(n => createData(n._id.$oid, n.name, n.description, n.organization, n.created_at)),
              id: '',
              name: '',
              description: '',
              SnackMessage: <FormattedMessage id="categories.saved" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    } else {
      category.update(this.state.id, this.state.name, this.state.description, localStorage.getItem('currentOrganization'), () => {
        category.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) => {
          this.setState(data => ({
              data: result.map(n => createData(n._id.$oid, n.name, n.description, n.organization, n.created_at)),
              id: '',
              name: '',
              description: '',
              SnackMessage: <FormattedMessage id="categories.updated" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    }
  }

  openDelDialog() {
    this.setState(data => ({ openDelDialog: true }));
  }

  handleDelClickClose() {
    this.setState({ openDelDialog: false });
  }

  handleDelete = () => {
    category.delete(this.state.OIDselected).then((result) => {
      category.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) => {
        this.setState(data => ({
            data: result.map(n => createData(n._id.$oid, n.name, n.description, n.organization, n.created_at)),
            id: '',
            name: '',
            description: '',
            SnackMessage: <FormattedMessage id="categories.deleted" />,
            openSnack: true,
            selected: [],
            OIDselected: []
          }));
          this.handleDelClickClose();
        }
      )
    })
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root} elevation={0} square={true}>
        <EnhancedTableToolbar numSelected={selected.length} action={this.handleClickOpen} deleteAction={this.openDelDialog} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      key={n.id}
                    >
                      <TableCell padding="checkbox" onClick={event => this.handleCheck(event, n.id, n.oid)} role="checkbox" aria-checked={isSelected} selected={isSelected}>
                        <Checkbox checked={isSelected} checkedIcon={<CheckBoxIcon fontSize="small" />} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none" onClick={event => this.handleClick(event, n.id, n.oid, n.name, n.description)} className={classes.tableCell}>
                        {n.name}
                      </TableCell>
                      <TableCell>{n.description}</TableCell>
                      <TableCell><FormattedDate value={new Date(n.created_at)} year='numeric' month='long' day='2-digit' /></TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={<FormattedMessage id="rows_per_page" />}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${ navigator.language === 'es-ES' ? 'de' : 'of' } ${count}`}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name"><FormattedMessage id="organizations.name" /></InputLabel>
                <Input id="name" value={this.state.name} onChange={this.handleNameChange} autoFocus />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="description"><FormattedMessage id="organizations.description" /></InputLabel>
                <Input id="description" value={this.state.description} onChange={this.handleDescriptionChange} multiline={true} rows="4" />
              </FormControl>                     
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>
        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.openDelDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Eliminar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>¿Estás seguro?</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              <FormattedMessage id="delete" />
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);