import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import ProfileTable from './profile/ProfileTable';
import HumanTable from './human/HumanTable';
import StructuralTable from './structural/StructuralTable'
import RelationalTable from './relational/RelationalTable';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing.unit * 3,
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  tabsRoot: {
    borderBottom: '1px solid #546e7a',
  },
  tabsIndicator: {
    backgroundColor: '#546e7a',
  },
});

class Show extends Component {

  constructor (props) {
    super(props);
    this.state = {
      value: 0,
    }
    
  } 

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>

        <Tabs value={value} textColor="inherit" onChange={this.handleTabChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
          <LinkTab textColor="inherit" label={<FormattedMessage id="resources.human" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="resources.structural" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="resources.relational" />} />
        </Tabs>

        <div>
          {value === 0 && <TabContainer>
            <ProfileTable spaceId={this.props.spaceId} />
            <HumanTable spaceId={this.props.spaceId} />                                      
          </TabContainer>}
          {value === 1 && <TabContainer>
            <StructuralTable spaceId={this.props.spaceId} />
          </TabContainer>}
          {value === 2 && <TabContainer>
            <RelationalTable spaceId={this.props.spaceId} />
          </TabContainer>}          
        </div>

      </div>
    );
  }
}

Show.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Show);