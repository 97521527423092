import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import auth from '../Auth'


const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing.unit,
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

class Header extends Component { 

  constructor (props) {
    super(props);
    this.state = {
      value: 0,
    }
    this.handleLogout = this.handleLogout.bind(this);
  } 

  handleLogout(e) {
    e.preventDefault();
    auth.logout(() => {
      this.props.history.push("/signin");
    })
  }

  render() {

    const { classes } = this.props;

    return(
    <div>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
        children={''}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={8}>
            <Grid item xs>
              <Typography color="inherit" variant="h5">
                { this.props.title || <FormattedMessage id="spaces.title" /> }
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
    )};
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);