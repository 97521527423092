import React, { Component, /* PureComponent */ } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, PieChart, Pie, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import space from '../Space';
import relational from '../resources/relational/RelationalCapital';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    //tableLayout: 'fixed',
    //minWidth: 700,
  },
  tableLegend: {
    tableLayout: 'fixed',
    maxWidth: 200,
  },
  dialogStyle: {
    borderRadius: 0
  }
});

const COLORS = ['#ffb74d', '#fff176', '#81c784', '#43a047'];

/* class CustomizedAxisTick extends PureComponent {
  render() {
    const {
      x, y, payload,
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)">{payload.value}</text>
      </g>
    );
  }
} */

class Relational extends Component {

  constructor (props) {
    super(props);
    this.state = {
      domains: [],
      humans: [],
      openDialog: false,
      progress: false,
      radarData: [],
      pieData: [],
      relational_capitals: [],
      name: '',
      ic: ''
    }
    this.ICcalc = this.ICcalc.bind(this);
    this.colorLevel = this.colorLevel.bind(this);
    this.domainsCount = this.domainsCount.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.mounted = false;
  } 

  componentDidMount() {
    this.mounted = true;
    this.setState({ progress : true });
    this.setUp();
  }

  componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.spaceId !== prevProps.spaceId) {
      this.setState({ progress : true });
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  setUp() {
    space.getFrequencyAndDpendency(this.props.spaceId).then((result) => {
      var domainResult = result.sort(function(a, b){
        var icA = this.ICcalc(a);
        var icB = this.ICcalc(b);
        return icB-icA
      }.bind(this));
      relational.getSpaceList(this.props.spaceId).then((result) => {
        if(this.mounted) {
          this.setState({ domains: domainResult,
                        frequency_levels: domainResult[0].frequency_levels,
                        dependency_levels: domainResult[0].dependency_levels,
                        relational_capitals: result, progress: false })
        }
      });
    });
  }

  handleClickOpen(name, ic, c, e, s, a, r) {
    this.setState({ openDialog: true, ic: ic, name: name, radarData: [
      { subject: 'Complejidad', A: c, B: c, fullMark: 5, },
      { subject: 'Escasez', A: e, B: e, fullMark: 5, },
      { subject: 'Singularidad', A: s, B: s, fullMark: 5, },
      { subject: 'Aplicabilidad', A: a, B: a, fullMark: 5, },
      { subject: 'Riesgo', A: r, B: r, fullMark: 5, },
    ] });
  }

  handleClickClose() {
    this.setState({ openDialog: false });
  }

  ICcalc(row) {

    var a = 0;
    var b = 0;
    var ic = 0;
    var total = 0;

    if (row.s1 === 4) {
      a = 0.05
    } 

    if (row.s3 === 4) {
      b = 0.05
    } 

    total = ((Math.sqrt(Math.pow(row.c,2) + Math.pow(row.e,2) + Math.pow(row.s,2) + Math.pow(row.a,2) + Math.pow(row.r,2)) + (row.s1 * 0.125) + (row.s3 * 0.125)) + a + b).toFixed(1);

    if (total === '10.0' ) {
      ic = 10;
    } else {
      ic = total;
    }

    return ic;
  }

  FrequencyCalc(frequency_levels) {
    var c = 0;
    var nonas = 0;
    frequency_levels.map((row, index) => {
      if (row.frequency_level !== 0) {
        c += row.frequency_level;
        nonas++;
      }
      return true
    })
    return Math.round((c / nonas).toFixed(1));
  }

  DependencyCalc(dependency_levels) {
    var c = 0;
    var nonas = 0;
    dependency_levels.map((row, index) => {
      if (row.dependency_level !== 0) {
        c += row.dependency_level;
        nonas++;
      }
      return true
    })
    return Math.round((c / nonas).toFixed(1));
  }

  domainsCount(level) {
    var count = 0;
    var ic = 0;
    this.state.domains.map((row) => {
      ic = this.ICcalc(row);
      if (level === 1) {
        if ( ic >= 8.5 ) {
          count++;
        }
      }
      if (level === 2) {
        if (ic >= 6.5 && ic < 8.5) {
          count++;
        }
      }
      if (level === 3) {
        if (ic >= 4 && ic < 6.5) {
          count++;
        }
      }
      if (level === 4) {
        if (ic >= 0 && ic < 4) {
          count++;
        }
      }
      return true
    });
    return count;
  }

  relationalCount(level) {
    var count = 0;
    var ic = 0;
    this.state.domains.map((row) => {
      ic = this.FrequencyCalc(row.frequency_levels);
      if (ic === level) {
        count++;
      }
      return true
    });
    return count;
  }

  frequencyCount() {
    var frequency = [];
    var x1 = 0;
    var x2 = 0;
    var x3 = 0;
    var x4 = 0;

    this.state.domains.map((row) => {
      if (this.FrequencyCalc(row.relational_capitals) === 1) {
        ++x1;
      } else if (this.FrequencyCalc(row.relational_capitals) === 2) {
        ++x2;
      } else if (this.FrequencyCalc(row.relational_capitals) === 3) {
        ++x3;
      } else if (this.FrequencyCalc(row.relational_capitals) === 4) {
        ++x4; 
      }
      return true    
    });

    frequency = [{ name: 'Baja', x: x1 }, { name: 'Media', x: x2 }, { name: 'Alta', x: x3 }, { name: 'Muy alta', x: x4 }];
    return frequency;
  }

  dependencyCount() {
    var dependency = [];
    var x1 = 0;
    var x2 = 0;
    var x3 = 0;
    var x4 = 0;

    this.state.domains.map((row) => {
      if (this.DependencyCalc(row.relational_capitals) === 1) {
        ++x1;
      } else if (this.DependencyCalc(row.relational_capitals) === 2) {
        ++x2;
      } else if (this.DependencyCalc(row.relational_capitals) === 3) {
        ++x3;
      } else if (this.DependencyCalc(row.relational_capitals) === 4) {
        ++x4; 
      }
      return true 
    });

    dependency = [{ name: 'Baja', x: x1 }, { name: 'Media', x: x2 }, { name: 'Alta', x: x3 }, { name: 'Muy alta', x: x4 }];
    return dependency;
  }

  colorLevel(ic) {
    if (ic >= 8.5) {
      return '#455a64';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#607d8b';
    } else if (ic >= 4 && ic < 6.5) {
      return '#90a4ae';
    } else if (ic >= 0 && ic < 4) {
      return '#cfd8dc';
    } else {
      return '#FFF';
    }
  }

  colorLevelFont(ic) {
    if (ic >= 8.5) {
      return '#FFF';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#FFF';
    } else if (ic >= 4 && ic < 6.5) {
      return '#000';
    } else if (ic >= 0 && ic < 4) {
      return '#000';
    } else {
      return '#FFF';
    }
  }

  colorStructuralFrequency(v) {
    switch (v) {
      case 1:
        return '#ffe0b2';
      case 2:
        return '#ffcc80';
      case 3:
        return '#ffb74d';
      case 4:
        return '#ffa726';
      case 0:
        return '#fff';                             
      default:
        return '#FFF';
    }
  }

  colorStructuralDependency(v) {
    switch (v) {
      case 1:
        return '#bbdefb';
      case 2:
        return '#90caf9';
      case 3:
        return '#64b5f6';
      case 4:
        return '#42a5f5';
      case 0:
        return '#fff';                             
      default:
        return '#FFF';
    }
  }

  agentCount() {
    var source = [];
    var x1 = 0;
    var x2 = 0;
    var x3 = 0;
    var x4 = 0;

    this.state.relational_capitals.map((row) => {
      if (row.agent === 1) {
        ++x1;
      } else if (row.agent === 2) {
        ++x2;
      } else if (row.agent === 3) {
        ++x3;
      } else if (row.agent === 4) {
        ++x4;
      }
      return true   
    });

    if (x1 > 0) {
      source.push({ name: 'Cliente', value: x1 })
    }
    if (x2 > 0) {
      source.push({ name: 'Colaborador', value: x2 })
    }
    if (x3 > 0) {
      source.push({ name: 'Proveedor', value: x3 })
    }
    if (x4 > 0) {
      source.push({ name: 'Red', value: x4 })
    }

    return source;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
      { this.state.progress ?
        <div>
          <br />
          <LinearProgress />
        </div>
      :      
      <div>
        <Grid container spacing={0} style={{ margin: 0 }}>
          <Grid item xs={5}>
            <Typography style={{ textAlign: 'center', margin: 30 }}>Frecuencia de Colaboración</Typography>
            <AreaChart
              width={420}
              height={300}
              data={this.frequencyCount()}
              margin={{
                top: 0, right: 0, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" width={2} />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="x" stroke="#ff7043" fill="#ffa726" />
            </AreaChart>
          </Grid>
          <Grid item xs={2}>
            <Paper className={classes.root} square={true} elevation={0}>
              <Table className={classes.tableLegend} align={'center'}>
                <colgroup>
                  <col width="8px" />
                  <col width="4px" />
                  <col width="40px" />
                  <col width="4px" />
                  <col width="8px" />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', padding: '0px', textAlign: 'center', fontSize: '12px', margin: '0px', float: 'none' }} rowSpan={5}><p style={{transform: 'rotate(180deg)', margin: '0px', padding: '0px', whiteSpace: 'nowrap', writingMode: 'vertical-rl' }}>Frecuencia de Colaboración</p></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238', padding: '0px' }} align={'center'}><FormattedMessage id="dependency.one" /></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', padding: '0px', textAlign: 'center', fontSize: '12px', margin: '0px', float: 'none' }} rowSpan={5}><p style={{transform: 'rotate(180deg)', margin: '0px', padding: '0px', whiteSpace: 'nowrap', writingMode: 'vertical-rl' }}>D. Dependencia</p></TableCell>
                  </TableRow>
                  <TableRow>

                    <TableCell style={{ backgroundColor: '#ffe0b2', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238', padding: '0px' }} align={'center'}><FormattedMessage id="dependency.two" /></TableCell>
                    <TableCell style={{ backgroundColor: '#bbdefb', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    
                  </TableRow>
                  <TableRow>
                    
                    <TableCell style={{ backgroundColor: '#ffcc80', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238', padding: '0px' }} align={'center'}><FormattedMessage id="dependency.three" /></TableCell>
                    <TableCell style={{ backgroundColor: '#90caf9', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    
                  </TableRow>
                  <TableRow>
                    
                    <TableCell style={{ backgroundColor: '#ffb74d', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238', padding: '0px' }} align={'center'}><FormattedMessage id="dependency.four" /></TableCell>
                    <TableCell style={{ backgroundColor: '#64b5f6', border: '1px solid #fff', color: '#263238', padding: '0px' }} align={'center'}></TableCell>
                    
                  </TableRow> 
                  <TableRow>
                    
                    <TableCell style={{ backgroundColor: '#ffa726', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238', padding: '0px' }} align={'center'}><FormattedMessage id="dependency.five" /></TableCell>
                    <TableCell style={{ backgroundColor: '#42a5f5', border: '1px solid #fff', color: '#263238', padding: '0px' }}></TableCell>
                    
                  </TableRow>        
                  </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ textAlign: 'center', margin: 30 }}>D. Dependencia</Typography>
            <AreaChart
              width={420}
              height={300}
              data={this.dependencyCount()}
              margin={{
                top: 0, right: 0, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="x" stroke="#42a5f5" fill="#bbdefb" />
            </AreaChart>
          </Grid>
        </Grid>
        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'} rowSpan={2} width={200}><FormattedMessage id="spaces.domains" /></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70} rowSpan={2}>IC</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={140} colSpan={2}>Datos Acumulados</TableCell>
                {this.state.relational_capitals.map((row, index) => (
                  <TableCell key={index + 1 + 'a'} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={140} colSpan={2}>{row.name}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>F</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>D</TableCell>
                {this.state.relational_capitals.map((row, index) => (
                  <React.Fragment key={index + 1 + 'b'}>
                  <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>F</TableCell>
                  <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>D</TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
              {this.state.domains.map((row, index) => (
              <TableRow hover key={index + 1 + 'd'}>
                <TableCell style={{ border: '1px solid #cfd8dc' }} width={200}>{row.name}</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), color: this.colorLevelFont(this.ICcalc(row)), padding: 0, border: '1px solid #cfd8dc' + this.colorLevel(this.ICcalc(row)) }} width={70}>{this.ICcalc(row)}</TableCell>
                <TableCell style={{ backgroundColor: this.colorStructuralFrequency(this.FrequencyCalc(row.relational_capitals)), border: '1px solid #cfd8dc', color: '#263238', padding: 0 }} align={'center'} width={70}>{ isNaN(this.FrequencyCalc(row.relational_capitals)) ? '--' : this.FrequencyCalc(row.relational_capitals) }</TableCell>
                <TableCell style={{ backgroundColor: this.colorStructuralDependency(this.DependencyCalc(row.relational_capitals)), border: '1px solid #cfd8dc', color: '#263238', padding: 0 }} align={'center'} width={70}>{ isNaN(this.DependencyCalc(row.relational_capitals)) ? '--' : this.DependencyCalc(row.relational_capitals)}</TableCell>
                {row.relational_capitals.map((c, index) => (
                  <React.Fragment key={index + 1 + 's'}>
                  <TableCell style={{ backgroundColor: this.colorStructuralFrequency(c.frequency_level), color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'} width={70}>{c.frequency_level === 0 ? 'N/A' : c.frequency_level}</TableCell>
                  <TableCell style={{ backgroundColor: this.colorStructuralDependency(c.dependency_level), color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'} width={70}>{c.dependency_level === 0 ? 'N/A' : c.dependency_level}</TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
              )) }
              </TableBody>
          </Table>
        </Paper>

        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>

          <Typography style={{ textAlign: 'left', marginTop: 30 }}>Distribución por tipo de agente</Typography>

            <PieChart width={500} height={300} onMouseEnter={this.onPieEnter}>
              <Pie
                data={this.agentCount()}
                cx={60}
                cy={180}
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                startAngle={180}
                endAngle={0}
                label
              >
                {
                  this.agentCount().map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
              <Legend verticalAlign="bottom" align="left" layout="vertical" />
            </PieChart>
            
          </Grid>
          <Grid item xs={12} sm={6}>

          </Grid>
        </Grid>

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="analysis.radar" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
                <b>{ this.state.name }</b>
                <p>Indice de Criticidad: <b>{ this.state.ic }</b></p>
            </DialogContentText>
            <RadarChart outerRadius={150} width={450} height={450} data={this.state.radarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" fontSize={14} />
              <PolarRadiusAxis fontSize={14} angle={90} domain={[0, 4]} />
              <Radar name="Cesar" dataKey="A" stroke="#4fc3f7" fill="#b3e5fc" fillOpacity={0.7} label={{ angle: 0 }} />
            </RadarChart>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>
        </div>
        }
      </div>
    );
  }
}

Relational.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Relational);