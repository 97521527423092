import React, { Component } from 'react';
import auth from './Auth';

export const KMapContext = React.createContext();
export const KMapConsumer = KMapContext.Consumer

class KMapProvider extends Component {

  state = {
    userName: auth.loggedIn() ? auth.getProfile().name : '',
    userEmail: auth.loggedIn() ? auth.getProfile().email : '',
    userOrganization: auth.loggedIn() ? auth.getProfile().organization : '',
    userRoles: auth.loggedIn() ? auth.getProfile().role : '',
    spaceList: false,
    updateUser: this.updateUser.bind(this),
    switchOrganization: this.switchOrganization.bind(this),
    updateSpaceList: this.updateSpaceList.bind(this)
  }

  updateUser(UpdatedUser) {
    this.setState(prevState => ({ userName: UpdatedUser.name, userEmail: UpdatedUser.email, userOrganization: UpdatedUser.organization, userRoles: UpdatedUser.role }))
    localStorage.setItem('currentOrganization', UpdatedUser.organization.id)
  }

  switchOrganization(SwitchedOrganization) {
    this.setState(prevState => ({ userOrganization: SwitchedOrganization }))
    localStorage.setItem('currentOrganization', SwitchedOrganization.oid)
  }

  updateSpaceList(b) {
    this.setState(prevState => ({ spaceList: b }))
  }
  
  render () {
    return (
      <KMapContext.Provider value={this.state}>
        {this.props.children}
      </KMapContext.Provider>
    )
  }
}
export default KMapProvider