import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, PieChart, Pie, Cell, Tooltip } from 'recharts';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import space from '../Space';
import activity from '../activities/Activity';
import challenge from '../challenges/Challenge';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    minWidth: 700,
  },
  tableBig: {
    //tableLayout: 'fixed',
    //minWidth: 700,
  },
  tableLegend: {
    tableLayout: 'fixed',
    maxWidth: 110,
  },
  dialogStyle: {
    borderRadius: 0
  }
});

const COLORS = [];

let counter = 0;
function createData(oid, title, impact, complexity, need, created_at) {
  counter += 1;
  return { id: counter, oid, title, impact, complexity, need, created_at };
}

class Functional extends Component {

  constructor (props) {
    super(props);
    this.state = {
      domains: [],
      activities: [],
      challenges: [],
      openDialog: false,
      progress: false,
      radarData: [],
      activityPieData: [],
      challengePieData: [],
      name: '',
      ic: ''
    }
    this.setUp = this.setUp.bind(this);
    this.ICcalc = this.ICcalc.bind(this);
    this.colorLevel = this.colorLevel.bind(this);
    this.activitiesCount = this.activitiesCount.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
  } 

  componentDidMount() {
    this.setState({ progress : true });
    this.setUp();
  }

  componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.spaceId !== prevProps.spaceId) {
      this.setState({ progress : true });
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    space.getFunctional(this.props.spaceId).then((result) => {
      this.setState(data => ({ domains: 
                                result.sort(function(a, b){
                                  var icA = this.ICCCalc(a);
                                  var icB = this.ICCCalc(b);
                                  return icB-icA
                                }.bind(this))
                            }));
      this.setState({
        activityPieData : this.CreateActivityPieData(), challengePieData : this.CreateChallengePieData(), progress: false
      });
    });
    activity.getSpaceList(this.props.spaceId).then((result) =>
      this.setState(data => ({ activities: result.sort(function(a, b){
        var icA = this.ICcalc(a);
        var icB = this.ICcalc(b);
        return icB-icA
      }.bind(this)).map(n => createData(n._id.$oid, n.title, n.impact, n.complexity, n.need, n.created_at)) }))
    );
    challenge.getSpaceList(this.props.spaceId).then((result) =>
      this.setState(data => ({ challenges: result.sort(function(a, b){
        var icA = this.ICcalc(a);
        var icB = this.ICcalc(b);
        return icB-icA
      }.bind(this)).map(n => createData(n._id.$oid, n.title, n.impact, n.complexity, n.need, n.created_at)) }))
    );
  }

  handleClickOpen(name, ic, impact, complexity, need) {
    this.setState({ openDialog: true, ic: ic, name: name, radarData: [
      { subject: 'Nivel de impacto en oferta de valor', A: impact, B: impact, fullMark: 5, },
      { subject: 'Nivel de complejidad', A: complexity, B: complexity, fullMark: 5, },
      { subject: 'Necesidad de atención / mejora', A: need, B: need, fullMark: 5, },
    ] });
  }

  handleClickClose() {
    this.setState({ openDialog: false });
  }

  ICcalc(row) {

    //var a = 0;
    //var b = 0;
    var ic = 0;
    var total = 0;

    //if (row.s1 === 4) {
    //  a = 0.05
    //} 

    //if (row.s3 === 4) {
    //  b = 0.05
    //} 

    total = ((row.impact * 0.4 + row.complexity * 0.3 + row.need * 0.3) * 10 /4).toFixed(1);

    if (total === '10.0' ) {
      ic = 10;
    } else {
      ic = total;
    }

    return ic;
  }

  ICCCalc(row) {

    var a = 0;
    var b = 0;
    var ic = 0;
    var total = 0;

    if (row.s1 === 4) {
      a = 0.05
    } 

    if (row.s3 === 4) {
      b = 0.05
    } 

    total = ((Math.sqrt(Math.pow(row.c,2) + Math.pow(row.e,2) + Math.pow(row.s,2) + Math.pow(row.a,2) + Math.pow(row.r,2)) + (row.s1 * 0.125) + (row.s3 * 0.125)) + a + b).toFixed(1);

    if (total === '10.0' ) {
      ic = 10;
    } else {
      ic = total;
    }

    return ic;
  }

  interferenceCalc(interferences) {
    var total = 0;
    interferences.map((row) => {
      total += row.value;
      return true
    });
    return Math.round(total / interferences.length);
  }

  colorInterference(v) {
    switch (v) {
      case 1:
        return '#bbdefb';
      case 2:
        return '#90caf9';
      case 3:
        return '#64b5f6';
      case 4:
        return '#42a5f5';                             
      default:
        return '#FFF';
    }
  }

  activitiesCount(level) {
    var count = 0;
    var ic = 0;
    this.state.activities.map((row) => {
      ic = this.ICcalc(row);
      if (level === 1) {
        if ( ic >= 8.5 ) {
          count++;
        }
      }
      if (level === 2) {
        if (ic >= 6.5 && ic < 8.5) {
          count++;
        }
      }
      if (level === 3) {
        if (ic >= 4 && ic < 6.5) {
          count++;
        }
      }
      if (level === 4) {
        if (ic >= 0 && ic < 4) {
          count++;
        }
      }
      return true
    });
    return count;
  }

  challengesCount(level) {
    var count = 0;
    var ic = 0;
    this.state.challenges.map((row) => {
      ic = this.ICcalc(row);
      if (level === 1) {
        if ( ic >= 8.5 ) {
          count++;
        }
      }
      if (level === 2) {
        if (ic >= 6.5 && ic < 8.5) {
          count++;
        }
      }
      if (level === 3) {
        if (ic >= 4 && ic < 6.5) {
          count++;
        }
      }
      if (level === 4) {
        if (ic >= 0 && ic < 4) {
          count++;
        }
      }
      return true
    });
    return count;
  }

  colorLevel(ic) {
    if (ic >= 8.5) {
      return '#455a64';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#607d8b';
    } else if (ic >= 4 && ic < 6.5) {
      return '#90a4ae';
    } else if (ic >= 0 && ic < 4) {
      return '#cfd8dc';
    } else {
      return '#FFF';
    }
  }

  colorLevelFont(ic) {
    if (ic >= 8.5) {
      return '#FFF';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#FFF';
    } else if (ic >= 4 && ic < 6.5) {
      return '#000';
    } else if (ic >= 0 && ic < 4) {
      return '#000';
    } else {
      return '#FFF';
    }
  }

  CreateActivityPieData() {
    var data = [];
    if (this.activitiesCount(1) > 0) {
      data.push({ name: 'Nivel 1', value: this.activitiesCount(1) })
      COLORS.push('#455a64')
    }
    if (this.activitiesCount(2) > 0) {
      data.push({ name: 'Nivel 2', value: this.activitiesCount(2) })
      COLORS.push('#607d8b')
    }
    if (this.activitiesCount(3) > 0) {
      data.push({ name: 'Nivel 3', value: this.activitiesCount(3) })
      COLORS.push('#90a4ae')
    }
    if (this.activitiesCount(4) > 0) {
      data.push({ name: 'Nivel 4', value: this.activitiesCount(4) })
      COLORS.push('#cfd8dc')
    }
    return data;
  }

  CreateChallengePieData() {
    var data = [];
    if (this.challengesCount(1) > 0) {
      data.push({ name: 'Nivel 1', value: this.challengesCount(1) })
      COLORS.push('#455a64')
    }
    if (this.challengesCount(2) > 0) {
      data.push({ name: 'Nivel 2', value: this.challengesCount(2) })
      COLORS.push('#607d8b')
    }
    if (this.challengesCount(3) > 0) {
      data.push({ name: 'Nivel 3', value: this.challengesCount(3) })
      COLORS.push('#90a4ae')
    }
    if (this.challengesCount(4) > 0) {
      data.push({ name: 'Nivel 4', value: this.challengesCount(4) })
      COLORS.push('#cfd8dc')
    }
    return data;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
      { this.state.progress ?
        <div>
          <br />
          <LinearProgress />
        </div>
      :
      <div>
        <Grid container spacing={0} style={{ margin: 0 }}>
          <Grid item xs={4}>
            <PieChart width={290} height={200} onMouseEnter={this.onPieEnter}>
              <Pie
                data={this.state.activityPieData}
                cx={140}
                cy={180}
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                startAngle={180}
                endAngle={0}
                label
              >
                {
                  this.state.activityPieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
            <Typography style={{ textAlign: 'left', paddingLeft: 40 }}>Actividades</Typography>
          </Grid>
          <Grid item xs={4}>
            <PieChart width={290} height={200} onMouseEnter={this.onPieEnter}>
              <Pie
                data={this.state.challengePieData}
                cx={140}
                cy={180}
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                startAngle={180}
                endAngle={0}
                label
              >
                {
                  this.state.challengePieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
            <Typography style={{ textAlign: 'left', paddingLeft: 40 }}>Retos</Typography>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.root} square={true} elevation={0}>
              <Table className={classes.tableLegend}>
                <colgroup>
                  <col width="10px" />
                  <col width="400px" />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#455a64', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.one" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#607d8b', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.two" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#90a4ae', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.three" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#cfd8dc', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.four" /></TableCell>
                  </TableRow>            
                  </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'} width={200}><FormattedMessage id="activities.head_title" /></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>IC</TableCell>
                <TableCell style={{ backgroundColor: '#ffa726', border: '1px solid #cfd8dc', color: '#FFF', padding: 6 }} align={'center'} width={200}><FormattedMessage id="challenges.impact" /></TableCell>
                <TableCell style={{ backgroundColor: '#66bb6a', border: '1px solid #cfd8dc', color: '#FFF', padding: 6 }} align={'center'} width={180}><FormattedMessage id="challenges.complexity" /></TableCell>
                <TableCell style={{ backgroundColor: '#ab47bc', border: '1px solid #cfd8dc', color: '#FFF', padding: 6 }} align={'center'} width={180}><FormattedMessage id="challenges.need" /></TableCell>
              </TableRow>
              {this.state.activities.map((row, index) => (
              <TableRow hover key={index} onClick={ event => {this.handleClickOpen(row.title, this.ICcalc(row), row.impact, row.complexity, row.need)} }>
                <TableCell style={{ border: '1px solid #cfd8dc', cursor: 'pointer' }} width={200}>{row.title}</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), padding: 0, color: this.colorLevelFont(this.ICcalc(row)), border: '1px solid #cfd8dc' + this.colorLevel(this.ICcalc(row)) }}><strong>{this.ICcalc(row)}</strong></TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'}>{row.impact}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'}>{row.complexity}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'}>{row.need}</TableCell>
              </TableRow>
              )) }
              </TableBody>
          </Table>
        </Paper>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'} width={200}><FormattedMessage id="challenges.head_title" /></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>IC</TableCell>
                <TableCell style={{ backgroundColor: '#ffa726', border: '1px solid #cfd8dc', color: '#FFF', padding: 6 }} align={'center'} width={200}><FormattedMessage id="challenges.impact" /></TableCell>
                <TableCell style={{ backgroundColor: '#66bb6a', border: '1px solid #cfd8dc', color: '#FFF', padding: 6 }} align={'center'} width={180}><FormattedMessage id="challenges.complexity" /></TableCell>
                <TableCell style={{ backgroundColor: '#ab47bc', border: '1px solid #cfd8dc', color: '#FFF', padding: 6 }} align={'center'} width={180}><FormattedMessage id="challenges.need" /></TableCell>
              </TableRow>
              {this.state.challenges.map((row, index) => (
              <TableRow hover key={index} onClick={ event => {this.handleClickOpen(row.title, this.ICcalc(row), row.impact, row.complexity, row.need)} }>
                <TableCell style={{ border: '1px solid #cfd8dc', cursor: 'pointer' }} width={200}>{row.title}</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), padding: 0, color: this.colorLevelFont(this.ICcalc(row)), border: '1px solid #cfd8dc' + this.colorLevel(this.ICcalc(row)) }}><strong align={'center'}>{this.ICcalc(row)}</strong></TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'}>{row.impact}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'}>{row.complexity}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'}>{row.need}</TableCell>
              </TableRow>
              )) }
              </TableBody>
          </Table>
        </Paper>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.tableBig}>
            <TableBody>
            <TableRow>
                <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF' }} align={'left'} colSpan={3} rowSpan={2}><Typography variant="body1" gutterBottom style={{ paddingTop: 20, paddingBottom: 20, color: '#37474f' }}>Nivel de interacción de los dominios de conocimiento por actividad / reto</Typography></TableCell>

                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} colSpan={(this.state.activities.concat(this.state.challenges)).length}>Actividades y Retos</TableCell>
              </TableRow>
              <TableRow>

                {(this.state.activities.concat(this.state.challenges)).sort(function(a, b){
                  var icA = this.ICcalc(a);
                  var icB = this.ICcalc(b);
                  return icB-icA
                }.bind(this)).map((row, index) => (
                  <TableCell key={index} style={{ backgroundColor: '#fff', border: '1px solid #cfd8dc', color: '#37474f', padding: 6 }} align={'center'} width={120}>{row.title}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'} width={300}><FormattedMessage id="spaces.domains" /></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>IA</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>IC</TableCell>
                {(this.state.activities.concat(this.state.challenges)).sort(function(a, b){
                  var icA = this.ICcalc(a);
                  var icB = this.ICcalc(b);
                  return icB-icA
                }.bind(this)).map((row, index) => (
                  <TableCell key={index} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), border: '1px solid #cfd8dc', color: this.colorLevelFont(this.ICcalc(row)), padding: 0 }} align={'center'} width={120}>{this.ICcalc(row)}</TableCell>
                ))}
              </TableRow>
              {this.state.domains.map((row, index) => (
              <TableRow hover key={index + 1 + 'd'}>
                <TableCell style={{ border: '1px solid #cfd8dc' }} width={300}>{row.name}</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorInterference(this.interferenceCalc(row.interferences)), padding: 0, border: '1px solid #cfd8dc' }} width={70}>{isNaN(this.interferenceCalc(row.interferences)) ? '--' : this.interferenceCalc(row.interferences) }</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICCCalc(row)), padding: 0, color: this.colorLevelFont(this.ICCCalc(row)), border: '1px solid #cfd8dc' }} width={70}>{this.ICCCalc(row)}</TableCell>
                {
                  row.interferences.length === 0 ? 
                  (this.state.activities.concat(this.state.challenges)).sort(function(a, b){
                    var icA = this.ICcalc(a);
                    var icB = this.ICcalc(b);
                    return icB-icA
                  }.bind(this)).map((row, index) => (
                    <TableCell key={index} style={{ backgroundColor: '#fff', border: '1px solid #cfd8dc', color: '#37474f', padding: 6 }} align={'center'} width={120}>{'--'}</TableCell>
                  ))
                  :
                  row.interferences.sort(function(a, b){
                    var icA = this.ICcalc(a.resource);
                    var icB = this.ICcalc(b.resource);
                    return icB-icA
                  }.bind(this)).map((c, index) => (
                    <TableCell key={index} style={{ backgroundColor: this.colorInterference(c.value), color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'} width={70}>{c.value === null ? '--' : c.value}</TableCell>
                  ))
                  
                }
              </TableRow>
              )) }
              </TableBody>
          </Table>
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="analysis.radar" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
                <b>{ this.state.name }</b><br />
                Indice de Criticidad: <b>{ this.state.ic }</b>
            </DialogContentText>
            <div align={'center'}>
              <RadarChart outerRadius={150} width={700} height={450} data={this.state.radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" fontSize={14} />
                <PolarRadiusAxis fontSize={14} angle={90} domain={[0, 4]} />
                <Radar name="Cesar" dataKey="A" stroke="#4fc3f7" fill="#b3e5fc" fillOpacity={0.7} label={{ angle: 0 }} />
              </RadarChart>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
        }
      </div>
    );
  }
}

Functional.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Functional);