import auth from '../Auth';

class Category {
  constructor() {
    this.getList = this.getList.bind(this)
    this.getOrganizationList = this.getOrganizationList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
  }

  async getList(cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'categories', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async getOrganizationList(organization_id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations/' + organization_id + '/categories/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async create(name, description, organization_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'categories', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          category: { name: name, description: description, organization_id: organization_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async show(id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'categories/' + id, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async update(id, name, description, organization_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'categories', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, category: { name: name, description: description, organization_id: organization_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async delete(ids, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'categories', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'DELETE',
        body: JSON.stringify({
          category: { ids: ids }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

}

export default new Category();