import auth from '../../../Auth';

class ProfileCapital {
  constructor() {
    this.getList = this.getList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'profile_capitals/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getLookUP(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'profile_capitals/' + id + '/get_competence/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getSpaceList(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/profile_capitals/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getDomainList(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'profile_capitals/' + id + '/domains/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async create(name, people, space_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'profile_capitals', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          profile_capital: { name: name, people: people, space_id: space_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async update(id, name, people, space_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'profile_capitals', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, profile_capital: { name: name, people: people, space_id: space_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(ids, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'profile_capitals', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        profile_capital: { ids: ids }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  saveCompetence(id, competences, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'profile_competences/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'PUT',
      body: JSON.stringify({
        id: id, profile_capital: { competence: competences }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

}

export default new ProfileCapital();