import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
//import { Link } from 'react-router-dom';
import EnhancedTable from './EnhancedTable';
import Header from './Header';
//import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  buttonNew: {
    marginTop: '24px',
  }
});

class List extends Component { 

  constructor (props) {
    super(props);
    this.state = {}

  } 

  render() {

  const { classes } = this.props;
  //const MyLink = props => <Link to="/users/add" {...props} />

  return (
    <div>
      <Header />
      <main className={classes.main}>
        <EnhancedTable history={this.props.history} />
      </main>
    </div>
  );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);