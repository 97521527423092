import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { KMapContext } from '../../KMapProvider';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';
import LeakAddIcon from '@material-ui/icons/LeakAdd';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../layout/Snack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { FormattedMessage } from 'react-intl';
import blue from '@material-ui/core/colors/blue';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import domain from './Domain';
import category from '../../categories/Category';
import activity from '../activities/Activity';
import challenge from '../challenges/Challenge';

let counter = 0;
function createData(oid, title, category, created_at) {
  counter += 1;
  return { id: counter, oid, title, category, created_at };
}

let counterC = 0;
function createCategoriesData(oid, name, description, organization, created_at) {
  counterC += 1;
  return { id: counterC, oid, name, description, organization, created_at };
}

function createACData(oid, title, impact, complexity, need, created_at, interferences) {
  return { id: oid, oid, title, impact, complexity, need, created_at, interferences };
}

function createAIData(interferences) {
  return { value: interferences[0] ? interferences[0].value : 0, resource_id: interferences[0] ? interferences[0].resource_id : 0, resource_type: interferences[0] ? interferences[0].resource_type : 0 };
}

function createCIData(interferences) {
  return { value: interferences[0] ? interferences[0].value : 0, resource_id: interferences[0] ? interferences[0].resource_id : 0, resource_type: interferences[0] ? interferences[0].resource_type : 0 };
}

function createCharData(aspect, value) {
  return { aspect, value };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'title', numeric: false, disablePadding: true, label: <FormattedMessage id="domains.head_title" /> },
  { id: 'category', numeric: false, disablePadding: false, label: <FormattedMessage id="category.title" /> },
  { id: 'interference', numeric: false, disablePadding: false, label: <FormattedMessage id="interference.title" /> },
  { id: 'characterization', numeric: false, disablePadding: false, label: <FormattedMessage id="characterization.title" /> },
];

const characterizationsItems = [

  {
    id: <FormattedMessage id="characterization.complexity" />,
    key: 'C',
    cesarColor: '#ffa726',
    children: [
      { id: 'C1', index: 0, label: <FormattedMessage id="characterization.complexity_one" /> },
      { id: 'C2', index: 1, label: <FormattedMessage id="characterization.complexity_two" /> },
      { id: 'C3', index: 2, label: <FormattedMessage id="characterization.complexity_three" /> },
    ]
  },
  {
    id: <FormattedMessage id="characterization.shortage" />,
    key: 'E',
    cesarColor: '#66bb6a',
    children: [
      { id: 'E1', index: 3, label: <FormattedMessage id="characterization.shortage_one" /> },
      { id: 'E2', index: 4, label: <FormattedMessage id="characterization.shortage_two" /> },
      { id: 'E3', index: 5, label: <FormattedMessage id="characterization.shortage_three" /> },
    ]
  },
  {
    id: <FormattedMessage id="characterization.singularity" />,
    key: 'S',
    cesarColor: '#ab47bc',
    children: [
      { id: 'S1', index: 6, label: <FormattedMessage id="characterization.singularity_one" /> },
      { id: 'S2', index: 7, label: <FormattedMessage id="characterization.singularity_two" /> },
      { id: 'S3', index: 8, label: <FormattedMessage id="characterization.singularity_three" /> },
    ]
  },
  {
    id: <FormattedMessage id="characterization.applicability" />,
    key: 'A',
    cesarColor: '#42a5f5',
    children: [
      { id: 'A1', index: 9, label: <FormattedMessage id="characterization.applicability_one" /> },
      { id: 'A2', index: 10, label: <FormattedMessage id="characterization.applicability_two" /> },
      { id: 'A3', index: 11, label: <FormattedMessage id="characterization.applicability_three" /> },
    ]
  },
  {
    id: <FormattedMessage id="characterization.risk" />,
    key: 'R',
    cesarColor: '#ef5350',
    children: [
      { id: 'R1', index: 12, label: <FormattedMessage id="characterization.risk_one" /> },
      { id: 'R2', index: 13, label: <FormattedMessage id="characterization.risk_two" /> },
      { id: 'R3', index: 14, label: <FormattedMessage id="characterization.risk_three" /> },
    ]
  }
];

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, canContext } = this.props;

    return (
      <TableHead>
        <TableRow>
          { canContext ?
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              indeterminateIcon={<IndeterminateCheckBox fontSize="small" />}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />}
            />
          </TableCell>
          : null }
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, canContext } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} seleccionado
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            <FormattedMessage id="domains.title" />
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Eliminar">
            <IconButton aria-label="Eliminar" onClick={event => props.deleteAction()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          (canContext) ?
          <Tooltip title={<FormattedMessage id="domains.add" />}>
            <IconButton aria-label={<FormattedMessage id="domains.add" />} onClick={props.action}>
              <AddIcon />
            </IconButton>
          </Tooltip> : null
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  selectControl: {
    width: '100%',
  },
  tableCell: {
    cursor: 'pointer',
  },
  dialogStyle: {
    borderRadius: 0
  },
  dialogTable: {
    width: '100%',
  },
  radioLow: {
    color: blue[100],
    '&$checkedLow': {
      color: blue[200],
    },
  },
  checkedLow: {},
  radioMedium: {
    color: blue[300],
    '&$checkedMedium': {
      color: blue[400],
    },
  },
  checkedMedium: {},
  radioHigh: {
    color: blue[500],
    '&$checkedHigh': {
      color: blue[600],
    },
  },
  checkedHigh: {},
  radioVeryHigh: {
    color: blue[700],
    '&$checkedVeryHigh': {
      color: blue[800],
    },
  },
  radioAllLow: {
    color: "#ffa726",
    '&$checkedLow': {
      color: blue[200],
    },
  },
  checkedAllLow: {},
  radioAllMedium: {
    color: blue[300],
    '&$checkedMedium': {
      color: blue[400],
    },
  },
  checkedAllMedium: {},
  radioAllHigh: {
    color: blue[500],
    '&$checkedHigh': {
      color: blue[600],
    },
  },
  checkedAllHigh: {},
  radioAllVeryHigh: {
    color: blue[700],
    '&$checkedVeryHigh': {
      color: blue[800],
    },
  },
  checkedVeryHigh: {},
  label: {
    fontSize: '12px',
  }  
});

class DomainsTable extends React.Component {

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      OIDselected: [],
      domains: [],
      categories: [],
      activities: [],
      challenges: [],
      interferenceActivity: [],
      interferenceChallenge: [],
      currentDomain: '',
      page: 0,
      rowsPerPage: 10,
      openDialog: false,
      openSnack: false,
      openInterferenceDialog: false,
      openCharacterizationDialog: false,
      id: '',
      title: '',
      category: '',
      characterization: [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],
      fullWidth: true,
      maxWidth: 'md',
      submitAction: 'new',
      disableInterferenceSave: true,
      disableCharacterizationSave: true,
      submitActionTitle: <FormattedMessage id="activities.add" />,
      openDelDialog: false,
      characterizationAll: [],
    }
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);

    this.handleInterferenceClickOpen = this.handleInterferenceClickOpen.bind(this);
    this.handleInterferenceClickClose = this.handleInterferenceClickClose.bind(this);
    this.handleInterferenceAChange = this.handleInterferenceAChange.bind(this);
    this.handleInterferenceCChange = this.handleInterferenceCChange.bind(this);
     
    this.handleCharacterizationClickOpen = this.handleCharacterizationClickOpen.bind(this);
    this.handleCharacterizationClickClose = this.handleCharacterizationClickClose.bind(this);
    this.handleCharacterizationChange = this.handleCharacterizationChange.bind(this);
    this.handleCharacterizationAALLChange = this.handleCharacterizationAALLChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInterferenceSubmit = this.handleInterferenceSubmit.bind(this);
    this.handleCharacterizationSubmit = this.handleCharacterizationSubmit.bind(this);
    this.openDelDialog = this.openDelDialog.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDelClickClose = this.handleDelClickClose.bind(this);

    this.CharFilter = this.CharFilter.bind(this)
  } 

  componentDidMount() {
    domain.getSpaceList(this.props.spaceId).then((result) =>
      this.setState(data => ({ domains: result.map(n => createData(n._id.$oid, n.name, n.category, n.created_at)) }))
    );
  } 

  componentWillReceiveProps(nextProps) {
    domain.getSpaceList(this.props.spaceId).then((result) =>
      this.setState(data => ({ domains: result.map(n => createData(n._id.$oid, n.name, n.category, n.created_at)) }))
    );
  }

  componentDidUpdate(prevProps, prevState) {

    var choices = 0;

    if (this.state.openInterferenceDialog) {
      
      for (var a=0;a<this.state.activities.length;a++){
        var ela = document.querySelector("input[name=radio-button-activity-" + a + "]:checked")
        if ( ela) {
          choices++;
        }
      }
      for (var b=0;b<this.state.challenges.length;b++){
        var elb = document.querySelector("input[name=radio-button-challenge-" + b + "]:checked")
        if ( elb ) {
          choices++;
        }
      }
      
      if (choices > 0 && (choices === ( this.state.challenges.length +  this.state.activities.length)) && prevState.disableInterferenceSave) {
        this.setState({ disableInterferenceSave: false });
      }

    } 

    if (this.state.openCharacterizationDialog) {
      
      for (var c=0;c<this.state.characterization.length;c++){
        var elc = document.querySelector("input[name=radio-button-character-" + c + "]:checked")
        if ( elc ) {
          choices++;
        }
      }
      
      if (choices > 0 && (choices === this.state.characterization.length) && prevState.disableCharacterizationSave) {
        this.setState({ disableCharacterizationSave: false });
      }

    } 

  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.domains.map(n => n.id), OIDselected: state.domains.map(n => n.oid) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, domain) => {
    category.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) =>
      this.setState(data => ({ categories: result.map(n => createCategoriesData(n._id.$oid, n.name, n.description, n.organization, n.created_at)) }))
    );
    this.setState({ currentDomain: domain, openDialog: true, submitAction: 'edit', submitActionTitle: <FormattedMessage id="domains.editing" />, title: domain.title, category: domain.category.id.$oid });
  };

  handleCheck = (event, id, oid) => {
    const { selected, OIDselected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newOIDSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newOIDSelected = newOIDSelected.concat(OIDselected, oid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newOIDSelected = newOIDSelected.concat(
        OIDselected.slice(0, selectedIndex),
        OIDselected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected, OIDselected: newOIDSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleCategoryChange(event) {
    this.setState({ category: event.target.value });
  }

  handleClickOpen() {
    category.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) =>
      this.setState(data => ({ categories: result.map(n => createCategoriesData(n._id.$oid, n.name, n.description, n.organization, n.created_at)) }))
    );
    this.setState({ openDialog: true, submitAction: 'new', submitActionTitle: <FormattedMessage id="domains.add" /> });
  }

  handleClickClose() {
    this.setState({ openDialog: false, title: '', category: '' });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  handleInterferenceClickOpen(currentDomain) {

    activity.getDomainList(currentDomain.oid).then((result) => {
      this.setState(data => ({
        activities: result.map(n => createACData(n._id.$oid, n.title, n.impact, n.complexity, n.need, n.created_at)),
        interferenceActivity: result.map(n => createAIData(n.interferences)),
      }))
    });
    challenge.getDomainList(currentDomain.oid).then((result) => {
      this.setState(data => ({
        challenges: result.map(n => createACData(n._id.$oid, n.title, n.impact, n.complexity, n.need, n.created_at)),
        interferenceChallenge: result.map(n => createCIData(n.interferences))
      }))
    });
    this.setState({ openInterferenceDialog: true, currentDomain: currentDomain });
  }

  handleInterferenceClickClose() {
    this.setState({ openInterferenceDialog: false, interferenceActivity: [], interferenceChallenge: [], activities: [], challenges: [], disableInterferenceSave: true });
  }

  handleInterferenceAChange = (index, resource_type, resource_id) => (event) => {
   
    let i = this.state.interferenceActivity;
    i[index] = { space_id: this.props.spaceId, resource_type: resource_type, resource_id: resource_id, value: event.target.value };
    this.setState({ interferenceActivity: i });
    
  }

  handleInterferenceCChange = (index, resource_type, resource_id) => (event) => {

    let i = this.state.interferenceChallenge;
    i[index] = { space_id: this.props.spaceId, resource_type: resource_type, resource_id: resource_id, value: event.target.value };
    this.setState({ interferenceChallenge: i });
    
  }

  handleCharacterizationClickOpen(currentDomain) {

    domain.getCharacterization(currentDomain.oid).then((result) => {
      this.setState(data => ({
        characterization: result.map(n => n).length > 0 ? result.map(n => createCharData(n.aspect, n.value)) : [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]
      }))
    });
    this.setState({ openCharacterizationDialog: true, currentDomain: currentDomain });
  }

  handleCharacterizationClickClose() {
    this.setState({ openCharacterizationDialog: false, characterization: [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]], disableCharacterizationSave: true });
  }

  handleCharacterizationChange = (index, id) => (event) => {

    let i = this.state.characterization;
    i[index] = { aspect: id, value: parseInt(event.target.value) };
    this.setState({ characterization: i });
    
  }

  handleCharacterizationAALLChange = (children) => (event) => {

    let i = this.state.characterization;
    children.forEach( row => {
      i[row.index] = { aspect: row.id, value: parseInt(event.target.value) };
    });
    this.setState({ characterization: i });

  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
    domain.create(this.state.title, this.state.category, this.props.spaceId, () => {
      domain.getSpaceList(this.props.spaceId).then((result) => {
          this.setState(data => ({
            domains: result.map(n => createData(n._id.$oid, n.name, n.category, n.created_at)),
            id: '',
            title: '',
            category: '',
            SnackMessage: <FormattedMessage id="domains.saved" />,
            openSnack: true
          }));
          this.handleClickClose();
        }
      )
    })
    } else {
      domain.update(this.state.currentDomain.oid, this.state.title, this.state.category, this.props.spaceId, () => {
        domain.getSpaceList(this.props.spaceId).then((result) => {
            this.setState(data => ({
              domains: result.map(n => createData(n._id.$oid, n.name, n.category, n.created_at)),
              id: '',
              title: '',
              category: '',
              SnackMessage: <FormattedMessage id="domains.updated" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    }
  }

  openDelDialog() {
    this.setState(data => ({ openDelDialog: true }));
  }

  handleDelClickClose() {
    this.setState({ openDelDialog: false });
  }

  handleDelete = () => {
    domain.delete(this.state.OIDselected).then((result) => {
      domain.getSpaceList(this.props.spaceId).then((result) => {
        this.setState(data => ({
            domains: result.map(n => createData(n._id.$oid, n.name, n.category, n.created_at)),
            id: '',
            title: '',
            impact: '',
            complexity: '',
            need: '',
            SnackMessage: <FormattedMessage id="domains.deleted" />,
            openSnack: true,
            selected: [],
            OIDselected: []
          }));
          this.handleDelClickClose();
        }
      )
    })
  };

  handleInterferenceSubmit(e) {
    e.preventDefault();
    domain.saveInterference(this.state.currentDomain.oid, this.state.interferenceActivity, this.state.interferenceChallenge).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="interference.updated" />,
        openSnack: true
      });
      this.handleInterferenceClickClose();
    })
  }

  handleCharacterizationSubmit(e) {
    e.preventDefault();
    domain.saveCharacterization(this.state.currentDomain.oid, this.state.characterization).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="characterization.updated" />,
        openSnack: true
      });
      this.handleCharacterizationClickClose();
    })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  CharFilter(id) {
    let el = this.state.characterization.filter(x => x.aspect === id)[0];
    if (el) {
      return el;
    } else {
      return 'L0L';
    }
  }

  render() {
    const { classes } = this.props;
    const { domains, order, orderBy, selected, rowsPerPage, page, activities, challenges } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, domains.length - page * rowsPerPage);
    const can = (this.context.userRoles.filter(role => ((role.name === "editor" && role.resource_id.$oid === this.props.spaceId) || role.name === "owner" || role.name === "admin")).length > 0);
    
    return (
      <Paper className={classes.root} elevation={0} square={true}>
        <EnhancedTableToolbar numSelected={selected.length} action={this.handleClickOpen} deleteAction={this.openDelDialog} canContext={can} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={domains.length}
              canContext={can}
            />
            <TableBody>
              {stableSort(domains, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      key={n.id}
                    >
                      { can ?
                      <TableCell padding="checkbox" onClick={event => this.handleCheck(event, n.id, n.oid)} role="checkbox" aria-checked={isSelected} selected={isSelected}>
                        <Checkbox checked={isSelected} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                      </TableCell>
                      : null }
                      <TableCell component="th" scope="row" padding="none" onClick={event => can ? this.handleClick(event, n) : null } className={classes.tableCell}>
                        {n.title}
                      </TableCell>
                      <TableCell>{n.category.title}</TableCell>

                      <TableCell>
                        <Tooltip title={<FormattedMessage id="interference.title" />}>
                          <IconButton aria-label={<FormattedMessage id="interference.title" />} onClick={event => this.handleInterferenceClickOpen(n)}>
                            <LeakAddIcon />
                          </IconButton>
                        </Tooltip>                      
                      </TableCell>

                      <TableCell>
                        <Tooltip title={<FormattedMessage id="characterization.title" />}>
                          <IconButton aria-label={<FormattedMessage id="characterization.title" />} onClick={event => this.handleCharacterizationClickOpen(n)}>
                            <SentimentSatisfiedAltIcon />
                          </IconButton>
                        </Tooltip>                        
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={domains.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={<FormattedMessage id="rows_per_page" />}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${ navigator.language === 'es-ES' ? 'de' : 'of' } ${count}`}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="title"><FormattedMessage id="domains.head_title" /></InputLabel>
                <Input id="title" value={this.state.title} onChange={this.handleTitleChange} autoFocus />
              </FormControl>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="select-multiple"><FormattedMessage id="category.title" /></InputLabel>
                <Select
                  value={this.state.category}
                  onChange={this.handleCategoryChange}
                  input={<Input name="category" id="category-helper" />}
                  MenuProps={{ classes: { paper: classes.dialogStyle } }}
                >
                  {this.state.categories.map(category => (
                    <MenuItem key={category.oid} value={category.oid}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>                          
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary" disabled={!(this.state.title !== '' && this.state.category !== '')}>
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openInterferenceDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="interference.title" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentDomain.title }</b><br/>
              Nivel de interacción que tiene este dominio de conocimiento en relación a las Actividades y Retos declaradas en el espacio.
            </DialogContentText>
            <form>
              <Table className={classes.dialogTable}>
                <TableHead>
                  <TableRow>
                    <TableCell><FormattedMessage id="activities.title" /></TableCell>
                    <TableCell><FormattedMessage id="evaluation" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {activities.map((row, index) => ( 
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell width="60%">
                    { can ?
                    <FormControl component="fieldset">
                      <RadioGroup row>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceActivity.length > 0 ? parseInt(this.state.interferenceActivity[index].value) === 1 : false }
                              onChange={this.handleInterferenceAChange(index, 'Activity', row.oid)}
                              value={1}
                              classes={{
                                root: classes.radioLow,
                                checked: classes.checkedLow,
                              }}
                              className="radio-"
                              name={"radio-button-activity-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                        
                          }
                          label={<FormattedMessage id="scale_values.low"/>}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceActivity.length > 0 ? parseInt(this.state.interferenceActivity[index].value) === 2 : false }
                              onChange={this.handleInterferenceAChange(index, 'Activity', row.oid)}
                              value={2}
                              classes={{
                                root: classes.radioMedium,
                                checked: classes.checkedMedium,
                              }}
                              className="radio-"
                              name={"radio-button-activity-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                       
                          }
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceActivity.length > 0 ? parseInt(this.state.interferenceActivity[index].value) === 3 : false }
                              onChange={this.handleInterferenceAChange(index, 'Activity', row.oid)}
                              value={3}
                              classes={{
                                root: classes.radioHigh,
                                checked: classes.checkedHigh,
                              }}
                              className="radio-"
                              name={"radio-button-activity-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceActivity.length > 0 ? parseInt(this.state.interferenceActivity[index].value) === 4 : false }
                              onChange={this.handleInterferenceAChange(index, 'Activity', row.oid)}
                              value={4}
                              classes={{
                                root: classes.radioVeryHigh,
                                checked: classes.checkedVeryHigh,
                                
                              }}
                              className="radio-"
                              name={"radio-button-activity-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          label={<FormattedMessage id="scale_values.very" />}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="end"
                        />
                        </RadioGroup>
                      </FormControl>
                      :
                      (() => {
                          if (this.state.interferenceActivity.length > 0) {
                            switch(parseInt(this.state.interferenceActivity[index].value)) {
                              case 1:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.low" /></Typography><LinearProgress variant="determinate" value={25} /></div>;
                              case 2:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.medium" /></Typography><LinearProgress variant="determinate" value={50} /></div>;
                              case 3:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.high" /></Typography><LinearProgress variant="determinate" value={75} /></div>;
                              case 4:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.very" /></Typography><LinearProgress variant="determinate" value={100} /></div>;
                              default:
                                return null;
                            }
                          }
                        })()}  
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table> 
              <Table className={classes.dialogTable}>
                <TableHead>
                  <TableRow>
                    <TableCell><FormattedMessage id="challenges.title" /></TableCell>
                    <TableCell><FormattedMessage id="evaluation" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {challenges.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell width="60%">
                    { can ?
                    <FormControl component="fieldset">
                      <RadioGroup row>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceChallenge.length > 0 ? parseInt(this.state.interferenceChallenge[index].value) === 1 : false }
                              onChange={this.handleInterferenceCChange(index, 'Challenge', row.oid)}
                              value={1}
                              classes={{
                                root: classes.radioLow,
                                checked: classes.checkedLow,
                              }}
                              className="radio-"
                              name={"radio-button-challenge-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                        
                          }
                          label={<FormattedMessage id="scale_values.low" classes={ { fontSize: '7px' } }/>}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceChallenge.length > 0 ? parseInt(this.state.interferenceChallenge[index].value) === 2 : false }
                              onChange={this.handleInterferenceCChange(index, 'Challenge', row.oid)}
                              value={2}
                              classes={{
                                root: classes.radioMedium,
                                checked: classes.checkedMedium,
                              }}
                              className="radio-"
                              name={"radio-button-challenge-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                       
                          }
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceChallenge.length > 0 ? parseInt(this.state.interferenceChallenge[index].value) === 3 : false }
                              onChange={this.handleInterferenceCChange(index, 'Challenge', row.oid)}
                              value={3}
                              classes={{
                                root: classes.radioHigh,
                                checked: classes.checkedHigh,
                              }}
                              className="radio-"
                              name={"radio-button-challenge-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.interferenceChallenge.length > 0 ? parseInt(this.state.interferenceChallenge[index].value) === 4 : false}
                              onChange={this.handleInterferenceCChange(index, 'Challenge', row.oid)}
                              value={4}
                              classes={{
                                root: classes.radioVeryHigh,
                                checked: classes.checkedVeryHigh,
                              }}
                              className="radio-"
                              name={"radio-button-challenge-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          label={<FormattedMessage id="scale_values.very"/>}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="end"
                        />
                        </RadioGroup>
                      </FormControl>  
                      :
                      (() => {
                          if (this.state.interferenceChallenge.length > 0) {
                            switch(parseInt(this.state.interferenceChallenge[index].value)) {
                              case 1:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.low" /></Typography><LinearProgress variant="determinate" value={25} /></div>;
                              case 2:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.medium" /></Typography><LinearProgress variant="determinate" value={50} /></div>;
                              case 3:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.high" /></Typography><LinearProgress variant="determinate" value={75} /></div>;
                              case 4:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.very" /></Typography><LinearProgress variant="determinate" value={100} /></div>;
                              default:
                                return null;
                            }
                          }
                        })()}
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>                                
            </form>
          </DialogContent>
          { can ?
          <DialogActions>
            <Button onClick={this.handleInterferenceClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleInterferenceSubmit} color="primary" disabled={this.state.disableInterferenceSave}>
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
          :
          <DialogActions>
            <Button onClick={this.handleInterferenceClickClose} color="primary">
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
          }          
        </Dialog>

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openCharacterizationDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="characterization.title" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentDomain.title }</b><br/>
              Análisis de las características del dominio de conocimiento en relación a su complejidad, escasez, singularidad, aplicabilidad y riesgo.
            </DialogContentText>
            <form>
              <Table className={classes.dialogTable}>
                <TableHead>
                  <TableRow>
                    <TableCell><FormattedMessage id="variable" /></TableCell>
                    <TableCell><FormattedMessage id="evaluation" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  characterizationsItems.map(({ id, key, cesarColor, children }) => (
                    <React.Fragment key={key}>
                    <TableRow>
                      <TableCell style={{ color: cesarColor }}><strong>{id}</strong></TableCell>
                      <TableCell width="60%">

                        <FormControl component="fieldset">
                          <RadioGroup row>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={false}
                                  onChange={this.handleCharacterizationAALLChange(children)}
                                  value="1"
                                  style={{ color: cesarColor }}
                                  name={"radio-button-character-1-all"}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                        
                              }
                              label={<FormattedMessage id="scale_values.low"/>}
                              classes={{
                                label: classes.label,
                              }}
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={false}
                                  onChange={this.handleCharacterizationAALLChange(children)}
                                  value="2"
                                  style={{ color: cesarColor }}
                                  name={"radio-button-character-2-all"}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                       
                              }
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={false}
                                  onChange={this.handleCharacterizationAALLChange(children)}
                                  value="3"
                                  style={{ color: cesarColor }}
                                  name={"radio-button-character-3-all"}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                     
                              }
                              labelPlacement="bottom"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={false}
                                  onChange={this.handleCharacterizationAALLChange(children)}
                                  value="4"
                                  style={{ color: cesarColor }}
                                  name={"radio-button-character-4-all"}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                     
                              }
                              classes={{
                                label: classes.label,
                              }}
                              label={<FormattedMessage id="scale_values.very"/>}
                              labelPlacement="end"
                            />
                            </RadioGroup>
                          </FormControl>

                        </TableCell>
                    </TableRow>
                    {children.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          { row.label }
                        </TableCell>
                        <TableCell width="60%">
                        { can ?
                        <FormControl component="fieldset">
                          <RadioGroup row>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={this.state.characterization.length > 0 ? parseInt(this.CharFilter(row.id).value) === 1 : false}
                                  onChange={this.handleCharacterizationChange(row.index, row.id)}
                                  value="1"
                                  classes={{
                                    root: classes.radioLow,
                                    checked: classes.checkedLow,
                                  }}
                                  name={"radio-button-character-" + row.index}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                        
                              }
                              label={<FormattedMessage id="scale_values.low"/>}
                              classes={{
                                label: classes.label,
                              }}
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={this.state.characterization.length > 0 ? parseInt(this.CharFilter(row.id).value) === 2 : false}
                                  onChange={this.handleCharacterizationChange(row.index, row.id)}
                                  value="2"
                                  classes={{
                                    root: classes.radioMedium,
                                    checked: classes.checkedMedium,
                                  }}
                                  name={"radio-button-character-" + row.index}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                       
                              }
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={this.state.characterization.length > 0 ? parseInt(this.CharFilter(row.id).value) === 3 : false}
                                  onChange={this.handleCharacterizationChange(row.index, row.id)}
                                  value="3"
                                  classes={{
                                    root: classes.radioHigh,
                                    checked: classes.checkedHigh,
                                  }}
                                  name={"radio-button-character-" + row.index}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                     
                              }
                              labelPlacement="bottom"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={this.state.characterization.length > 0 ? parseInt(this.CharFilter(row.id).value) === 4 : false}
                                  onChange={this.handleCharacterizationChange(row.index, row.id)}
                                  value="4"
                                  classes={{
                                    root: classes.radioVeryHigh,
                                    checked: classes.checkedVeryHigh,
                                  }}
                                  name={"radio-button-character-" + row.index}
                                  aria-label="E"
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                />                     
                              }
                              classes={{
                                label: classes.label,
                              }}
                              label={<FormattedMessage id="scale_values.very"/>}
                              labelPlacement="end"
                            />
                            </RadioGroup>
                          </FormControl>
                          :
                          (() => {
                              if (this.state.characterization.length > 0) {
                                switch(parseInt(this.state.characterization[index].value)) {
                                  case 1:
                                    return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.low" /></Typography><LinearProgress variant="determinate" value={25} /></div>;
                                  case 2:
                                    return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.medium" /></Typography><LinearProgress variant="determinate" value={50} /></div>;
                                  case 3:
                                    return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.high" /></Typography><LinearProgress variant="determinate" value={75} /></div>;
                                  case 4:
                                    return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="scale_values.very" /></Typography><LinearProgress variant="determinate" value={100} /></div>;
                                  default:
                                    return null;
                                }
                              }
                            })()}              
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
                </TableBody>
              </Table>                                
            </form>
          </DialogContent>
          { can ?
          <DialogActions>
            
            <Button onClick={this.handleCharacterizationClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleCharacterizationSubmit} color="primary" disabled={this.state.disableCharacterizationSave}>
              <FormattedMessage id="save" />
            </Button>
            
          </DialogActions>
          :
          <DialogActions>
            
            <Button onClick={this.handleCharacterizationClickClose} color="primary">
              <FormattedMessage id="close" />
            </Button>
            
          </DialogActions>          
          }
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.openDelDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Eliminar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>¿Estás seguro?</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              <FormattedMessage id="delete" />
            </Button>
          </DialogActions>
        </Dialog>

      </Paper>
    );
  }
}

DomainsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DomainsTable);