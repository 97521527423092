import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import auth from '../Auth'
import { FormattedMessage } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      elevation={0}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

class AcceptInvitation extends Component {

  constructor (props) {
    super(props);
    this.state = {
      name: '',
      organization: '',
      email: '',
      password: '',
      password_confirmation: ''
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordCChange = this.handlePasswordCChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  handleNameChange(event) {
    this.setState({
        name: event.target.value
    })
  }
  
  handleOrgChange(event) {
    this.setState({
        organization: event.target.value
    })
  }

  handleEmailChange(event) {
    this.setState({
        email: event.target.value
    })
  }

  handlePasswordChange(event) {
    this.setState({
        password: event.target.value
    })
  }

  handlePasswordCChange(event) {
    this.setState({
        password_confirmation: event.target.value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    auth.password(this.props.match.params.invitation_token, this.state.password, this.state.password_confirmation, () => {
      this.props.history.push("/");
    })
  }

  render() {

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Typography component="h2" gutterBottom style={{ marginTop: 30, backgroundImage: 'url(/kmarker.png)', backgroundRepeat: 'no-repeat', backgroundSize: 92, backgroundPosition: 'center', height: 180 }}></Typography>
        <Paper className={classes.paper}>
{/*           <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5" style={{ marginTop: 14 }}>
            <FormattedMessage id="signin" />
          </Typography>

          <form className={classes.form} onSubmit={this.handleSubmit}>
            { this.state.open ?
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant="warning"
              message="Contraseña no coincide."
            />
            : null }
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password"><FormattedMessage id="users.password" /></InputLabel>
              <Input name="password" type="password" id="password" value={this.state.password} onChange={this.handlePasswordChange} autoComplete="current-password" />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password_confirmation"><FormattedMessage id="users.password_confirmation" /></InputLabel>
              <Input name="password_confirmation" type="password" id="password_confirmation" value={this.state.password_confirmation} onChange={this.handlePasswordCChange} autoComplete="current-password" />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color={grey[800]}
              elevation={0}
              className={classes.submit}
            >
              <FormattedMessage id="signup" />
            </Button>
          </form>

          <Typography className={classes.link} component="a" href="/signin">
            <FormattedMessage id="signin" />
          </Typography>
        </Paper>
      </main>
    );
  }
}

AcceptInvitation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AcceptInvitation);