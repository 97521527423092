import React from 'react';
import { KMapContext } from '../KMapProvider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import GoIcon from '@material-ui/icons/ExitToApp';
import space from '../spaces/Space';
import SpaceForm from '../spaces/SpaceForm';
import { FormattedMessage, FormattedDate } from 'react-intl';
import TextTruncate from 'react-text-truncate';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginTop: 20
  },
  orgCard: {
    minWidth: '50%',
  },
  organizationCard: {
    //borderBottom: '1px solid red'
  },
  card: {
    minWidth: 250,
    maxWidth: 270,
    minHeight: 220,
    maxHeight: 270,
    backgroundColor: '#e1f5fe'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  nameLink: {
    cursor: 'pointer',
  }
});

let counter = 0;
function createData(oid, name, description, created_at) {
  counter += 1;
  return { id: counter, oid, name, description, created_at };
}

class SpaceDash extends React.Component{

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      OIDselected: [],
      data: [],
      page: 0,
      name: '',
      description: '',
      rowsPerPage: 5,
      fullWidth: true,
      maxWidth: 'sm',
      openDialog: false,
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="organizations.add" />,
      chartData: [
        {
          subject: 'Math', A: 120, B: 110, fullMark: 150,
        },
        {
          subject: 'Chinese', A: 98, B: 130, fullMark: 150,
        },
        {
          subject: 'English', A: 86, B: 130, fullMark: 150,
        },
        {
          subject: 'Geography', A: 99, B: 100, fullMark: 150,
        },
        {
          subject: 'Physics', A: 85, B: 90, fullMark: 150,
        },
        {
          subject: 'History', A: 65, B: 85, fullMark: 150,
        },
      ]
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.updateList = this.updateList.bind(this);
  } 

  componentDidMount() {

      space.getOrganizationList(this.context.userOrganization.id).then((result) =>
        this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.name, n.purpose, n.created_at)) }))
      )
    
  } 

  handleClickOpen() {
    this.setState({ openDialog: true, submitAction: 'new', submitActionTitle: <FormattedMessage id="spaces.new" /> });
  }

  handleClickClose() {
    this.setState({ openDialog: false, name: '', description: '' });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  handleClick = (event, id, oid, name, description) => {
    if (this.context.userRoles.filter(role => (role.name === "admin")).length > 0) {
      this.setState({ openDialog: true, submitAction: 'edit', submitActionTitle: <FormattedMessage id="organizations.editing" />, id: oid, name: name, description: description });
    } else {
      this.props.history.push('/spaces/' + oid)
    }
  }

  updateList() {
    space.getOrganizationList(this.context.userOrganization.id).then((result) => {
        this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.name, n.purpose, n.created_at)) }))
        this.handleClickClose();
    })
  }

  render() {

    const { classes } = this.props;
    const { data } = this.state;

    return (
      <div>

          <Grid style={{ marginBottom: 14, marginLeft: 10 }}>
            <Tooltip title={<FormattedMessage id="spaces.new" />} placement={'right'}>
              <IconButton aria-label={<FormattedMessage id="spaces.new" />} onClick={this.handleClickOpen}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>          

          <Grid container spacing={8} className={classes.pos}>
            {data.map(({ id, oid, name, description, created_at }) => (
            <Grid item xs key={id}>
              <Card className={classes.card} elevation={0} square={true}>
                <CardContent>
                  <Typography variant="h5" component="h2" className={classes.nameLink} onClick={event => this.handleClick(event, id, oid)}>
                    <TextTruncate
                      line={1}
                      truncateText="…"
                      text={name}
                    />
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <FormattedDate value={new Date(created_at)} year='numeric' month='long' day='2-digit' />
                  </Typography>
                  <Typography component="span">
                    <TextTruncate
                      line={3}
                      truncateText="…"
                      text={description}
                    />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Tooltip title={<FormattedMessage id="more" />} placement={'right'}>
                    <IconButton aria-label={<FormattedMessage id="more" />} onClick={event => this.handleClick(event, id, oid)}>
                      <GoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
            ))}
          </Grid>

          <SpaceForm openDialog={this.state.openDialog} actionClose={this.handleClickClose} submitAction={this.state.submitAction} updateListAction={this.updateList} />

      </div>
    );
  }
}

SpaceDash.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpaceDash);
