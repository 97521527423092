import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage } from 'react-intl';
import space from '../Space';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    //minWidth: 700,
    //tableLayout: 'fixed',
  },
  tableLegend: {
    tableLayout: 'fixed',
    maxWidth: 110,
  },
});

const COLORS = [];

class GridLevels extends Component {

  constructor (props) {
    super(props);
    this.state = {
      domains: [],
      progress: false,
      pieData: [],
    }
    this.setUp = this.setUp.bind(this);
    this.domainsLevel = this.domainsLevel.bind(this);
    this.domainsCount = this.domainsCount.bind(this);
  } 

  componentDidMount() {
    this.setState({ progress : true });
    this.setUp();
  }

  componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.spaceId !== prevProps.spaceId) {
      this.setState({ progress : true });
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    space.getCesar(this.props.spaceId).then((result) => {
      this.setState(data => ({ domains: 
          result.sort(function(a, b) {
            var icA = this.ICcalc(a);
            var icB = this.ICcalc(b);
            return icB-icA
          }.bind(this))
      }))
      this.setState({
        pieData : this.CreatePieData(), progress: false
      });                      
    });
  }

  CreatePieData() {
    var data = [];
    if (this.domainsCount(3) > 0) {
      data.push({ name: 'Know-How interno', value: this.domainsCount(3) })
      COLORS.push('#ff8a65')
    }
    if (this.domainsCount(1) > 0) {
      data.push({ name: 'Conocimiento estructurado interno', value: this.domainsCount(1) })
      COLORS.push('#fff176')
    }
    if (this.domainsCount(2) > 0) {
      data.push({ name: 'Conocimiento público estructurado', value: this.domainsCount(2) })
      COLORS.push('#4fc3f7')
    }
    if (this.domainsCount(4) > 0) {
      data.push({ name: 'Know-How público', value: this.domainsCount(4) })
      COLORS.push('#81c784')
    }
    return data;
  }

  ICcalc(row) {

    var a = 0;
    var b = 0;
    var ic = 0;
    var total = 0;

    if (row.s1 === 4) {
      a = 0.05
    } 

    if (row.s3 === 4) {
      b = 0.05
    } 

    total = ((Math.sqrt(Math.pow(row.c,2) + Math.pow(row.e,2) + Math.pow(row.s,2) + Math.pow(row.a,2) + Math.pow(row.r,2)) + (row.s1 * 0.125) + (row.s3 * 0.125)) + a + b).toFixed(1);

    if (total === '10.0' ) {
      ic = 10;
    } else {
      ic = total;
    }

    return ic;
  }

  domainsLevel(x, y) {

    var doms = [];

        this.state.domains.map((row) => {

          var cod = 0;
          switch (row.s1) {
            case 1:
              cod = 4;
              break;
            case 2:
              cod = 3;
              break;
            case 3:
              cod = 2;
              break;
            case 4:
              cod = 1;
              break;                                          
            default:
              cod = 0;
          }

          var dif = 0;
          switch (row.s3) {
            case 1:
              dif = 4;
              break;
            case 2:
              dif = 3;
              break;
            case 3:
              dif = 2;
              break;
            case 4:
              dif = 1;
              break;                                          
            default:
              dif = 0;
          }

          if (dif === x && cod === y) {

            var a = 0;
            var b = 0;
            var ic = 0;
            var total = 0;
        
            if (row.s1 === 4) {
              a = 0.05
            } 
        
            if (row.s3 === 4) {
              b = 0.05
            } 
        
            total = ((Math.sqrt(Math.pow(row.c,2) + Math.pow(row.e,2) + Math.pow(row.s,2) + Math.pow(row.a,2) + Math.pow(row.r,2)) + (row.s1 * 0.125) + (row.s3 * 0.125)) + a + b).toFixed(1);
        
            if (total === '10.0' ) {
              ic = 10;
            } else {
              ic = total;
            }

            doms.push(row.name + ' (' + ic + ')')
          }
          return true
        });

    return doms;
  }

  domainsCount(q) {

    var c = 0;

        this.state.domains.map((row) => {

          var cod = 0;
          switch (row.s1) {
            case 1:
              cod = 4;
              break;
            case 2:
              cod = 3;
              break;
            case 3:
              cod = 2;
              break;
            case 4:
              cod = 1;
              break;                                          
            default:
              cod = 0;
          }

          var dif = 0;
          switch (row.s3) {
            case 1:
              dif = 4;
              break;
            case 2:
              dif = 3;
              break;
            case 3:
              dif = 2;
              break;
            case 4:
              dif = 1;
              break;                                          
            default:
              dif = 0;
          }

          if (q === 1) {
            if ((dif === 1 && cod === 4) || (dif === 2 && cod === 4) || (dif === 1 && cod === 3) || (dif === 2 && cod === 3)) {
              c++;
            }
          }

          if (q === 2) {
            if ((dif === 3 && cod === 4) || (dif === 4 && cod === 4) || (dif === 3 && cod === 3) || (dif === 4 && cod === 3)) {
              c++;
            }
          }

          if (q === 3) {
            if ((dif === 1 && cod === 2) || (dif === 2 && cod === 2) || (dif === 1 && cod === 1) || (dif === 2 && cod === 1)) {
              c++;
            }
          }

          if (q === 4) {
            if ((dif === 3 && cod === 2) || (dif === 4 && cod === 2) || (dif === 3 && cod === 1) || (dif === 4 && cod === 1)) {
              c++;
            }
          }
          return true
        });

    return c;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
      { this.state.progress ?
        <div>
          <br />
          <LinearProgress />
        </div>
      :
      <div>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <PieChart width={500} height={200} onMouseEnter={this.onPieEnter}>
            <Pie
              data={this.state.pieData}
              cx={260}
              cy={180}
              innerRadius={80}
              outerRadius={120}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              startAngle={180}
              endAngle={0}
              label
            >
              {
                this.state.pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
              }
            </Pie>
            <Tooltip />
          </PieChart>
          <Typography style={{ textAlign: 'center' }}>{this.state.domains.length} Dominios de Conocimiento evaluados</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.root} square={true} elevation={0}>
            <Table className={classes.tableLegend}>
              <colgroup>
                <col width="10px" />
                <col width="400px" />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#ff8a65', border: '1px solid #fff', color: '#263238' }}></TableCell>
                  <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="analysis.people" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#fff176', border: '1px solid #fff', color: '#263238' }} width={'10px'}></TableCell>
                  <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }} width={'400px'}><FormattedMessage id="analysis.corporate" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#4fc3f7', border: '1px solid #fff', color: '#263238' }}></TableCell>
                  <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="analysis.sectorial" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#81c784', border: '1px solid #fff', color: '#263238' }}></TableCell>
                  <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="analysis.universal" /></TableCell>
                </TableRow>            
                </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <Paper className={classes.root} square={true} elevation={0}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff176', border: '1px solid #546e7a' }} width={'50%'} colSpan={2}><FormattedMessage id="analysis.corporate" /> ({this.domainsCount(1)})</TableCell>
              <TableCell style={{ backgroundColor: '#4fc3f7', border: '1px solid #546e7a' }} width={'50%'} colSpan={2}><FormattedMessage id="analysis.sectorial" /> ({this.domainsCount(2)})</TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white' }}></TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', padding: '0px', textAlign: 'center', fontSize: '16px', margin: '0px', float: 'none' }} rowSpan={8}><p style={{transform: 'rotate(180deg)', margin: '0px', padding: '0px', whiteSpace: 'nowrap', writingMode: 'vertical-rl' }}><strong>(-) --- Nivel de Codificación ---> (+)</strong></p></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff9c4', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(1, 4).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#fff9c4', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(2, 4).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#b3e5fc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(3, 4).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#b3e5fc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(4, 4).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center', padding: '0px' }}>4</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff9c4', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(1, 3).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#fff9c4', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(2, 3).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#b3e5fc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(3, 3).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#b3e5fc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(4, 3).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center', padding: '0px' }}>3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#ffccbc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(1, 2).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#ffccbc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(2, 2).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#c8e6c9', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(3, 2).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#c8e6c9', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(4, 2).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center', padding: '0px' }}>2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#ffccbc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(1, 1).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#ffccbc', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(2, 1).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#c8e6c9', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(3, 1).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#c8e6c9', border: '1px solid #546e7a', padding: '0px 6px 0px 6px' }} width={'25%'} height={100}><ul style={{ padding: '0px 0px 0px 24px' }}>{ this.domainsLevel(4, 1).map((row, index) => (<li key={index}>{row}</li>)) }</ul></TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center', padding: '0px' }}>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#ff8a65', border: '1px solid #546e7a' }} width={'25%'} colSpan={2}><FormattedMessage id="analysis.people" /> ({this.domainsCount(3)})</TableCell>
              <TableCell style={{ backgroundColor: '#81c784', border: '1px solid #546e7a' }} width={'25%'} colSpan={2}><FormattedMessage id="analysis.universal" /> ({this.domainsCount(4)})</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center' }} width={'25%'}>1</TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center' }} width={'25%'}>2</TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center' }} width={'25%'}>3</TableCell>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', textAlign: 'center' }} width={'25%'}>4</TableCell>
            </TableRow>            
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff', border: '1px solid white', padding: '0px 0px 0px 6px', textAlign: 'center', fontSize: '16px' }} colSpan={4}><p><strong>(-) --- Nivel de Difusión ---> (+)</strong></p></TableCell>
            </TableRow>
            </TableBody>
        </Table>
      </Paper>
      </div>
        }
      </div>
    );
  }
}

GridLevels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridLevels);