import auth from '../../../Auth';

class HumanCapital {
  constructor() {
    this.getList = this.getList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getSpaceList(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/human_capitals/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getDomainList(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'human_capitals/' + id + '/domains/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  create(name, profile_capital, age, senority, experience, risk, rotation, space_id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'human_capitals', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'POST',
      body: JSON.stringify({
          human_capital: { name: name, profile_capital: profile_capital, age: age, senority: senority, experience: experience, risk: risk, rotation: rotation, space_id: space_id }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  update(id, name, profile_capital, age, senority, experience, risk, rotation, space_id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'human_capitals', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'PUT',
      body: JSON.stringify({
        id: id, human_capital: { name: name, profile_capital: profile_capital, age: age, senority: senority, experience: experience, risk: risk, rotation: rotation, space_id: space_id }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(ids, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'human_capitals', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        human_capital: { ids: ids }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  saveCompetence(id, competences, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'competences/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'PUT',
      body: JSON.stringify({
        id: id, human_capital: { competence: competences }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }


}

export default new HumanCapital();