import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { KMapContext } from '../KMapProvider';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ViewListIcon from '@material-ui/icons/ViewList';
//import OrgIcon from '@material-ui/icons/Business';
import SpaceIcon from '@material-ui/icons/RadioButtonChecked';
//import DomainIcon from '@material-ui/icons/PanoramaFishEye';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';
import space from '../spaces/Space';
import organization from '../organizations/Organization';
import auth from '../Auth';

let counter = 1;
function createData(oid, name, description) {
  counter += 1;
  return { key: counter, id: name, icon: <SpaceIcon />, link: "/spaces/" + oid };
}

const spaceItems = [
  {
    id: <FormattedMessage id="spaces.title" />,
    key: 1,
    children: [],
  }
];

const contextItems = [
  {
    id: <FormattedMessage id="context.title" />,
    key: 27, 
    children: [
      { key: 28, id: <FormattedMessage id="spaces.title" />, icon: <GroupWorkIcon />, link: "/spaces", active: false },
      { key: 29, id: <FormattedMessage id="categories.title" />, icon: <ViewListIcon />, link: "/categories", active: false },
      { key: 30, id: <FormattedMessage id="users.title" />, icon: <PeopleIcon />, link: "/users", active: false },
    ],
  }
];

const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 20,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  organization: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    cursor: 'pointer'
  },
  copy: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.7)',
    fontFamily: theme.typography.fontFamily,
    padding: 12,
    marginTop: 'auto',
  },
  copyLinK: {
    '&:hover': {
      textDecoration: 'none',
    },
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  itemOrg: {
    backgroundColor: '#232f3e',
    paddingTop: 16,
    paddingBottom: 0,
    cursor: 'pointer'
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    '&$textDense': {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing.unit * 2,
  },
  dialogStyle: {
    borderRadius: 0
  }
});

class Navigator extends Component { 

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      data: [],
      fullWidth: true,
      maxWidth: 'sm',
      organizationData: {},
      currentItem: 28
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
    this.handleorgNameChange = this.handleorgNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  componentWillMount() {
    spaceItems[0].children = [];
    space.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) =>
      this.setState(data => ({ data: result.map(n => spaceItems[0].children.push(createData(n._id.$oid, n.name, n.description))) }))
    );
    organization.show(localStorage.getItem('currentOrganization')).then((result) =>
      this.setState({ organizationData: result })
    );
  }

  componentWillReceiveProps() {
    if (this.context.spaceList) {
      spaceItems[0].children = [];
      space.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) => {
        this.setState(data => ({ data: result.map(n => spaceItems[0].children.push(createData(n._id.$oid, n.name, n.description))) }))
        this.context.updateSpaceList(false);
      });
    }
  }

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClickClose() {
    this.setState({ openDialog: false });
  }

  handleGoHome = (event, id, oid) => {
    this.props.history.push("/");
  };

  handleorgNameChange(event) {
    this.setState({ organizationData: { name: event.target.value } });
  }

  handleSubmit(e) {
    e.preventDefault();
    organization.update(localStorage.getItem('currentOrganization'), this.state.organizationData.name, this.state.organizationData.description, () => {
      this.handleClickClose();
    })
  }

  render() {
  
  const { classes, ...other } = this.props;

  return (
    <Drawer variant="permanent" {...other}>
      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.state.openDialog}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialogStyle
        }}
      >
        <DialogTitle id="form-dialog-title">{<FormattedMessage id="organization.title" />}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="organizations.name_message" />
          </DialogContentText>
          <form>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name"><FormattedMessage id="organizations.name" /></InputLabel>
            <Input id="name" value={this.state.organizationData.name} onChange={this.handleorgNameChange} autoFocus />
          </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClickClose} color="primary">
            <FormattedMessage id="cancel" />
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            <FormattedMessage id="save" />
          </Button>
        </DialogActions>
      </Dialog>
      <List disablePadding>
        <ListItem className={classNames(classes.firebase, classes.item, classes.itemOrg)} onClick={this.handleGoHome} style={{ paddingTop: 20, justifyContent: 'center' }}>
          <img src="/kmarker.png" alt="KMap" width="51px" />
        </ListItem>
        <ListItem className={classNames(classes.organization, classes.item, classes.itemCategory)} onClick={this.handleClickOpen} style={{ justifyContent: 'center' }}>
          {this.state.organizationData.name}
        </ListItem>
        { 
          spaceItems.map(({ id, key, children }) => (
          <React.Fragment key={key}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ key, id: childId, link, icon, active }) => (
              <ListItem
                button
                dense
                key={key}
                className={classNames(
                  classes.item,
                  classes.itemActionable,
                  (this.props.history.location.pathname === link) && classes.itemActiveItem,
                )}
                onClick={() => { this.props.history.push(link); }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    textDense: classes.textDense,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        )) }
        { auth.getProfile().role.filter(role => (role.name === "admin" || role.name === "owner")).length > 0 ?
          contextItems.map(({ id, key, children }) => (
          <React.Fragment key={key}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ key, id: childId, link, icon, active }) => (
              <ListItem
                button
                dense
                key={key}
                className={classNames(
                  classes.item,
                  classes.itemActionable,
                  (this.props.history.location.pathname === link) && classes.itemActiveItem,
                )}
                onClick={() => { this.props.history.push(link); }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    textDense: classes.textDense,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        )) : null }              
      </List>
      <Typography className={classes.copy}>
        <Link href="https://ica2.com" target="_blank" className={classes.copyLinK}>© 2023 ICA2 Innovación y Tecnología S.L.</Link>
      </Typography>
    </Drawer>
  );
  }
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);