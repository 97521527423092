import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
//import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import auth from '../Auth'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  }
});

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      elevation={0}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          {/* <CloseIcon className={classes.icon} /> */}
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

class ResetPassword extends Component {

  constructor (props) {
    super(props);
    this.state = {
      password: '',
      password_confirmation: '',
      message: '',
      messageIcon: null,
      passwordMessage: false,
      passwordConfirmationMessage: false
    }
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordCChange = this.handlePasswordCChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  handlePasswordChange(event) {
    this.setState({
        password: event.target.value
    })
  }

  handlePasswordCChange(event) {
    this.setState({
        password_confirmation: event.target.value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      auth.reset(this.props.match.params.reset_password_token, this.state.password, this.state.password_confirmation, (res) => {
        if (res.status === 204) {
          this.setState({
            open: true, message: 'Se ha cambiado su contraseña.', messageIcon: 'success'
          }) 
        } else if (res.status === 422) {
          this.setState({
            open: true, message: 'Verifique la contraseña. Al menos 8 caracteres, una mayúscula, una minúscula y una numérica.', messageIcon: 'warning'
          }) 
        }
      })
    }
  }

  validateForm() {
    if (this.state.password === '') {
      this.setState({
        passwordMessage: true
      })
      return false
    }  
    if (this.state.password_confirmation === '') {
      this.setState({
        passwordConfirmationMessage: true
      })
      return false
    }  
    if (this.state.password !== '' && this.state.password_confirmation !== '') { return true }
  }

  render() {

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Typography component="h2" gutterBottom style={{ marginTop: 16, backgroundImage: 'url(/kmarker.png)', backgroundRepeat: 'no-repeat', backgroundSize: 92, backgroundPosition: 'center', height: 180 }}></Typography>
        <Paper className={classes.paper} elevation={0}>
          <Typography component="h1" variant="h5" style={{ marginTop: 14 }}>
            <FormattedMessage id="reset" />
          </Typography>

          { this.state.open ?
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant={this.state.messageIcon}
              className={classes.margin}
              message={this.state.message}
            />
          : null }

          <form className={classes.form} onSubmit={this.handleSubmit}>
            <FormControl margin="normal" fullWidth error={this.state.passwordMessage}>
              <InputLabel htmlFor="password"><FormattedMessage id="users.password" /></InputLabel>
              <Input name="password" type="password" id="password" value={this.state.password} onChange={this.handlePasswordChange} autoComplete="current-password" />
              { this.state.passwordMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControl margin="normal" fullWidth error={this.state.passwordConfirmationMessage}>
              <InputLabel htmlFor="password_confirmation"><FormattedMessage id="users.password_confirmation" /></InputLabel>
              <Input name="password_confirmation" type="password" id="password_confirmation" value={this.state.password_confirmation} onChange={this.handlePasswordCChange} autoComplete="current-password" />
              { this.state.passwordConfirmationMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <Button
              type="submit"
              fullWidth
              color="primary"
              className={classes.submit}
              elevation={0}
              variant="outlined"
            >
              <FormattedMessage id="reset" />
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPassword);