import React, { Component } from 'react';
import { KMapContext } from '../KMapProvider';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import auth from '../Auth';
import user from './User';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing.unit * 3,
  },
  buttonNew: {
    marginTop: '24px',
    marginBottom: '24px'
  }
});

class Password extends Component {

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      userData: { password: '', password_confirmation: '' },
      openSnack: false,
    }
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordConfirmationChange = this.handlePasswordConfirmationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  componentDidMount() {

  }

  handlePasswordChange(event) {
    this.setState({
      userData: { ...this.state.userData, password: event.target.value }
    })
  }

  handlePasswordConfirmationChange(event) {
    this.setState({
      userData: { ...this.state.userData, password_confirmation: event.target.value }
    })
  }
  
  handleSubmit(e) {
    e.preventDefault();
      user.updatePassword(auth.getProfile().sub, this.state.userData.password, this.state.userData.password_confirmation, () => {
        this.setState({ SnackMessage: <FormattedMessage id="password.saved" />, openSnack: true })
      })
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          <FormattedMessage id="password.edit" />
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password"><FormattedMessage id="users.password" /></InputLabel>
            <Input id="password" type="password" value={this.state.userData.password} onChange={this.handlePasswordChange} />
          </FormControl>     
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password_confirmation"><FormattedMessage id="users.password_confirmation" /></InputLabel>
            <Input id="password_confirmation" type="password" value={this.state.userData.password_confirmation} onChange={this.handlePasswordConfirmationChange} />
          </FormControl>
          <Button onClick={this.handleSubmit} color="primary" variant="outlined" style={{ marginTop: 20 }}>
              <FormattedMessage id="save" />
          </Button>
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>          
      </div>
    );
  }
}

Password.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Password);