import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, PieChart, Pie, Cell, Tooltip } from 'recharts';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import space from '../Space';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    minWidth: 700,
  },
  tableLegend: {
    tableLayout: 'fixed',
    maxWidth: 110,
  },
  dialogStyle: {
    borderRadius: 0
  }
});

const COLORS = [];


class Cesar extends Component {

  constructor (props) {
    super(props);
    this.state = {
      domains: [],
      openDialog: false,
      progress: false,
      radarData: [],
      pieData: [],
      name: '',
      ic: ''
    }
    this.ICcalc = this.ICcalc.bind(this);
    this.colorLevel = this.colorLevel.bind(this);
    this.domainsCount = this.domainsCount.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.setUp = this.setUp.bind(this);
  } 

  componentDidMount() {
    this.setState({ progress : true });
    this.setUp();
  }

  componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.spaceId !== prevProps.spaceId) {
      this.setState({ domains: [], pieData: [], progress : true });
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    space.getCesar(this.props.spaceId).then((result) => {
      this.setState(data => ({ domains: 
                                result.sort(function(a, b){
                                  var icA = this.ICcalc(a);
                                  var icB = this.ICcalc(b);
                                  return icB-icA
                                }.bind(this))
                            }));
      this.setState({
        pieData: this.CreatePieData(), progress: false
      });
    });
  }

  handleClickOpen(name, ic, c, e, s, a, r) {
    this.setState({ openDialog: true, ic: ic, name: name, radarData: [
      { subject: 'Complejidad', A: c, B: c, fullMark: 5, },
      { subject: 'Escasez', A: e, B: e, fullMark: 5, },
      { subject: 'Singularidad', A: s, B: s, fullMark: 5, },
      { subject: 'Aplicabilidad', A: a, B: a, fullMark: 5, },
      { subject: 'Riesgo', A: r, B: r, fullMark: 5, },
    ] });
  }

  handleClickClose() {
    this.setState({ openDialog: false });
  }

  ICcalc(row) {

    var a = 0;
    var b = 0;
    var ic = 0;
    var total = 0;

    if (row.s1 === 4) {
      a = 0.05
    } 

    if (row.s3 === 4) {
      b = 0.05
    } 

    total = ((Math.sqrt(Math.pow(row.c,2) + Math.pow(row.e,2) + Math.pow(row.s,2) + Math.pow(row.a,2) + Math.pow(row.r,2)) + (row.s1 * 0.125) + (row.s3 * 0.125)) + a + b).toFixed(1);

    if (total === '10.0' ) {
      ic = 10;
    } else {
      ic = total;
    }

    return ic;
  }

  domainsCount(level) {
    var count = 0;
    var ic = 0;
    this.state.domains.map((row) => {
      ic = this.ICcalc(row);
      if (level === 1) {
        if ( ic >= 8.5 ) {
          count++;
        }
      }
      if (level === 2) {
        if (ic >= 6.5 && ic < 8.5) {
          count++;
        }
      }
      if (level === 3) {
        if (ic >= 4 && ic < 6.5) {
          count++;
        }
      }
      if (level === 4) {
        if (ic >= 0 && ic < 4) {
          count++;
        }
      }
      return true
    });
    return count;
  }

  colorLevel(ic) {
    if (ic >= 8.5) {
      return '#455a64';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#607d8b';
    } else if (ic >= 4 && ic < 6.5) {
      return '#90a4ae';
    } else if (ic >= 0 && ic < 4) {
      return '#cfd8dc';
    } else {
      return '#FFF';
    }
  }

  colorLevelFont(ic) {
    if (ic >= 8.5) {
      return '#FFF';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#FFF';
    } else if (ic >= 4 && ic < 6.5) {
      return '#000';
    } else if (ic >= 0 && ic < 4) {
      return '#000';
    } else {
      return '#FFF';
    }
  }

  CreatePieData() {
    var data = [];
    if (this.domainsCount(1) > 0) {
      data.push({ name: 'Nivel 1', value: this.domainsCount(1) })
      COLORS.push('#455a64')
    }
    if (this.domainsCount(2) > 0) {
      data.push({ name: 'Nivel 2', value: this.domainsCount(2) })
      COLORS.push('#607d8b')
    }
    if (this.domainsCount(3) > 0) {
      data.push({ name: 'Nivel 3', value: this.domainsCount(3) })
      COLORS.push('#90a4ae')
    }
    if (this.domainsCount(4) > 0) {
      data.push({ name: 'Nivel 4', value: this.domainsCount(4) })
      COLORS.push('#cfd8dc')
    }
    return data;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
      { this.state.progress ?
        <div>
          <br />
          <LinearProgress />
        </div>
      :
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <PieChart width={500} height={200} onMouseEnter={this.onPieEnter}>
              <Pie
                data={this.state.pieData}
                cx={260}
                cy={180}
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                startAngle={180}
                endAngle={0}
                label
              >
                {
                  this.state.pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
            <Typography style={{ textAlign: 'center' }}>{this.state.domains.length} Dominios de Conocimiento evaluados</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.root} square={true} elevation={0}>
              <Table className={classes.tableLegend}>
                <colgroup>
                  <col width="10px" />
                  <col width="400px" />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#455a64', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.one" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#607d8b', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.two" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#90a4ae', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.three" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#cfd8dc', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="level.four" /></TableCell>
                  </TableRow>            
                  </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <colgroup>
              <col width="64%" />
              <col width="6%" />
              <col width="6%" />
              <col width="6%" />
              <col width="6%" />
              <col width="6%" />
              <col width="6%" />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="spaces.domains" /></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'}>IC</TableCell>
                <TableCell style={{ backgroundColor: '#ffa726', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'}>C</TableCell>
                <TableCell style={{ backgroundColor: '#66bb6a', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'}>E</TableCell>
                <TableCell style={{ backgroundColor: '#ab47bc', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'}>S</TableCell>
                <TableCell style={{ backgroundColor: '#42a5f5', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'}>A</TableCell>
                <TableCell style={{ backgroundColor: '#ef5350', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'}>R</TableCell>
              </TableRow>
              {this.state.domains.map((row, index) => (
              <TableRow hover key={index} onClick={ event => {this.handleClickOpen(row.name, this.ICcalc(row), row.c, row.e, row.s, row.a, row.r)} }>
                <TableCell style={{ border: '1px solid #cfd8dc', cursor: 'pointer' }}>{row.name}</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), cursor: 'pointer', color: this.colorLevelFont(this.ICcalc(row)), border: '1px solid #cfd8dc' + this.colorLevel(this.ICcalc(row)) }}><strong>{this.ICcalc(row)}</strong></TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', cursor: 'pointer', padding: 0 }} align={'center'}>{row.c}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', cursor: 'pointer', padding: 0 }} align={'center'}>{row.e}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', cursor: 'pointer', padding: 0 }} align={'center'}>{row.s}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', cursor: 'pointer', padding: 0 }} align={'center'}>{row.a}</TableCell>
                <TableCell style={{ color: '#263238', border: '1px solid #cfd8dc', cursor: 'pointer', padding: 0 }} align={'center'}>{row.r}</TableCell>
              </TableRow>
              )) }
              </TableBody>
          </Table>
        </Paper>

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="analysis.radar" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
                <b>{ this.state.name }</b><br />
                Indice de Criticidad: <b>{ this.state.ic }</b>
            </DialogContentText>
            <RadarChart outerRadius={150} width={450} height={450} data={this.state.radarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" fontSize={14} />
              <PolarRadiusAxis fontSize={14} angle={90} domain={[0, 4]} />
              <Radar name="Cesar" dataKey="A" stroke="#4fc3f7" fill="#b3e5fc" fillOpacity={0.7} label={{ angle: 0 }} />
            </RadarChart>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
        }
      </div>
    );
  }
}

Cesar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cesar);