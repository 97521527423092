import auth from '../../Auth';

class Activity {
  constructor() {
    this.getList = this.getList.bind(this)
    this.getSpaceList = this.getSpaceList.bind(this)
    this.getDomainList = this.getDomainList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async getSpaceList(id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/activities/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async getDomainList(id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'domains/' + id + '/activities/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async create(title, impact, complexity, need, space_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'activities', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          activity: { title: title, impact: impact, complexity: complexity, need: need, space_id: space_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async update(id, title, impact, complexity, need, space_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'activities', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, activity: { title: title, impact: impact, complexity: complexity, need: need, space_id: space_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(ids, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        activity: { ids: ids }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

}

export default new Activity();