import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import KMapBase from './layout/KMapBase';
import SignUp from './auth/SignUp';
import SignIn from './auth/SignIn';
import AcceptInvitation from './auth/AcceptInvitation';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';

class App extends Component {

  render() {

    return (
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Route exact path="/reset/:reset_password_token" component={ResetPassword} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/accept/:invitation_token" component={AcceptInvitation} />
          <ProtectedRoute path="/organizations" component={KMapBase} />
          <ProtectedRoute path="/spaces" component={KMapBase} />
          <ProtectedRoute path="/categories" component={KMapBase} />
          <ProtectedRoute path="/users" component={KMapBase} />
          <ProtectedRoute path="/account" component={KMapBase} />
          <ProtectedRoute exact path="/" component={KMapBase} />
        </Switch>
    );
  }
}

export default App;
