import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import GridLevels from './GridLevels';
import Cesar from './Cesar';
import Functional from './Functional';
import Human from './Human';
import Structural from './Structural';
import Relational from './Relational';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing.unit * 3,
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  tabsRoot: {
    borderBottom: '1px solid #546e7a',
  },
  tabsIndicator: {
    backgroundColor: '#546e7a',
  },
});

class Show extends Component {

  constructor (props) {
    super(props);
    this.state = {
      value: 0,
    }
    
  } 

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>

        <Tabs value={value} textColor="inherit" onChange={this.handleTabChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
          <LinkTab textColor="inherit" label={<FormattedMessage id="analysis.ic" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="analysis.if" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="analysis.ispace" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="analysis.human" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="analysis.structural" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="analysis.relational" />} />
        </Tabs>

        <div>
          {value === 0 && <TabContainer>
            <Cesar spaceId={this.props.spaceId} />                                      
          </TabContainer>}
          {value === 1 && <TabContainer>
            <Functional spaceId={this.props.spaceId} />
          </TabContainer>}
          {value === 2 && <TabContainer>
            <GridLevels spaceId={this.props.spaceId} />
          </TabContainer>}
          {value === 3 && <TabContainer>
            <Human spaceId={this.props.spaceId} />
          </TabContainer>}
          {value === 4 && <TabContainer>
            <Structural spaceId={this.props.spaceId} />
          </TabContainer>} 
          {value === 5 && <TabContainer>
            <Relational spaceId={this.props.spaceId} />
          </TabContainer>}               
        </div>

      </div>
    );
  }
}

Show.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Show);