import React, { Component } from 'react';
import { KMapContext } from '../KMapProvider';
import classNames from 'classnames';
import PropTypes from 'prop-types';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//import LockIcon from '@material-ui/icons/LockOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import auth from '../Auth'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  palette: {
    primary: '#009be5',
  },
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      elevation={0}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

class SignIn extends Component {

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailMessage: false,
      passwordMessage: false,
      open: false,
      openDialog: false,
    }
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
  } 

  handleEmailChange(event) {
    this.setState({
        email: event.target.value,
        emailMessage: false
    })
  }

  handlePasswordChange(event) {
    this.setState({
        password: event.target.value,
        passwordMessage: false
    })
  }

  validateForm() {
    if (this.state.email === '') {
      this.setState({
        emailMessage: true
      })
      return false
    }
    if (this.state.password === '') {
      this.setState({
        passwordMessage: true
      })
      return false
    }
    if (this.state.email !== '' && this.state.password !== '') { return true }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      auth.login(this.state.email, this.state.password, (res) => {
        if (res.status === 201) {
          this.context.updateUser(auth.getProfile());
          this.props.history.push("/");
        } else {
          this.setState({ open: true });
        }
      })
    }
  }

  handleClickOpen() {
    this.setState({ openDialog: true, submitActionTitle: <FormattedMessage id="privacy" /> });
  }

  handleClickClose() {
    this.setState({ openDialog: false });
  }

  render() {

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Typography component="h2" gutterBottom style={{ marginTop: 30, backgroundImage: 'url(/kmarker.png)', backgroundRepeat: 'no-repeat', backgroundSize: 92, backgroundPosition: 'center', height: 180 }}></Typography>
        <Paper className={classes.paper} elevation={0}>
{/*           <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5" style={{ marginTop: 14 }}>
            <FormattedMessage id="signin" />
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            { this.state.open ?
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant="warning"
              className={classes.margin}
              message="Usuario y/o contraseña incorrectos."
            />
            : null }
            <FormControl margin="normal" fullWidth error={this.state.emailMessage}>
              <InputLabel htmlFor="email"><FormattedMessage id="users.email" /></InputLabel>
              <Input id="email" autoComplete="email" value={this.state.email} onChange={this.handleEmailChange} aria-describedby="component-error-text" autoFocus />
              { this.state.emailMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControl margin="normal" fullWidth error={this.state.passwordMessage}>
              <InputLabel htmlFor="password"><FormattedMessage id="users.password" /></InputLabel>
              <Input type="password" id="password" value={this.state.password} onChange={this.handlePasswordChange} aria-describedby="component-error-text" autoComplete="password" />
              { this.state.passwordMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={<FormattedMessage id="users.remember" />}
            />
            <Button
              type="submit"
              fullWidth
              color="primary"
              className={classes.submit}
              elevation={0}
              variant="outlined"
            >
              <FormattedMessage id="signin" />
            </Button>
          </form><br />
          <Typography className={classes.link} component="a" href="/forgot">
            <FormattedMessage id="forgot" />
          </Typography>
          <br />
          <Typography className={classes.link} component="a" href="#privacy" onClick={this.handleClickOpen}>
            <FormattedMessage id="privacy" />
          </Typography>
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="privacy" /></DialogTitle>
          <DialogContent>
            <DialogContentText>
            <Typography style={{ whiteSpace: "break-spaces" }}>
            Bienvenido, somos ICADOS Innovación y Tecnología, S.L. Somos los propietarios de esta aplicación web, kmap.ica2.com. A continuación se presenta nuestra política de privacidad:
            <br/><br/>
            1.- Información sobre el responsable del tratamiento de los datos personales:
            <br/><br/>
            La responsable del tratamiento de los datos recabados a través de los sitios web https://www.ica2.com (en adelante, la “Web” o el “Sitio Web”) es ICADOS Innovación y Tecnología, S.L., con C.I.F. nº B85006088, con domicilio en C/ Mar Negro, 30, Tres Cantos, Madrid (28760), e inscrita en el Registro Mercantil de Madrid al Tomo 24019 , Folio 140, Sección 8, Hoja: M-431413. En adelante ICADOS Innovación y Tecnología.
            <br/><br/>
            Puedes ponerte en contacto con ICADOS Innovación y Tecnología, para cualquier aspecto relacionado con esta política de privacidad, en la dirección de correo electrónico: info@ica2.com
            <br/><br/>
            2.- Finalidad del tratamiento de los datos personales:
            <br/><br/>
            2.1. ¿Con qué finalidad trataremos tus datos personales? En ICADOS Innovación y Tecnología trataremos tus datos personales, recabados a través del Sitio Web con las siguientes finalidades:
            <br/><br/>
            En caso de contratación de los bienes y servicios ofertados a través del Sitio Web, para mantener la relación contractual, así como la gestión, administración, información, prestación y mejora del servicio.
            Envío de información solicitada a través de los formularios dispuestos en el Sitio Web.
            Remitir boletines (newsletters), así como comunicaciones comerciales de promociones y/o publicidad del Sitio Web y de nuestros servicios / actividades.
            Te recordamos que puedes oponerte al envío de comunicaciones comerciales por cualquier vía y en cualquier momento, remitiendo un correo electrónico a la dirección de correo electrónico indicada anteriormente.
            <br/><br/>
            Los campos de dichos registros son de cumplimentación obligatoria, siendo imposible realizar las finalidades expresadas si no se aportan esos datos.
            <br/><br/>
            2.2. ¿Por cuánto tiempo se conservan los datos personales recabados? Los datos personales proporcionados se conservarán mientras se mantenga la relación comercial o no solicites su supresión y durante el plazo por el cuál pudieran derivarse responsabilidades legales por los servicios prestados.
            <br/><br/>
            3.- Legitimación:
            <br/><br/>
            El tratamiento de tus datos se realiza con las siguientes bases jurídicas que legitiman el mismo:
            <br/><br/>
            La solicitud de información y/o la contratación de los servicios de ICADOS Innovación y Tecnología, cuyos términos y condiciones se pondrán a tu disposición en todo caso, de forma previa a una eventual contratación.
            El consentimiento libre, específico, informado e inequívoco, en tanto que te informamos poniendo a tu disposición la presente política de privacidad, que tras la lectura de la misma, en caso de estar conforme, puedes aceptar mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al efecto.
            En caso de que no nos facilites tus datos o los hagas de forma errónea o incompleta, no podremos atender tu solicitud, resultando del todo imposible proporcionarte la información solicitada o llevar a cabo la contratación de los servicios.
            <br/><br/>
            4.- Destinatarios:
            <br/><br/>
            Los datos no se comunicarán a ningún tercero ajeno a ICADOS Innovación y Tecnología, salvo obligación legal.
            <br/><br/>
            5.- Derechos:
            <br/><br/>
            Como interesado que nos has proporcionado tus datos personales, tienes pleno derecho a obtener confirmación sobre si en ICADOS Innovación y Tecnología estamos tratando tus datos personales, y en concreto estás facultado para ejercitar los siguientes derechos que la normativa en materia de protección de datos te reconoce, conforme a lo previsto en la misma:
            <br/><br/>
            Derecho de ACCESO a tus datos personales.<br/>
            Derechos de solicitar la RECTIFICACIÓN de los datos inexactos.<br/>
            Derecho de solicitar su SUPRESIÓN cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.<br/>
            En determinadas circunstancias, podrás solicitar la LIMITACIÓN DEL TRATAMIENTO de tus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.<br/>
            En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán ejercitar su derecho de OPOSICIÓN al tratamiento de sus datos. Dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.<br/>
            En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás solicitar su derecho a la PORTABILIDAD de los datos.<br/>
            Podrás ejercer tales derechos dirigiendo una comunicación a la dirección postal y/o de correo electrónico que se hace constar en el apartado “1. Información sobre el responsable del tratamiento de los datos personales.”<br/>

            Además, en caso de que se haya vulnerado cualquiera de tus derechos, el interesado tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD), en C/ Jorge Juan, 6, 28001-Madrid o a través de la sede electrónica de la AEPD: https://sedeagpd.gob.es/sede-electronica-web/
            </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cerrar" />
            </Button>
          </DialogActions>
        </Dialog>

      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);