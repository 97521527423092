import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { KMapContext } from '../KMapProvider';
import AppBar from '@material-ui/core/AppBar';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import Header from './Header';

import Profile from './Profile';
import { FormattedMessage } from 'react-intl';
import Password from './Password';
import Billing from './Billing';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginTop: 20,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing.unit * 3,
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  }
});

class Account extends Component {

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      value: 0
    }


  } 

  componentDidMount() {

  }


  handleTabChange = (event, value) => {
    this.setState({ value });
  }



  render() {

    const { classes } = this.props;
    const { value } = this.state;
    const can = (this.context.userRoles.filter(role => (role.name === "owner" || role.name === "admin")).length > 0)

    return (
      <div>
      <Header title={<FormattedMessage id="account" />} showtabs={true} />
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
        children={''}
      >
        <Tabs value={value} textColor="inherit" onChange={this.handleTabChange}>
          <LinkTab textColor="inherit" label={<FormattedMessage id="account.profile" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="account.password" />} />
          { can ?
          <LinkTab textColor="inherit" label={<FormattedMessage id="account.billing" />} />
          : null }
        </Tabs>
      </AppBar>
      <main className={classes.main}>
        {value === 0 && <TabContainer>
          <Profile />
        </TabContainer>}        
        {value === 1 && <TabContainer>
          <Password />
        </TabContainer>}
        {value === 2 && can && <TabContainer>
          <Billing />
        </TabContainer>}
      </main>
      </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Account);