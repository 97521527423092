import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import auth from '../Auth';
import user from './User';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing.unit * 3,
  },
  buttonNew: {
    marginTop: '24px',
    marginBottom: '24px'
  }
});

class Billing extends Component {

  constructor (props) {
    super(props);
    this.state = {
      userData: {},
      value: 0
    }
  } 

  componentDidMount() {
    user.show(auth.getProfile().sub).then((result) =>
      this.setState({ userData: result })
    ); 
  }
  
  handleClickOpen() {
    this.setState(data => ({
      openDialog: true,
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="users.new" />,
    }))
  }

  handleClickEdit = (spaceData) => {
    this.setState({ 
      openDialog: true,
      submitAction: 'edit',
      submitActionTitle: <FormattedMessage id="users.edit" />,
      spaceData: spaceData
    });
  }

  handleUserUdate(e) {
    e.preventDefault();
    user.update(this.props.match.params.id, this.state.spaceData.name, this.state.spaceData.type, this.state.spaceData.purpose, this.state.spaceData.products, () => {
      this.setState({ openSnack: true, SnackMessage: <FormattedMessage id="spaces.updated" /> });
      this.handleClickClose();
    })
  }

  handleClickClose() {
    this.setState({ 
      openDialog: false
    });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          <FormattedMessage id="account.billing" />
        </Typography>

      </div>
    );
  }
}

Billing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Billing);