import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { KMapContext } from '../../../KMapProvider';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import CompetenceIcon from '@material-ui/icons/VerifiedUser';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';
//import StarsIcon from '@material-ui/icons/Stars';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../../layout/Snack';
import blue from '@material-ui/core/colors/blue';
import { FormattedMessage } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import profile from './ProfileCapital';

let counter = 0;
function createData(oid, name, people, created_at) {
  counter += 1;
  return { id: counter, oid, name, people, created_at };
}

let counterD = 0;
function createDomainData(oid, title, category, created_at) {
  counterD += 1;
  return { id: counterD, oid, title, category, created_at };
}

function createCompetenceData(competences) {
  return { domain_id: competences[0] ? competences[0].domain_id.$oid : -1, value: competences[0] ? competences[0].value : -1 };
} 

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name', disablePadding: false, label: <FormattedMessage id="profile.name" /> },
  { id: 'people', disablePadding: true, label: <FormattedMessage id="profile.people" /> },
  { id: 'evaluation', disablePadding: true, label: <FormattedMessage id="profile.skill" /> },
];

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, canContext } = this.props;

    return (
      <TableHead>
        <TableRow>
          { canContext ?
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              indeterminateIcon={<IndeterminateCheckBox fontSize="small" />}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />}
            />
          </TableCell>
          : null }
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                
                padding={ row.disablePadding ? 'none' : 'dense' }
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, canContext } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} seleccionado
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            <FormattedMessage id="resources.profile" />
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Eliminar">
            <IconButton aria-label="Eliminar" onClick={event => props.deleteAction()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          (canContext) ?
          <Tooltip title={<FormattedMessage id="profile.add" />}>
            <IconButton aria-label={<FormattedMessage id="profile.add" />} onClick={props.action}>
              <AddIcon />
            </IconButton>
          </Tooltip> : null
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  selectControl: {
    width: '100%',
  },
  tableCell: {
    cursor: 'pointer',
  },
  dialogStyle: {
    borderRadius: 0
  },
  radioLow: {
    color: blue[100],
    '&$checkedLow': {
      color: blue[200],
    },
  },
  checkedLow: {},
  radioMedium: {
    color: blue[300],
    '&$checkedMedium': {
      color: blue[400],
    },
  },
  checkedMedium: {},
  radioHigh: {
    color: blue[500],
    '&$checkedHigh': {
      color: blue[600],
    },
  },
  checkedHigh: {},
  radioVeryHigh: {
    color: blue[700],
    '&$checkedVeryHigh': {
      color: blue[800],
    },
  },
  checkedVeryHigh: {},
  label: {
    fontSize: '12px',
  } 
});

class ProfileTable extends React.Component {

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      OIDselected: [],
      profiles: [],
      domains: [],
      page: 0,
      rowsPerPage: 5,
      openDialog: false,
      openEvaluationDialog: false,
      openSnack: false,
      id: '',
      name: '',
      people: '',
      currentProfile: '',
      competence: [],
      disableCompetenceSave: true,
      fullWidth: true,
      maxWidth: 'md',
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="profile.add" />,
      openDelDialog: false
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePeopleChange = this.handlePeopleChange.bind(this);

    this.handleCompetenceChange = this.handleCompetenceChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openDelDialog = this.openDelDialog.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDelClickClose = this.handleDelClickClose.bind(this);

    this.handleEvaluationClickOpen = this.handleEvaluationClickOpen.bind(this);
    this.handleEvaluationClickClose = this.handleEvaluationClickClose.bind(this);
    this.handleEvaluationSubmit = this.handleEvaluationSubmit.bind(this);
    
  } 

  componentDidMount() {
    profile.getSpaceList(this.props.spaceId).then((result) =>
      this.setState(data => ({ profiles: result.map(n => createData(n._id.$oid, n.name, n.people, n.created_at)) }))
    );
  } 

  componentDidUpdate(prevProps, prevState) {

    if (this.state.openEvaluationDialog) {
      
      var choices = 0;
      
      for (var i=0;i<this.state.domains.length;i++){
        var el = document.querySelector("input[name=radio-button-competence-" + i + "]:checked")
        if ( el ) {
          choices++;
        }
      }
      
      if (choices > 0 && (choices === this.state.domains.length) && prevState.disableCompetenceSave) {
        this.setState({ disableCompetenceSave: false });
      }

    } 

  }

  componentWillReceiveProps(nextProps) {
    profile.getSpaceList(this.props.spaceId).then((result) =>
      this.setState(data => ({ profiles: result.map(n => createData(n._id.$oid, n.name, n.people, n.created_at)) }))
    );
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.profiles.map(n => n.id), OIDselected: state.profiles.map(n => n.oid) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, oid, name, people) => {
    this.setState({ openDialog: true, submitAction: 'edit', submitActionTitle: <FormattedMessage id="profile.editing" />, id: oid, name: name, people: people });
  };

  handleCheck = (event, id, oid) => {
    const { selected, OIDselected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newOIDSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newOIDSelected = newOIDSelected.concat(OIDselected, oid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newOIDSelected = newOIDSelected.concat(
        OIDselected.slice(0, selectedIndex),
        OIDselected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected, OIDselected: newOIDSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handlePeopleChange(event) {
    this.setState({ people: event.target.value });
  }

  handleClickOpen() {
    this.setState({ maxWidth: 'md', openDialog: true, submitAction: 'new', submitActionTitle: <FormattedMessage id="profile.add" /> });
  }

  handleClickClose() {
    this.setState({ openDialog: false, id: '', name: '', people: '' });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  handleEvaluationClickOpen(currentProfile) {
    profile.getDomainList(currentProfile.oid).then((result) => {
      this.setState(data => ({ domains: result.map(n => createDomainData(n._id.$oid, n.name, n.category, n.created_at)), competence: result.map(n => createCompetenceData(n.competences)) }));
    });
    this.setState({ openEvaluationDialog: true, currentProfile: currentProfile });
  }

  handleEvaluationClickClose() {
    this.setState({ openEvaluationDialog: false, competence: [], domains: [], disableCompetenceSave: true });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
      profile.create(this.state.name, this.state.people, this.props.spaceId, () => {
        profile.getSpaceList(this.props.spaceId).then((result) => {
          this.setState(data => ({
              profiles: result.map(n => createData(n._id.$oid, n.name, n.people, n.created_at)),
              id: '',
              name: '',
              people: '',
              SnackMessage: <FormattedMessage id="profile.created" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    } else {
      profile.update(this.state.id, this.state.name, this.state.people, this.props.spaceId, () => {
        profile.getSpaceList(this.props.spaceId).then((result) => {
            this.setState(data => ({
              profiles: result.map(n => createData(n._id.$oid, n.name, n.people, n.created_at)),
              id: '',
              name: '',
              people: '',
              SnackMessage: <FormattedMessage id="profile.updated" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    }
  }

  openDelDialog() {
    this.setState(data => ({ openDelDialog: true }));
  }

  handleDelClickClose() {
    this.setState({ openDelDialog: false });
  }

  handleDelete = () => {
    profile.delete(this.state.OIDselected).then((result) => {
      profile.getSpaceList(this.props.spaceId).then((result) => {
        this.setState(data => ({
            profiles: result.map(n => createData(n._id.$oid, n.name, n.people, n.created_at)),
            id: '',
            name: '',
            people: '',
            SnackMessage: <FormattedMessage id="profile.deleted" />,
            openSnack: true,
            selected: [],
            OIDselected: []
          }));
          this.handleDelClickClose();
        }
      )
    })
  };
  
  handleEvaluationSubmit(e) {
    e.preventDefault();
    profile.saveCompetence(this.state.currentProfile.oid, this.state.competence).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="competence.updated" />,
        openSnack: true
      });
      this.handleEvaluationClickClose();
    })
  }

  handleCompetenceChange = (index, domain_id) => (event) => {

    let i = this.state.competence;
    i[index] = { domain_id: domain_id, value: event.target.value };
    this.setState({ competence: i });
    
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { profiles, domains, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, profiles.length - page * rowsPerPage);
    const can = (this.context.userRoles.filter(role => ((role.name === "editor" && role.resource_id.$oid === this.props.spaceId) || role.name === "owner" || role.name === "admin")).length > 0);

    return (
      <Paper className={classes.root} elevation={0} square={true}>
        <EnhancedTableToolbar numSelected={selected.length} action={this.handleClickOpen} deleteAction={this.openDelDialog} canContext={can} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={profiles.length}
              canContext={can}
            />
            <TableBody>
              {stableSort(profiles, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      key={n.id}
                    >
                      { can ?
                      <TableCell padding="checkbox" onClick={event => this.handleCheck(event, n.id, n.oid)} role="checkbox" aria-checked={isSelected} selected={isSelected}>
                        <Checkbox checked={isSelected} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                      </TableCell>
                      : null }
                      <TableCell component="th" scope="row" padding="dense" onClick={event => can ? this.handleClick(event, n.id, n.oid, n.name, n.people) : null } className={classes.tableCell}>
                        {n.name}
                      </TableCell>
                      <TableCell padding={'none'}>
                        {n.people}                     
                      </TableCell>
                      <TableCell padding="none">
                        <Tooltip title={<FormattedMessage id="relational.competence" />}>
                          <IconButton aria-label={<FormattedMessage id="relational.competence" />} onClick={event => this.handleEvaluationClickOpen(n)}>
                            <CompetenceIcon />
                          </IconButton>
                        </Tooltip>                        
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={profiles.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={<FormattedMessage id="rows_per_page" />}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${ navigator.language === 'es-ES' ? 'de' : 'of' } ${count}`}          
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="title"><FormattedMessage id="profile.name" /></InputLabel>
                <Input id="name" value={this.state.name} onChange={this.handleNameChange} autoFocus />
              </FormControl>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="people"><FormattedMessage id="profile.people" /></InputLabel>
                <Input id="people" value={this.state.people} onChange={this.handlePeopleChange} />
              </FormControl>                                    
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary" disabled={!(this.state.name !== '' && this.state.people !== '')}>
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openEvaluationDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title"><CompetenceIcon style={{ marginRight: 7, verticalAlign: 'text-bottom' }} />{<FormattedMessage id="relational.competence" />}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentProfile.name }</b><br/>
              Valoración del nivel de competencia por dominio de conocimiento.
            </DialogContentText>
            <form>
            <Table className={classes.dialogTable}>
                <TableHead>
                  <TableRow>
                    <TableCell><FormattedMessage id="domains.title" /></TableCell>
                    <TableCell><FormattedMessage id="human.skill" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {domains.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell width="70%">
                    { can ?
                    <FormControl component="fieldset">
                      <RadioGroup row>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.competence.length > 0 ? parseInt(this.state.competence[index].value) === 0 : false}
                              onChange={this.handleCompetenceChange(index, row.oid)}
                              value="0"
                              classes={{
                                root: classes.radioLow,
                                checked: classes.checkedLow,
                              }}
                              name={"radio-button-competence-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                        
                          }
                          label={<FormattedMessage id="skill.one"/>}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                            checked={this.state.competence.length > 0 ? parseInt(this.state.competence[index].value) === 1 : false}
                              onChange={this.handleCompetenceChange(index, row.oid)}
                              value="1"
                              classes={{
                                root: classes.radioMedium,
                                checked: classes.checkedMedium,
                              }}
                              name={"radio-button-competence-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                       
                          }
                          label={<FormattedMessage id="skill.two" />}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                            checked={this.state.competence.length > 0 ? parseInt(this.state.competence[index].value) === 2 : false}
                              onChange={this.handleCompetenceChange(index, row.oid)}
                              value="2"
                              classes={{
                                root: classes.radioHigh,
                                checked: classes.checkedHigh,
                              }}
                              name={"radio-button-competence-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          label={<FormattedMessage id="skill.three" />}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                            checked={this.state.competence.length > 0 ? parseInt(this.state.competence[index].value) === 3 : false}
                              onChange={this.handleCompetenceChange(index, row.oid)}
                              value="3"
                              classes={{
                                root: classes.radioVeryHigh,
                                checked: classes.checkedVeryHigh,
                              }}
                              name={"radio-button-competence-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          label={<FormattedMessage id="skill.four" />}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.competence.length > 0 ? parseInt(this.state.competence[index].value) === 4 : false}
                              onChange={this.handleCompetenceChange(index, row.oid)}
                              value="4"
                              classes={{
                                root: classes.radioVeryHigh,
                                checked: classes.checkedVeryHigh,
                              }}
                              name={"radio-button-competence-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          label={<FormattedMessage id="skill.five" />}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={this.state.competence.length > 0 ? parseInt(this.state.competence[index].value) === 5 : false}
                              onChange={this.handleCompetenceChange(index, row.oid)}
                              value="5"
                              classes={{
                                root: classes.radioVeryHigh,
                                checked: classes.checkedVeryHigh,
                              }}
                              name={"radio-button-competence-" + index}
                              aria-label="E"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                            />                     
                          }
                          label={<FormattedMessage id="skill.six" />}
                          classes={{
                            label: classes.label,
                          }}
                          labelPlacement="bottom"
                        />
                        </RadioGroup>
                      </FormControl>
                      :
                      (() => {
                          if (this.state.competence.length > 0) {
                            switch(parseInt(this.state.competence[index].value)) {
                              case 0:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="skill.one" /></Typography><LinearProgress variant="determinate" value={16.6666666} /></div>;
                              case 1:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="skill.two" /></Typography><LinearProgress variant="determinate" value={33.3333332} /></div>;
                              case 2:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="skill.three" /></Typography><LinearProgress variant="determinate" value={49.9999998} /></div>;
                              case 3:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="skill.four" /></Typography><LinearProgress variant="determinate" value={66.6666664} /></div>;
                              case 4:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="skill.five" /></Typography><LinearProgress variant="determinate" value={83.3333333} /></div>;
                              case 5:
                                return <div><Typography className={classes.levelCellTypo}><FormattedMessage id="skill.six" /></Typography><LinearProgress variant="determinate" value={100} /></div>;                                                                
                              default:
                                return null;
                            }
                          }
                        })()}         
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>    
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleEvaluationClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleEvaluationSubmit} color="primary" disabled={this.state.disableCompetenceSave}>
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.openDelDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Eliminar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>¿Estás seguro?</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              <FormattedMessage id="delete" />
            </Button>
          </DialogActions>
        </Dialog>

      </Paper>
    );
  }
}

ProfileTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileTable);