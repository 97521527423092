import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import auth from '../Auth'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class SignUp extends Component {

  constructor (props) {
    super(props);
    this.state = {
      name: '',
      organization: '',
      email: '',
      password: '',
      password_confirmation: '',
      nameMessage: false,
      organizationMessage: false,
      emailMessage: false,
      passwordMessage: false,
      passwordConfirmationMessage: false
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordCChange = this.handlePasswordCChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  handleNameChange(event) {
    this.setState({
        name: event.target.value
    })
  }
  
  handleOrgChange(event) {
    this.setState({
        organization: event.target.value
    })
  }

  handleEmailChange(event) {
    this.setState({
        email: event.target.value
    })
  }

  handlePasswordChange(event) {
    this.setState({
        password: event.target.value
    })
  }

  handlePasswordCChange(event) {
    this.setState({
        password_confirmation: event.target.value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      auth.register(this.state.name, this.state.organization, this.state.email, this.state.password, this.state.password_confirmation, () => {
        this.props.history.push("/");
      })
    }
  }

  validateForm() {
    if (this.state.name === '') {
      this.setState({
        nameMessage: true
      })
      return false
    }
    if (this.state.organization === '') {
      this.setState({
        organizationMessage: true
      })
      return false
    }
    if (this.state.email === '') {
      this.setState({
        emailMessage: true
      })
      return false
    } 
    if (this.state.password === '') {
      this.setState({
        passwordMessage: true
      })
      return false
    }  
    if (this.state.password_confirmation === '') {
      this.setState({
        passwordConfirmationMessage: true
      })
      return false
    }  
    if (this.state.name !== '' && this.state.organization !== '' && this.state.email !== '' && this.state.password !== '' && this.state.password_confirmation !== '') { return true }
  }

  render() {

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Typography component="h2" gutterBottom style={{ marginTop: 16, backgroundImage: 'url(/kmarker.png)', backgroundRepeat: 'no-repeat', backgroundSize: 92, backgroundPosition: 'center', height: 180 }}></Typography>
        <Paper className={classes.paper} elevation={0}>
{/*           <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5" style={{ marginTop: 14 }}>
            <FormattedMessage id="signup" />
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <FormControl margin="normal" fullWidth error={this.state.nameMessage}>
              <InputLabel htmlFor="name"><FormattedMessage id="users.fullname" /></InputLabel>
              <Input id="name" name="name" autoComplete="name" value={this.state.name} onChange={this.handleNameChange} autoFocus />
              { this.state.nameMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControl margin="normal" fullWidth error={this.state.organizationMessage}>
              <InputLabel htmlFor="organization"><FormattedMessage id="organization.title" /></InputLabel>
              <Input id="organization" name="organization" autoComplete="organization" value={this.state.organization} onChange={this.handleOrgChange} />
              { this.state.organizationMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>            
            <FormControl margin="normal" fullWidth error={this.state.emailMessage}>
              <InputLabel htmlFor="email"><FormattedMessage id="users.email" /></InputLabel>
              <Input id="email" name="email" autoComplete="email" value={this.state.email} onChange={this.handleEmailChange} />
              { this.state.emailMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControl margin="normal" fullWidth error={this.state.passwordMessage}>
              <InputLabel htmlFor="password"><FormattedMessage id="users.password" /></InputLabel>
              <Input name="password" type="password" id="password" value={this.state.password} onChange={this.handlePasswordChange} autoComplete="current-password" />
              { this.state.passwordMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControl margin="normal" fullWidth error={this.state.passwordConfirmationMessage}>
              <InputLabel htmlFor="password_confirmation"><FormattedMessage id="users.password_confirmation" /></InputLabel>
              <Input name="password_confirmation" type="password" id="password_confirmation" value={this.state.password_confirmation} onChange={this.handlePasswordCChange} autoComplete="current-password" />
              { this.state.passwordConfirmationMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <Button
              type="submit"
              fullWidth
              color="primary"
              className={classes.submit}
              elevation={0}
              variant="outlined"
            >
              <FormattedMessage id="signup" />
            </Button>
          </form><br />
          <Typography className={classes.link} component="a" href="/signin">
            <FormattedMessage id="signin" />
          </Typography>
        </Paper>
      </main>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignUp);