import auth from '../Auth';

class Space {
  constructor() {
    this.getList = this.getList.bind(this)
    this.getOrganizationList = this.getOrganizationList.bind(this)
    this.getUserList = this.getUserList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  async getList(cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async getOrganizationList(organization_id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations/' + organization_id + '/spaces/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  getUserList(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/users', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  create(name, type, organization_id, purpose, products, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'POST',
      body: JSON.stringify({
          space: { name: name, type: type, organization_id: organization_id, purpose: purpose, products: products }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  update(id, name, type, purpose, products, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'PUT',
      body: JSON.stringify({
        id: id, space: { name: name, type: type, purpose: purpose, products: products }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(ids, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
          space: { ids: ids }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  async getCesar(id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/cesar/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async getFunctional(id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/functional/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  getCompetence(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/competence/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getProfileCompetence(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/profile_competence/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getProfileHumansCompetence(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'profile_capitals/' + id + '/humans_competence/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getCoverageAndAccessibility(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/coverage_and_accessibility', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getFrequencyAndDpendency(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/frequency_and_dependency', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

}

export default new Space();