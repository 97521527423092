import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { KMapContext } from '../KMapProvider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Header from './Header';
import space from './Space';
import { FormattedMessage, FormattedTime } from 'react-intl';
//import UsersTable from './users/UsersTable';
import ActivitiesTable from './activities/ActivitiesTable';
import ChallengesTable from './challenges/ChallengesTable';
import DomainsTable from './domains/DomainsTable';
//import SnapshotsTable from './snapshots/SnapshotsTable';
import MainAnalysis from './analysis/Main';
import MainResources from './resources/Main';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const types = [
  'Área',
  'Proceso',
  'Proyecto',
  'Tópico',
];

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing.unit * 3,
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  }
});

class Show extends Component {

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      spaceData: {},
      value: 0,
      submitActionTitle: <FormattedMessage id="spaces.new" />,
      openDialog: false
    }

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handlePurposeChange = this.handlePurposeChange.bind(this);
    this.handleProductsChange = this.handleProductsChange.bind(this);
    this.handleSpaceUdate = this.handleSpaceUdate.bind(this);
  } 

  componentDidMount() {
    space.show(this.props.match.params.id).then((result) =>
      this.setState({ spaceData: result })
    ); 
  }

  componentWillReceiveProps(nextProps) {
    space.show(nextProps.match.params.id).then((result) =>
      this.setState({ spaceData: result })
    ); 
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  handleNameChange(event) {
    this.setState({
      spaceData: { ...this.state.spaceData, name: event.target.value }
    })
  }

  handleTypeChange(event) {
    this.setState({
      spaceData: { ...this.state.spaceData, type: event.target.value }
    })
  }

  handlePurposeChange(event) {
    this.setState({
      spaceData: { ...this.state.spaceData, purpose: event.target.value }
    })
  }

  handleProductsChange(event) {
    this.setState({
      spaceData: { ...this.state.spaceData, products: event.target.value }
    })
  }

  handleClickOpen() {
    this.setState(data => ({
      openDialog: true,
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="spaces.new" />,
    }))
  }

  handleClickEdit = (spaceData) => {
    this.setState({ 
      openDialog: true,
      submitAction: 'edit',
      submitActionTitle: <FormattedMessage id="spaces.edit" />,
      spaceData: spaceData
    });
  }

  handleSpaceUdate(e) {
    e.preventDefault();
    space.update(this.props.match.params.id, this.state.spaceData.name, this.state.spaceData.type, this.state.spaceData.purpose, this.state.spaceData.products, () => {
      this.setState({ openSnack: true, SnackMessage: <FormattedMessage id="spaces.updated" /> });
      this.handleClickClose();
    })
  }

  handleClickClose() {
    this.setState({ 
      openDialog: false
    });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;
    const can = (this.context.userRoles.filter(role => ((role.name === "editor" && role.resource_id.$oid === this.props.match.params.id) || role.name === "owner" || role.name === "admin")).length > 0)

    return (
      <div>
      <Header title={ this.state.spaceData.name } showtabs={true} />
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
        children={''}
      >
        <Tabs value={value} textColor="inherit" onChange={this.handleTabChange}>
          <LinkTab textColor="inherit" label={<FormattedMessage id="spaces.info" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="spaces.activities" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="spaces.domains" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="spaces.resources" />} />
          <LinkTab textColor="inherit" label={<FormattedMessage id="spaces.analysis" />} />
          {/* <LinkTab textColor="inherit" label={<FormattedMessage id="spaces.snapshots" />} /> */}
        </Tabs>
      </AppBar>
      <main className={classes.main}>
        {value === 0 && <TabContainer>
            { can ?
            <Tooltip title={<FormattedMessage id="spaces.edit" />} placement={'right'}>
              <IconButton onClick={event => { this.handleClickEdit(this.state.spaceData) }} aria-label={<FormattedMessage id="spaces.edit" />}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            : null }
            <Typography variant="body2" className={classes.subTitle} gutterBottom>
              { this.state.spaceData.name + ' - ' + this.state.spaceData.type }
            </Typography>
            <Typography variant="body2" className={classes.subTitle} gutterBottom>
              <FormattedTime value={new Date(this.state.spaceData.created_at)} year='numeric' month='long' day='2-digit' />
            </Typography>            
            <Typography variant="h6" className={classes.subTitle} gutterBottom>
              <FormattedMessage id="spaces.purpose" />
            </Typography>
            <Typography>
              { this.state.spaceData.purpose }
            </Typography>
            <Typography variant="h6" className={classes.subTitle} gutterBottom>
              <FormattedMessage id="spaces.products" />
            </Typography>            
            <Typography style={{ whiteSpace: "break-spaces" }}>
              { this.state.spaceData.products }
            </Typography>                                                
          </TabContainer>}
          <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.openDialog}
            aria-labelledby="form-dialog-title"
            classes={{
              paper: classes.dialogStyle
            }}
          >
          <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                
              </DialogContentText>
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="name"><FormattedMessage id="spaces.name" /></InputLabel>
                  <Input id="name" value={this.state.spaceData.name} onChange={this.handleNameChange} autoFocus />
                </FormControl>
                <FormControl className={classes.selectControl}>
                  <InputLabel htmlFor="select-multiple"><FormattedMessage id="spaces.type" /></InputLabel>
                  <Select
                    value={this.state.spaceData.type}
                    onChange={this.handleTypeChange}
                    input={<Input name="type" id="type-helper" />}
                    MenuProps={{ classes: { paper: classes.dialogStyle } }}
                  >
                    {types.map(type => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>          
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="purpose"><FormattedMessage id="spaces.purpose" /></InputLabel>
                  <Input id="purpose" value={this.state.spaceData.purpose} onChange={this.handlePurposeChange} multiline={true} rows="4" />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="products"><FormattedMessage id="spaces.products" /></InputLabel>
                  <Input id="products" value={this.state.spaceData.products} onChange={this.handleProductsChange} multiline={true} rows="4" />
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClickClose} color="primary">
                <FormattedMessage id="cancel" />
              </Button>
              <Button onClick={this.handleSpaceUdate} color="primary">
                <FormattedMessage id="save" />
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.openSnack}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant="success"
              message={this.state.SnackMessage}
            />
          </Snackbar>
        {value === 1 && <TabContainer>
          <ActivitiesTable spaceId={this.props.match.params.id} />
          <ChallengesTable spaceId={this.props.match.params.id} />
        </TabContainer>} 
        {value === 2 && <TabContainer>
          <DomainsTable spaceId={this.props.match.params.id} />
        </TabContainer>} 
        {value === 3 && <TabContainer>
          <MainResources spaceId={this.props.match.params.id} />
        </TabContainer>}  
        {value === 4 && <TabContainer>
          <MainAnalysis spaceId={this.props.match.params.id} />
        </TabContainer>} 
{/*         {value === 5 && can && <TabContainer>
          <SnapshotsTable spaceId={this.props.match.params.id} />
        </TabContainer>}   */}    
      </main>
      </div>
    );
  }
}

Show.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Show);