import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import auth from '../Auth';
import { KMapConsumer } from '../KMapProvider';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing.unit,
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  avatar: {
    margin: 10,
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
});



class Header extends Component { 

  constructor (props) {
    super(props);
    this.state = { anchorEl: null }
    this.handleAccount = this.handleAccount.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  } 

  handleLogout(e) {
    e.preventDefault();
    auth.logout(() => {
      this.props.history.push("/signin");
      this.handleClose();
    })
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAccount = event => {
    this.props.history.push("/account");
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleGoHome = (event, id, oid) => {
    this.props.history.push("/");
  };

  render() {

    const { classes, onDrawerToggle } = this.props;
    const { anchorEl } = this.state;

    return(
    <KMapConsumer>
    {({ userName }) => (
    <div>
      <AppBar color="primary" position="sticky" elevation={0} children={''}>
        <Toolbar>
          { this.props.isHome ?
          <Typography variant="h6" color="inherit" onClick={this.handleGoHome} style={{ cursor: 'pointer' }}>
            KMap
          </Typography>
          : null }
          <Grid container spacing={8} alignItems="center"> 
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <Typography className={classes.link} component="a" style={{ cursor: 'default' }}>
                {userName}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar}
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
                <Avatar>{userName.charAt(0)}</Avatar>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleAccount}><FormattedMessage id="account" /></MenuItem>
                <MenuItem onClick={this.handleLogout}><FormattedMessage id="logout" /></MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
    )}
    </KMapConsumer>
    )};
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);