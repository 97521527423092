import React, { Component, /* PureComponent */ } from 'react';
import XLSX from 'xlsx-js-style';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { PieChart, Pie, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip } from 'recharts';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import space from '../Space';
import human from '../resources/human/HumanCapital';
import profile from '../resources/profile/ProfileCapital';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    //tableLayout: 'fixed',
    //minWidth: 700,
  },
  tableLegend: {
    tableLayout: 'fixed',
    maxWidth: 110,
    marginLeft: 40
  },
  dialogStyle: {
    borderRadius: 0
  }
});

const COLORS = [];

/* class CustomizedAxisTick extends PureComponent {
  render() {
    const {
      x, y, payload,
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)">{payload.value}</text>
      </g>
    );
  }
} */

class Human extends Component {

  constructor (props) {
    super(props);
    this.state = {
      domains: [],
      profile_domains: [],
      profile_competence_levels: [],
      profile_humans_domains: [],
      profile_humans_competence_levels: [],
      profile_humans_domains_result: [],
      progress: false,
      progressProfile : false,
      XLSXprogress: false,
      humans: [],
      profiles: [],
      Dprofiles: [],
      currentProfile: [],
      profile_competences: [],
      openDialog: false,
      openProfileDialog: false,
      radarData: [],
      pieData: [],
      human_capitals: [],
      name: '',
      ic: '',
      profile_people: [],
      profile_index: 0
    }
    this.setUp = this.setUp.bind(this);
    this.ICcalc = this.ICcalc.bind(this);
    this.colorLevel = this.colorLevel.bind(this);
    this.domainsCount = this.domainsCount.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleProfileClickOpen = this.handleProfileClickOpen.bind(this);
    this.handleProfileClickClose = this.handleProfileClickClose.bind(this);
    this.handleExcel = this.handleExcel.bind(this);
    this.mounted = false;
  } 

  componentDidMount() {
    this.mounted = true;
    this.setState({ progress : true });
    this.setUp();
  }

  componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.spaceId !== prevProps.spaceId) {
      this.setState({ progress : true });
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  setUp() {
    space.getCompetence(this.props.spaceId).then((result) => {
      var domainResult = result.sort(function(a, b){
        var icA = this.ICcalc(a);
        var icB = this.ICcalc(b);
        return icB-icA
      }.bind(this));
      if(this.mounted) {
        this.setState({
          domains: domainResult,
          humans: domainResult[0].competence_levels,
        });
        this.setState({
          pieData : this.CreatePieData()
        });
      }
    });
    human.getSpaceList(this.props.spaceId).then((result) => {
      if(this.mounted) {
        this.setState(data => ({ human_capitals: result }))
      }
    });
    space.getProfileCompetence(this.props.spaceId).then((result) => {
      var domainResult = result.sort(function(a, b){
        var icA = this.ICcalc(a);
        var icB = this.ICcalc(b);
        return icB-icA
      }.bind(this));
      if(this.mounted) {
        this.setState({
          profile_domains: domainResult,
          profile_competence_levels: domainResult[0].profile_competence_levels,
          progress: false
        });
      }
    });    
    profile.getSpaceList(this.props.spaceId).then((result) => {
      if(this.mounted) {
        this.setState(data => ({ profiles: result }))
      }
    });

  }

  setUpLevels() {
    this.state.human_capitals.map((row, index) => {
      this.state.domains.map((row, index) => {
        return true
      })
      //console.log(row.human)
      return true
    })
    
  }
  
  handleClickOpen(name, ic, c, e, s, a, r) {
    this.setState({ openDialog: true, ic: ic, name: name, radarData: [
      { subject: 'Complejidad', A: c, B: c, fullMark: 5, },
      { subject: 'Escasez', A: e, B: e, fullMark: 5, },
      { subject: 'Singularidad', A: s, B: s, fullMark: 5, },
      { subject: 'Aplicabilidad', A: a, B: a, fullMark: 5, },
      { subject: 'Riesgo', A: r, B: r, fullMark: 5, },
    ] });
  }

  handleProfileClickOpen(id, name, index) {
    this.setState({ name: name, openProfileDialog: true, progressProfile : true });
    space.getProfileHumansCompetence(id).then((result) => {
      var domainResult = result;
      if(this.mounted) {
        this.setState({
          profile_humans_domains: domainResult,
          profile_humans_competence_levels: domainResult.levels,
          progressProfile : false,
        });
      }
    });
  }

  handleClickClose() {
    this.setState({ openDialog: false });
  }

  handleProfileClickClose() {
    this.setState({ openProfileDialog: false, name: '' });
  }

  CreatePieData() {
    var data = [];
    if (this.humansCount(0) > 0) {
      data.push({ name: 'No aplica', value: this.humansCount(0) })
      COLORS.push('#fff')
    }
    if (this.humansCount(1) > 0) {
      data.push({ name: 'En desarrollo', value: this.humansCount(1) })
      COLORS.push('#e57373')
    }
    if (this.humansCount(2) > 0) {
      data.push({ name: 'Conoce', value: this.humansCount(2) })
      COLORS.push('#ffb74d')
    }
    if (this.humansCount(3) > 0) {
      data.push({ name: 'Aplica', value: this.humansCount(3) })
      COLORS.push('#fff176')
    }
    if (this.humansCount(4) > 0) {
      data.push({ name: 'Desarrolla', value: this.humansCount(4) })
      COLORS.push('#81c784')
    }
    if (this.humansCount(5) > 0) {
      data.push({ name: 'Supervisa / Mejora', value: this.humansCount(5) })
      COLORS.push('#43a047')
    }        
    return data;
  }

  ICcalc(row) {

    var a = 0;
    var b = 0;
    var ic = 0;
    var total = 0;

    if (row.s1 === 4) {
      a = 0.05
    } 

    if (row.s3 === 4) {
      b = 0.05
    } 

    var cFilter = row.characterizations.filter(c => c.aspect.includes('C'))
    var cFilterSum = 0
    cFilter.forEach(el => {
      cFilterSum += el.value
    });

    var eFilter = row.characterizations.filter(e => e.aspect.includes('E'))
    var eFilterSum = 0
    eFilter.forEach(el => {
      eFilterSum += el.value
    });

    var sFilter = row.characterizations.filter(s => s.aspect.includes('S'))
    var sFilterSum = 0
    sFilter.forEach(el => {
      sFilterSum += el.value
    });

    var aFilter = row.characterizations.filter(a => a.aspect.includes('A'))
    var aFilterSum = 0
    aFilter.forEach(el => {
      aFilterSum += el.value
    });

    var rFilter = row.characterizations.filter(r => r.aspect.includes('R'))
    var rFilterSum = 0
    rFilter.forEach(el => {
      rFilterSum += el.value
    });

    var s1Filter = row.characterizations.filter(s1 => s1.aspect.includes('S1'))
    var s1FilterSum = 0
    s1Filter.forEach(el => {
      s1FilterSum += el.value
    });

    var s3Filter = row.characterizations.filter(s3 => s3.aspect.includes('S3'))
    var s3FilterSum = 0
    s3Filter.forEach(el => {
      s3FilterSum += el.value
    });
    
    total = ((Math.sqrt(Math.pow((cFilterSum / 3),2) + Math.pow((eFilterSum / 3),2) + Math.pow((sFilterSum / 3),2) + Math.pow((aFilterSum / 3),2) + Math.pow((rFilterSum / 3),2)) + (s1FilterSum * 0.125) + (s3FilterSum * 0.125)) + a + b).toFixed(1);

    if (total === '10.0' ) {
      ic = 10;
    } else {
      ic = total;
    }

    return ic;
  }

  CompetenceCalc(humans) {
    var c = 0;
    var nonas = 0;
    humans.map((row, index) => {
      if (row.value !== 0) {
        c += row.value;
        nonas++;
      }
      return true
    })
    return Math.round((c / nonas).toFixed(1));
  }

  ProfileCompetenceCalc(h) {
    var c = 0;
    var nonas = 0;
    h.sort(function(a, b){ 
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => {

      if (row.domain_level !== 0) {
        c += row.domain_level;
        nonas++;
      }
      return true
    })
    return Math.round((c / nonas).toFixed(1));
  }

  ProfileIndexCompetenceCalc(person) {
    var c = 0;
    var nonas = 0;
    person.levels.map((row, index) => {
      if (row.value !== 0) {
        c += row.value;
        nonas++;
      }
      return true
    })
    return Math.round((c / nonas).toFixed(1));
  }

  assignSimbol(number) {
    var numberStr = '';
    if (Math.sign(number) === 1) {
      numberStr = '+' + number;
    } 
    if (Math.sign(number) === -1) {
      numberStr = number;
    }
    if (Math.sign(number) === 0) {
      numberStr = number;
    }
    return numberStr;
  }

  simbolColor(number) {
    var numberColor = '#FFFFFF';
    if (Math.sign(number) === 1) {
      numberColor = '#a5d6a7';
    } 
    if (Math.sign(number) === -1) {
      numberColor = '#ef9a9a';
    }
    if (Math.sign(number) === 0) {
      numberColor = '#81d4fa';
    }
    return numberColor;
  }

  domainsCount(level) {
    var count = 0;
    var ic = 0;
    this.state.domains.map((row) => {
      ic = this.ICcalc(row);
      if (level === 1) {
        if ( ic >= 8.5 ) {
          count++;
        }
      }
      if (level === 2) {
        if (ic >= 6.5 && ic < 8.5) {
          count++;
        }
      }
      if (level === 3) {
        if (ic >= 4 && ic < 6.5) {
          count++;
        }
      }
      if (level === 4) {
        if (ic >= 0 && ic < 4) {
          count++;
        }
      }
      return true
    });
    return count;
  }

  humansCount(level) {
    var count = 0;
    var ic = 0;
    this.state.domains.map((row) => {
      ic = this.CompetenceCalc(row.competence_levels);
      if (ic === level) {
        count++;
      }
      return true
    });
    return count;
  }

  experienceCount() {
    var experience = [];
    var x1 = 0;
    var x2 = 0;
    var x3 = 0;
    var x4 = 0;
    this.state.human_capitals.map((row) => {
      if (row.experience === 1) {
        x1++;
      } else if (row.experience === 2) {
        ++x2;
      } else if (row.experience === 3) {
        ++x3;
      } else if (row.experience === 4) {
        ++x4;
      }
      return true
    });
    experience = [{ name: '< 2 años', x: x1 }, { name: '2 - 5 años', x: x2 }, { name: '5 - 10años', x: x3 }, { name: '> 10 años', x: x4 }];
    return experience;
  }

  ageCount() {
    var age = [];
    var x1 = 0;
    var x2 = 0;
    var x3 = 0;
    var x4 = 0;
    var x5 = 0;
    this.state.human_capitals.map((row) => {
      if (row.age === 1) {
        ++x1;
      } else if (row.age === 2) {
        ++x2;
      } else if (row.age === 3) {
        ++x3;
      } else if (row.age === 4) {
        ++x4;
      } else if (row.age === 5) {
        ++x5;  
      }
      return true
    });
    age = [{ name: '20 - 25', x: x1 }, { name: '25 - 30', x: x2 }, { name: '30 - 40', x: x3 }, { name: '40 - 50', x: x4 }, { name: '> 50', x: x5 }];
    return age;
  }

  rotationCount() {
    var rotation = [];
    var x1 = 0;
    var x2 = 0;
    var x3 = 0;
    var x4 = 0;
    this.state.human_capitals.map((row) => {
      if (row.rotation === 1) {
        ++x1;
      } else if (row.rotation === 2) {
        ++x2;
      } else if (row.rotation === 3) {
        ++x3;
      } else if (row.rotation === 4) {
        ++x4;
      }
      return true  
    });
    rotation = [{ name: 'Muy bajo', x: x1 }, { name: 'Bajo', x: x2 }, { name: 'Medio', x: x3 }, { name: 'Alto', x: x4 }];
    return rotation;
  }

  riskCount() {
    var risk = [];
    var x1 = 0;
    var x2 = 0;
    var x3 = 0;
    var x4 = 0;
    var x5 = 0;
    this.state.human_capitals.map((row) => {
      if (row.risk === 1) {
        ++x1;
      } else if (row.risk === 2) {
        ++x2;
      } else if (row.risk === 3) {
        ++x3;
      } else if (row.risk === 4) {
        ++x4;
      } else if (row.risk === 5) {
        ++x5;  
      }
      return true
    });
    risk = [{ name: 'A', x: x1 }, { name: 'B', x: x2 }, { name: 'C', x: x3 }, { name: 'D', x: x4 }, { name: 'E', x: x5 }];
    return risk;
  }

  colorLevel(ic) {
    if (ic >= 8.5) {
      return '#455a64';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#607d8b';
    } else if (ic >= 4 && ic < 6.5) {
      return '#90a4ae';
    } else if (ic >= 0 && ic < 4) {
      return '#cfd8dc';
    } else {
      return '#FFFFFF';
    }
  }

  colorLevelFont(ic) {
    if (ic >= 8.5) {
      return '#FFFFFF';
    } else if (ic >= 6.5 && ic < 8.5) {
      return '#FFFFFF';
    } else if (ic >= 4 && ic < 6.5) {
      return '#000000';
    } else if (ic >= 0 && ic < 4) {
      return '#000000';
    } else {
      return '#FFFFFF';
    }
  }

  colorHuman(v) {
    switch (v) {
      case 1:
        return '#e57373';
      case 2:
        return '#ffb74d';
      case 3:
        return '#fff176';
      case 4:
        return '#81c784';
      case 5:
        return '#43a047';
      case 0:
        return '#ffffff';                             
      default:
        return '#FFFFFF';
    }
  }

  tagValue(v) {
    switch (v) {
      case 1:
        return 'En desarrollo';
      case 2:
        return 'Conoce';
      case 3:
        return 'Aplica';
      case 4:
        return 'Desarrolla';
      case 5:
        return 'Supervisa / Mejora';
      case 0:
        return 'No aplica';
      default:
        return 'No aplica';
    }    
  }

  HumanCapitalPeopleExcel() {

    var xlsxJSON = [];

    xlsxJSON[3] = [
      { v: '' },
      { v: 'Capital Humano', t: "s", s: { font: { sz: "18", bold: true, color: { rgb: "557179" } } } }
    ];

    xlsxJSON[4] = [
      { v: '' },
      { v: 'Cobertura', t: "s", s: { font: { sz: "18", bold: true, color: { rgb: "557179" } } } }
    ];

    xlsxJSON[6] = [
      { v: '' },
      { v: 'Dominios de Conocimiento', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } },
      { v: 'IC', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } },
      { v: 'CA', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } }
    ];

    this.state.humans.sort(function(a, b){ 
      var nameA = a.human.toUpperCase();
      var nameB = b.human.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => ( 
      xlsxJSON[6].push(
        { 
          v: row.human,
          t: "s",
          s: { 
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            fill: { fgColor: { rgb: "557179" } },
            font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } }
          }
        }
      )
    ))

    this.state.domains.map((row, index) => {

      xlsxJSON[index + 7] = [{ v: "" }];

      xlsxJSON[index + 7].push(
        {
          v: row.name,
          t: "s",
          s: { 
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { horizontal: "left", wrapText: true, vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "557179" } }
          }
        });
      
      xlsxJSON[index + 7].push(
        {
          v: this.ICcalc(row),
          t: "s",
          s: {
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } },
            fill: { type: 'pattern', patternType: "solid", fgColor: { rgb: this.colorLevel(this.ICcalc(row)).slice(1).toString().toUpperCase() } },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "000000" } }
          }
        });

      xlsxJSON[index + 7].push({
        v: this.CompetenceCalc(row.competence_levels),
        t: "s",
        s: {
          border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } },
          alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
          fill: { type: 'pattern', patternType: "solid", fgColor: { rgb: this.colorHuman(this.CompetenceCalc(row.competence_levels)).slice(1).toString().toUpperCase() } },
          font: { sz: "14", bold: true, color: { rgb: '000000' } }
        }
      });

      row.competence_levels.sort(function(a, b){ 
        var nameA = a.human.toUpperCase();
        var nameB = b.human.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }).map((c, c_index) => ( xlsxJSON[index + 7].push({
        v: c.value === 0 ? 'N/A' : c.value,
        t: "s",
        s: {
          border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } },
          alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
          fill: { type: 'pattern', patternType: "solid", fgColor: { rgb: this.colorHuman(c.value).slice(1).toString().toUpperCase() } },
          font: { sz: "14", bold: true, color: { rgb: "000000" } }
        }
      })
      ))
      return null;
    })
    
    var sheet = XLSX.utils.aoa_to_sheet(xlsxJSON);

    sheet['!rows'] = [
      {'hpt' : 20},
      {'hpt' : 20},
      {'hpt' : 20},
      {'hpt' : 30},
      {'hpt' : 30},
      {'hpt' : 20},
      {'hpt' : 45},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60}
    ];

    sheet['!cols'] = [
      {'width' : 10},
      {'width' : 60},
      {'width' : 15},
      {'width' : 15}
    ];
    
    this.state.humans.sort(function(a, b){ 
      var nameA = a.human.toUpperCase();
      var nameB = b.human.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => ( 
      sheet['!cols'].push({'width' : 25})
    ))

    return sheet;
    

  }

  HumanCapitalProfilesExcel() {

    var xlsxJSON = [];

    xlsxJSON[3] = [
      { v: '' },
      { v: 'Capital Humano', t: "s", s: { font: { sz: "18", bold: true, color: { rgb: "557179" } } } }
    ];

    xlsxJSON[4] = [
      { v: '' },
      { v: 'Cobertura acumulada', t: "s", s: { font: { sz: "18", bold: true, color: { rgb: "557179" } } } }
    ];

    xlsxJSON[5] = [
      { v: '' },{ v: '' },{ v: '' },{ v: 'Perfiles', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } }
    ];
    
    xlsxJSON[6] = [
      { v: '' },{ v: '' },{ v: '' }
    ];

    xlsxJSON[7] = [
      { v: '' },
      { v: 'No. de personas necesarias con este perfil', t: "s", s: { alignment: { wrapText: true, horizontal: 'right', vertical: 'center' }, font: { sz: "14", bold: true, color: { rgb: "557179" } } } },
      { v: '' }
    ];

    this.state.profile_competence_levels.sort(function(a, b){ 
      var nameA = a.profile.toUpperCase();
      var nameB = b.profile.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => (
      xlsxJSON[7].push(
        { 
          v: row.people,
          t: "s",
          s: { 
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "000000" } }
          }
        }
      )
    ))

    xlsxJSON[8] = [
      { v: '' },
      { v: 'No. de personas en el espacio', t: "s", s: { alignment: { wrapText: true, horizontal: 'right', vertical: 'center' }, font: { sz: "14", bold: true, color: { rgb: "557179" } } } },
      { v: '' }
    ];

    this.state.profile_competence_levels.sort(function(a, b){ 
      var nameA = a.profile.toUpperCase();
      var nameB = b.profile.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => (
      xlsxJSON[8].push(
        { 
          v: row.profile_people.length,
          t: "s",
          s: { 
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "000000" } }
          }
        }
      )
    ))

    xlsxJSON[9] = [
      { v: '' },
      { v: 'Diferencia', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'right', vertical: 'center' }, font: { sz: "14", bold: true, color: { rgb: "557179" } } } },
      { v: '' }
    ];

    this.state.profile_competence_levels.sort(function(a, b){ 
      var nameA = a.profile.toUpperCase();
      var nameB = b.profile.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => (
      xlsxJSON[9].push(
        { 
          v: row.profile_people.length - row.people,
          t: "s",
          s: {
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "000000" } }
          }
        }
      )
    ))


    xlsxJSON[10] = [
      { v: '' },
      { v: 'Dominios de Conocimiento', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } },
      { v: 'IC', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } },
      { v: 'Cobertura Acumulada por perfil', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } }
    ];

    this.state.profile_competence_levels.sort(function(a, b){ 
      var nameA = a.profile.toUpperCase();
      var nameB = b.profile.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => (
      xlsxJSON[6].push(
        { 
          v: row.profile,
          t: "s",
          s: {
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            fill: { fgColor: { rgb: "557179" } },
            font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } }
          }
        }
      )
    ))

    this.state.profile_domains.map((row, index) => {

      xlsxJSON[index + 11] = [{ v: "" }];

      xlsxJSON[index + 11].push(
        {
          v: row.name,
          t: "s",
          s: { 
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { horizontal: "left", wrapText: true, vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "557179" } }
          }
        });
      
      xlsxJSON[index + 11].push(
        {
          v: this.ICcalc(row),
          t: "s",
          s: {
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } },
            fill: { type: 'pattern', patternType: "solid", fgColor: { rgb: this.colorLevel(this.ICcalc(row)).slice(1).toString().toUpperCase() } },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "000000" } }
          }
        });

      row.profile_competence_levels.sort(function(a, b){ 
        var nameA = a.profile.toUpperCase();
        var nameB = b.profile.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }).map((c, c_index) => ( xlsxJSON[index + 11].push({
        v: this.assignSimbol(this.ProfileCompetenceCalc(c.profile_people) - c.value) === '' ? 'N/A' : this.assignSimbol(this.ProfileCompetenceCalc(c.profile_people) - c.value),
        t: "s",
        s: {
          border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } },
          alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
          fill: { type: 'pattern', patternType: "solid", fgColor: { rgb: this.simbolColor(this.ProfileCompetenceCalc(c.profile_people) - c.value).slice(1).toString().toUpperCase() } },
          font: { sz: "14", bold: true, color: { rgb: "000000" } }
        }
      })
      ))
      return null;
    })
    
    var sheet = XLSX.utils.aoa_to_sheet(xlsxJSON);
    sheet['!cols'] = [
      {'width' : 10},
      {'width' : 50},
      {'width' : 15},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 25}
    ];

    sheet['!rows'] = [
      {'hpt' : 20},
      {'hpt' : 20},
      {'hpt' : 20},
      {'hpt' : 30},
      {'hpt' : 30},
      {'hpt' : 45},
      {'hpt' : 45},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60}
    ];
    
    sheet["!merges"] = [
      { s: { r: 7, c: 1 }, e: { r: 7, c: 2 } },{ s: { r: 8, c: 1 }, e: { r: 8, c: 2 } },{ s: { r: 9, c: 1 }, e: { r: 9, c: 2 } }, { s: { r: 5, c: 3 }, e: { r: 5, c: 2+this.state.profile_competence_levels.length } }, { s: { r: 10, c: 3 }, e: { r: 10, c: 2+this.state.profile_competence_levels.length } }
    ];
    return sheet;
    

  }

  HumanCapitalProfilesDetailExcel(id, name) {

    var xlsxJSON = [];

    xlsxJSON[3] = [
      { v: '' },
      { v: name, t: "s", s: { font: { sz: "18", bold: true, color: { rgb: "557179" } } } }
    ];

    xlsxJSON[4] = [
      { v: '' },
      { v: 'Diferencia de nivel de competencia esperado vs niveles de competencia de cada persona con este perfil por dominio de conocimiento', t: "s", s: { font: { sz: "18", bold: true, color: { rgb: "557179" } } } }
    ];

    xlsxJSON[6] = [
      { v: '' },
      { v: '' },
      { v: '' },
      { v: '' },
      { v: 'Personas', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } }
    ];

    xlsxJSON[7] = [
      { v: '' },
      { v: '' },
      { v: '' },
      { v: '' }
    ];

    xlsxJSON[8] = [
      { v: '' },
      { v: 'Dominios de Conocimiento', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } },
      { v: 'IC', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } },
      { v: 'Nivel Esperado por Perfil', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } },
      { v: 'Diferencia Nivel Esperado para el Perfil - Nivel Actual de la Persona', t: "s", s: { border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "557179" } }, font: { sz: "14", bold: true, color: { rgb: "FFFFFF" } } } }
    ];

    this.state.profile_humans_domains.humans.sort(function(a, b){ 
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => ( 
      xlsxJSON[7].push(
        { 
          v: row.name,
          t: "s",
          s: { 
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            fill: { fgColor: { rgb: "FFFFFF" } },
            font: { sz: "14", bold: true, color: { rgb: "000000" } }
          }
        }
      )
    ))

    this.state.profile_humans_competence_levels.sort(function(a, b){
      var icA = this.ICcalc(a);
      var icB = this.ICcalc(b);
      return icB-icA
    }.bind(this)).map((row, index) => {
      xlsxJSON[index + 9] = [{ v: "" }];

      xlsxJSON[index + 9].push(
        {
          v: row.name,
          t: "s",
          s: { 
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { horizontal: "left", wrapText: true, vertical: 'center' },
            font: { sz: "14", bold: true, color: { rgb: "557179" } }
          }
        });

    xlsxJSON[index + 9].push(
      {
        v: this.ICcalc(row),
        t: "s",
        s: { 
          border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
          alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
          fill: { type: 'pattern', patternType: "solid", fgColor: { rgb: this.colorLevel(this.ICcalc(row)).slice(1).toString().toUpperCase() } },
          font: { sz: "14", bold: true, color: { rgb: "000000" } }
        }
      });

    xlsxJSON[index + 9].push(
      {
        v: row.value + ': ' + this.tagValue(row.value),
        t: "s",
        s: { 
          border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
          alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
          font: { sz: "14", bold: true, color: { rgb: "000000" } }
        }
      });

        

        row.profile_people.sort(function(a, b){ 
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }).map((c, i) => ( xlsxJSON[index + 9].push({
          v: (c.levels.length === 0 || c.levels.length < this.state.profile_humans_competence_levels.length) ? '' : (c.levels.sort(function(a, b){
            var icA = this.ICcalc(a);
            var icB = this.ICcalc(b);
            return icB-icA
          }.bind(this))[index].value) === 0 ?
          'N/A'
          :
          this.assignSimbol(c.levels.sort(function(a, b){
            var icA = this.ICcalc(a);
            var icB = this.ICcalc(b);
            return icB-icA
          }.bind(this))[index].value - row.value),
          t: "s",
          s: { 
            border: { top: { style: 'thin', color: { rgb: 'CACACA' } }, right: { style: 'thin', color: { rgb: 'CACACA' } }, bottom: { style: 'thin', color: { rgb: 'CACACA' } }, left: { style: 'thin', color: { rgb: 'CACACA' } } }, 
            alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
            fill: { type: 'pattern', patternType: "solid", fgColor: { rgb: (c.levels.length === 0 || c.levels.length < this.state.profile_humans_competence_levels.length) ? '' : (c.levels.sort(function(a, b){
              var icA = this.ICcalc(a);
              var icB = this.ICcalc(b);
              return icB-icA
            }.bind(this))[index].value) === 0 ?
            'FFFFFF'
            :
            this.simbolColor(c.levels.sort(function(a, b){
            var icA = this.ICcalc(a);
            var icB = this.ICcalc(b);
            return icB-icA
          }.bind(this))[index].value - row.value).slice(1).toString().toUpperCase() } },
            font: { sz: "14", bold: true, color: { rgb: "000000" } }
          }
        })
        ))
      return null;
    })

    var sheet = XLSX.utils.aoa_to_sheet(xlsxJSON);
    sheet['!cols'] = [
      {'width' : 10},
      {'width' : 50},
      {'width' : 15},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 30},
      {'width' : 25}
    ];

    sheet['!rows'] = [
      {'hpt' : 20},
      {'hpt' : 20},
      {'hpt' : 20},
      {'hpt' : 30},
      {'hpt' : 30},
      {'hpt' : 45},
      {'hpt' : 45},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60},
      {'hpt' : 60}
    ];

    sheet["!merges"] = [
      { s: { r: 6, c: 4 }, e: { r: 6, c: 3+this.state.profile_humans_domains.humans.length } }, { s: { r: 8, c: 4 }, e: { r: 8, c: 3+this.state.profile_humans_domains.humans.length } }
    ];

    return sheet;

  }

  handleExcel() {

    this.setState({ XLSXprogress : true });
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, this.HumanCapitalPeopleExcel(), "Cobertura - Personas");
    XLSX.utils.book_append_sheet(wb, this.HumanCapitalProfilesExcel(), "Cobertura - Perfiles");
    let count = 1;
    this.state.profile_competence_levels.sort(function(a, b){ 
      var nameA = a.profile.toUpperCase();
      var nameB = b.profile.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }).map((row, index) => {
      this.setState({ name: row.profile, openProfileDialog: false, progressProfile : false });
      space.getProfileHumansCompetence(row.profile_id).then((result) => {
        var domainResult = result;
        if(this.mounted) {
          this.setState({
            profile_humans_domains: domainResult,
            profile_humans_competence_levels: domainResult.levels,
            progressProfile : false,
          });
          //XLSX.utils.book_append_sheet(wb, this.HumanCapitalProfilesDetailExcel(row.profile_id, row.profile), index.toString() + ".- " + row.profile.substring(0, 24).replace("/", "-") + '...' );
          XLSX.utils.book_append_sheet(wb, this.HumanCapitalProfilesDetailExcel(row.profile_id, row.profile), 'Perfil ' + (index + 1).toString() );
          if (this.state.profile_competence_levels.length === count) {
            XLSX.writeFile(wb, "KMAPCapitalHumano.xlsx", { cellStyles: true });
            this.setState({ XLSXprogress : false });
          }
          count++;
        }
      });
      return null;
    })
    

  }

  render() {

    const { classes } = this.props;

    return (
      <div>
      { this.state.progress ?
        <div>
          <br />
          <LinearProgress />
        </div>
      :
      <div>
        <Grid container spacing={24}>
          <Grid item xs={24} sm={12}>
            <Button onClick={this.handleExcel} color="primary" style={{ margin: '20px' }} disabled={this.state.XLSXprogress}>
              <FormattedMessage id="export" />
              {  this.state.XLSXprogress ? <CircularProgress size={18} style={{ marginLeft: '10px' }} /> : null }
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PieChart width={500} height={200} onMouseEnter={this.onPieEnter}>
              <Pie
                data={this.state.pieData}
                cx={260}
                cy={180}
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                startAngle={180}
                endAngle={0}
                label
              >
                {
                  this.state.pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
            <Typography style={{ textAlign: 'center' }}>Distribución de cobertura acumulada</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.root} square={true} elevation={0}>
              <Table className={classes.tableLegend}>
                <colgroup>
                  <col width="10px" />
                  <col width="400px" />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="skill.one" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#e57373', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="skill.two" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#ffb74d', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="skill.three" /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#fff176', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="skill.four" /></TableCell>
                  </TableRow> 
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#81c784', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="skill.five" /></TableCell>
                  </TableRow> 
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #fff', color: '#263238' }}></TableCell>
                    <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}><FormattedMessage id="skill.six" /></TableCell>
                  </TableRow>         
                  </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table} id='people_table'>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'} width={200}><FormattedMessage id="spaces.domains" /></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>IC</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>CA</TableCell>
                {this.state.humans.sort(function(a, b){ 
                        var nameA = a.human.toUpperCase();
                        var nameB = b.human.toUpperCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      }).map((row, index) => (
                  <TableCell key={index} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 5 }} align={'center'} width={70}>{row.human}</TableCell>
                ))}
              </TableRow>
              {this.state.domains.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell style={{ border: '1px solid #cfd8dc' }} width={200}>{row.name}</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), color: this.colorLevelFont(this.ICcalc(row)), padding: 0, border: '1px solid #cfd8dc' + this.colorLevel(this.ICcalc(row)) }} width={70}>{this.ICcalc(row)}</TableCell>
                <TableCell style={{ backgroundColor: this.colorHuman(this.CompetenceCalc(row.competence_levels)), border: '1px solid #cfd8dc', color: '#263238', padding: 0 }} align={'center'} width={70}>{this.CompetenceCalc(row.competence_levels)}</TableCell>
                {row.competence_levels.sort(function(a, b){ 
                        var nameA = a.human.toUpperCase();
                        var nameB = b.human.toUpperCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      }).map((c, index) => (
                  <React.Fragment key={index + '_lolfrag'}>
                  {this.state.humans[index].human !== c.human ? <TableCell key={index + '_lol'} style={{ backgroundColor: '#FFF', color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'}>N/A</TableCell> : null}
                  <TableCell key={index} style={{ backgroundColor: this.colorHuman(c.value), color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'} width={70}>{c.value === 0 ? 'N/A' : c.value}</TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
              )) }
              </TableBody>
          </Table>
        </Paper>

        <Grid container spacing={24} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6}>
            <Typography style={{ textAlign: 'center', marginBottom: 10 }}>Curva de distribución por antigüedad</Typography>
            <AreaChart
              width={500}
              height={400}
              data={this.experienceCount()}
              margin={{
                top: 10, right: 30, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="x" stroke="#03a9f4" fill="#4fc3f7" />
            </AreaChart>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography style={{ textAlign: 'center', marginBottom: 10 }}>Curva de distribución por edad</Typography>
            <AreaChart
              width={500}
              height={400}
              data={this.ageCount()}
              margin={{
                top: 10, right: 30, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="x" stroke="#03a9f4" fill="#4fc3f7" />
            </AreaChart>
          </Grid>
        </Grid>

        <Grid container spacing={24} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={6}>
            <Typography style={{ textAlign: 'center' }}>Nivel de Rotación</Typography>
            <BarChart
              width={500}
              height={300}
              data={this.rotationCount()}
              margin={{
                top: 20, right: 30, left: 20, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="x" stackId="a" fill="#81c784" />
            </BarChart>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography style={{ textAlign: 'center' }}> Riesgo de Desvinculación</Typography>
            <BarChart
              width={500}
              height={300}
              data={this.riskCount()}
              margin={{
                top: 20, right: 30, left: 20, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="x" stackId="a" fill="#81c784" />
            </BarChart>
            <Paper className={classes.root} square={true} elevation={0}>
                <Table className={classes.tableLegend}>
                  <colgroup>
                    <col width="40px" height="40px" />
                    <col width="400px" />
                  </colgroup>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>A.- </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>Sin Riesgo</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>B.- </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>Posible promoción / rotación</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>C.- </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>Posible Baja / reducción jornada</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>D.- </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>Desincorporación por Jubilación</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>E.- </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', border: '1px solid #fff', color: '#263238' }}>Desincorporación por por otras causas</TableCell>
                    </TableRow>                                                 
                    </TableBody>
                </Table>
              </Paper>
          </Grid>
        </Grid>
        
        <Paper className={classes.root} square={true} elevation={0}>
          <Typography variant="h6" gutterBottom>Cobertura acumulada</Typography>
          <Typography variant="body2" style={{ paddingBottom: 30 }}>Diferencia entre los niveles de competencia esperados para cada dominio de conocimiento por perfil y la competencia acumulada del capital humano del espacio</Typography>
          <Table className={classes.table} id='ca_table'>
            <TableBody>
            <TableRow>
                <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF' }} align={'left'} width={200}></TableCell>
                <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF', padding: 0 }} align={'center'} width={70}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70} colSpan={this.state.profiles.length}>Perfiles</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF' }} align={'left'} width={200}></TableCell>
                <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF', padding: 0 }} align={'center'} width={70}></TableCell>
                {this.state.profile_competence_levels.sort(function(a, b){ 
                    var nameA = a.profile.toUpperCase();
                    var nameB = b.profile.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map((row, index) => (
                  <TableCell key={index} onClick={ event => {this.handleProfileClickOpen(row.profile_id, row.profile, index)} } style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 5, cursor: 'pointer' }} align={'center'} width={70}>{row.profile}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#37474f', padding: '0px 20px 0px 0px' }} align={'right'} width={70}>No. de personas necesarias con este perfil</TableCell>
                {this.state.profile_competence_levels.sort(function(a, b){ 
                    var nameA = a.profile.toUpperCase();
                    var nameB = b.profile.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map((row, index) => (
                  <TableCell key={index} style={{ backgroundColor: '#FFF', border: '1px solid #cfd8dc', color: '#37474f', padding: 0 }} align={'center'} width={70}>{row.people}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#37474f', padding: '0px 20px 0px 0px' }} align={'right'} width={70}>No. de personas en el espacio</TableCell>
                {this.state.profile_competence_levels.sort(function(a, b){ 
                    var nameA = a.profile.toUpperCase();
                    var nameB = b.profile.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map((row, index) => (
                  <TableCell key={index} style={{ backgroundColor: '#FFF', border: '1px solid #cfd8dc', color: '#37474f', padding: 0 }} align={'center'} width={70}>{row.profile_people.length}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#37474f', padding: '0px 20px 0px 0px' }} align={'right'} width={70}>Diferencia</TableCell>
                {this.state.profile_competence_levels.sort(function(a, b){ 
                    var nameA = a.profile.toUpperCase();
                    var nameB = b.profile.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map((row, index) => (
                  <TableCell key={index} style={{ backgroundColor: this.simbolColor(row.profile_people.length - row.people), border: '1px solid #cfd8dc', color: '#37474f', padding: 0 }} align={'center'} width={70}>{this.assignSimbol(row.profile_people.length - row.people)}</TableCell>
                ))}
              </TableRow>                                         
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'} width={200}><FormattedMessage id="spaces.domains" /></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>IC</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70} colSpan={this.state.profiles.length}>Cobertura Acumulada por perfil</TableCell>
              </TableRow>
              {this.state.profile_domains.sort(function(a, b){
                    var icA = this.ICcalc(a);
                    var icB = this.ICcalc(b);
                    return icB-icA
                  }.bind(this)).map((row, index) => (
              <TableRow hover key={index}>
                <TableCell style={{ border: '1px solid #cfd8dc' }} width={200}>{row.name}</TableCell>
                <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), color: this.colorLevelFont(this.ICcalc(row)), padding: 0, border: '1px solid #cfd8dc' + this.colorLevel(this.ICcalc(row)) }} width={70}>{this.ICcalc(row)}</TableCell>
                {row.profile_competence_levels.sort(function(a, b){ 
                    var nameA = a.profile.toUpperCase();
                    var nameB = b.profile.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map((c, i) => (
                  <TableCell key={i} style={{ backgroundColor: this.simbolColor(this.ProfileCompetenceCalc(c.profile_people) - c.value), color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'} width={70}>{this.assignSimbol(this.ProfileCompetenceCalc(c.profile_people) - c.value) === '' ? 'N/A' : this.assignSimbol(this.ProfileCompetenceCalc(c.profile_people) - c.value) }</TableCell>
                ))}
              </TableRow>
              )) }
              </TableBody>
          </Table>
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={this.state.openProfileDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">{ this.state.name }</DialogTitle>
          <DialogContent>

            <Typography variant="body2" style={{ paddingBottom: 30 }}>Diferencia de nivel de competencia esperado vs niveles de competencia de cada persona con este perfil por dominio de conocimiento</Typography>
    
            { this.state.openProfileDialog ? 
            <Paper className={classes.root} square={true} elevation={0}>
              { this.state.progressProfile ?
                <div>
                  <br />
                  <LinearProgress />
                </div>
              :
              <Table className={classes.table}>
                <TableBody>
                <TableRow>
                    <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF' }} align={'left'} width={200}></TableCell>
                    <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF', padding: 0 }} align={'center'} width={70}></TableCell>
                    <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF', padding: 0 }} align={'center'} width={70}></TableCell>
                    <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70} colSpan={this.state.profile_humans_domains.humans.length}>Personas</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF' }} align={'left'} width={200}></TableCell>
                    <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF', padding: 0 }} align={'center'} width={70}></TableCell>
                    <TableCell style={{ backgroundColor: '#FFF', border: '1px solid #FFF', color: '#FFF', padding: 0 }} align={'center'} width={70}></TableCell>
                    {this.state.profile_humans_domains.humans.sort(function(a, b){ 
                        var nameA = a.name.toUpperCase();
                        var nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      }).map((row, index) => (
                      <TableCell key={index} style={{ backgroundColor: '#FFF', border: '1px solid #cfd8dc', color: '#37474f', padding: 5, cursor: 'pointer' }} align={'center'} width={70}>{row.name}</TableCell>
                    ))}
                  </TableRow>                                        
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'} width={200}><FormattedMessage id="spaces.domains" /></TableCell>
                    <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>IC</TableCell>
                    <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 0 }} align={'center'} width={70}>Nivel Esperado por Perfil</TableCell>
                    <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', padding: 5 }} align={'center'} width={70} colSpan={this.state.profile_humans_domains.humans.length}>Diferencia Nivel Esperado para el Perfil - Nivel Actual de la Persona</TableCell>
                  </TableRow>
                  {this.state.profile_humans_competence_levels.sort(function(a, b){
                    var icA = this.ICcalc(a);
                    var icB = this.ICcalc(b);
                    return icB-icA
                  }.bind(this)).map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell style={{ border: '1px solid #cfd8dc' }} width={200}>{row.name}</TableCell>
                    <TableCell align={'center'} style={{ backgroundColor: this.colorLevel(this.ICcalc(row)), color: this.colorLevelFont(this.ICcalc(row)), padding: 0, border: '1px solid #cfd8dc' + this.colorLevel(this.ICcalc(row)) }} width={70}>{this.ICcalc(row)}</TableCell>
                    <TableCell align={'center'} style={{ backgroundColor: '#fff', color: '#37474f', padding: 0, border: '1px solid #cfd8dc' }} width={70}>{ row.value + ': ' + this.tagValue(row.value) }</TableCell>
                    {row.profile_people.sort(function(a, b){ 
                        var nameA = a.name.toUpperCase();
                        var nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      }).map((c, i) => (
                      (c.levels.length === 0 || c.levels.length < this.state.profile_humans_competence_levels.length) ? null :
                      <TableCell key={i} style={{ backgroundColor: 
                        (c.levels.sort(function(a, b){
                          var icA = this.ICcalc(a);
                          var icB = this.ICcalc(b);
                          return icB-icA
                        }.bind(this))[index].value) === 0 ?
                        '#fff'
                        :
                        this.simbolColor(c.levels.sort(function(a, b){
                        var icA = this.ICcalc(a);
                        var icB = this.ICcalc(b);
                        return icB-icA
                      }.bind(this))[index].value - row.value), color: '#263238', border: '1px solid #cfd8dc', padding: 0 }} align={'center'} width={70}>
                      {
                        (c.levels.sort(function(a, b){
                          var icA = this.ICcalc(a);
                          var icB = this.ICcalc(b);
                          return icB-icA
                        }.bind(this))[index].value) === 0 ?
                        'N/A'
                        :
                        this.assignSimbol(c.levels.sort(function(a, b){
                          var icA = this.ICcalc(a);
                          var icB = this.ICcalc(b);
                          return icB-icA
                        }.bind(this))[index].value - row.value)
                        
                    }</TableCell>
                    ))}
                  </TableRow>
                  )) }
                  </TableBody>
              </Table>
              }
            </Paper> : null }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleProfileClickClose} color="primary">
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>

        </div>
        }
      </div>
    );
  }
}

Human.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Human);