import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableFooter from '@material-ui/core/TableFooter';
//import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Switch from '@material-ui/core/Switch';
import { FormattedMessage } from 'react-intl';
import space from '../spaces/Space';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { classes } = props;

  return (
    <Toolbar className={classNames(classes.root)}>
      <div className={classes.title}>
        <Typography variant="subtitle1" id="tableTitle">
          <FormattedMessage id="spaces.list_title" />
        </Typography>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

//const actionsStyles = theme => ({
//  root: {
//    flexShrink: 0,
//    color: theme.palette.text.secondary,
//    marginLeft: theme.spacing.unit * 2.5,
//  },
//});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

//const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
//  TablePaginationActions,
//);

let counter = 0;
function createData(oid, name, description) {
  counter += 1;
  return { id: counter, oid, name, description };
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class UserPermissionsTable extends React.Component {
  state = {
    rows: [],
    page: 0,
    rowsPerPage: 5,
    spacePermission: [],
  };

  componentDidMount() {
    if (this.props.organizationId) {
      space.getOrganizationList(this.props.organizationId).then((result) =>
        this.setState(data => ({ rows: result.map(n => createData(n._id.$oid, n.name, n.description)) }))
      );
    }
  } 

  componentDidUpdate(prevProps, prevState) {

      if ((prevProps.organizationId !== this.props.organizationId) && this.props.organizationId) {
      
        space.getOrganizationList(this.props.organizationId).then((result) =>
          this.setState(data => ({ rows: result.map(n => createData(n._id.$oid, n.name, n.description)) }))
        );
      }

  }
  
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rows } = this.state;
    //const { rows, rowsPerPage, page } = this.state;
    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    
    return (
      <React.Fragment>
      { (rows.length && this.props.roleType === '1') > 0 ?
      <Paper className={classes.root} elevation={0} square={true}>
        <FormLabel component="legend">Space Permissions</FormLabel>
        {/* <EnhancedTableToolbar /> */}
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <FormGroup row>
                        <FormControlLabel control={<Switch checked={this.props.spacePermission.includes(row.oid + '_viewer') ? true : false} onChange={this.props.action} value={row.oid + '_viewer'} color="primary" />} label={<FormattedMessage id="permissions.view" />} />
                        <FormControlLabel control={<Switch checked={this.props.spacePermission.includes(row.oid + '_editor') ? true : false} onChange={this.props.action} value={row.oid + '_editor'} color="primary" />} label={<FormattedMessage id="permissions.edit" />} />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
      : null }
      </React.Fragment>
    );
  }
}

UserPermissionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserPermissionsTable);