export default {
  'en-US': {
    'admin.title': 'Manage',
    'dashboard.title': 'Dashboard',
    'spaces.title': 'Spaces',
    'spaces.list_title': 'Listing spaces',
    'spaces.new': 'New space',
    'spaces.name': 'Name',
    'spaces.description': 'Description',
    'spaces.type': 'Type',
    'spaces.purpose': 'Purpose / Function',
    'spaces.products': 'Products',    
    'spaces.edit': 'Edit space',
    'spaces.info': 'Information',
    'spaces.domains': 'Knowledge Domains',
    'spaces.activities': 'Activities & Challenges',
    'spaces.permissions': 'Users & Permissions',
    'spaces.resources': 'Knowledge Resources',
    'spaces.analysis': 'Analysis',
    'spaces.snapshots': 'Snapshots',
    'spaces.saved': 'Space has been created!',
    'spaces.updated': 'Space has been updated!',
    'types.direction': 'Direction',
    'types.area': 'Area/Department',
    'types.process': 'Process',
    'domains.new': 'New domain',
    'domains.add': 'Add a knowledge domain',
    'domains.editing': 'Edit knowledge domain',
    'domains.head_title': 'Knowledge Domain',
    'domains.title': 'Knowledge Domains',
    'domains.list_title': 'Listing domains',
    'domains.saved': 'Knowledge domain has been created!',
    'domains.updated': 'Knowledge domain has been updated!',
    'domains.deleted': 'Knowledge domain has been deleted!',
    'interference.title': 'Interference',
    'interference.subtitle': 'Relation of the Knowledge Domains to the Activities and Challenges declared in the space.',
    'interference.asign': 'Add interference',
    'interference.updated': 'Interference has been updated!',


    'characterization.title': 'Characterization',
    'characterization.subtitle': 'Each Knowledge Domain will be characterized by responding to the following variables.',
    'characterization.asign': 'Add Characterization',
    'characterization.updated': 'Characterization updated!',

    'characterization.complexity': 'Complexity',
    'characterization.shortage': 'Shortage',
    'characterization.singularity': 'Singularity',
    'characterization.applicability': 'Applicability',
    'characterization.risk': 'Risk',

    'characterization.complexity_one': 'It takes a lot of training to develop this knowledge.',
    'characterization.complexity_two': 'It requires a lot of practical experience to be able to have a level of mastery and adequate application of this knowledge.',
    'characterization.complexity_three': 'The assimilation and application of this knowledge requires supervision or support from people who know the particular context of the organization.',

    'characterization.shortage_one': 'This knowledge is dominated by few people in the organization.',
    'characterization.shortage_two': 'There are few professionals in the market with an adequate level of specialization.',
    'characterization.shortage_three': 'Outsourcing this knowledge is complex, because there are few suppliers, or those that are out of reach, or because we need to have this know-how internally.',

    'characterization.singularity_one': 'This domain of knowledge is closely linked to the know-how and experience itself (it is very tacit or difficult to document).',
    'characterization.singularity_two': 'Although this knowledge can be documented, we do not have any trace of it, which hinders its disclosure.',
    'characterization.singularity_three': 'This knowledge is not widespread in the industry, either because we are pioneers in its development, or because it is closely linked to particular conditions of our organization.',

    'characterization.applicability_one': 'This knowledge is relevant to the current development of the organization.',
    'characterization.applicability_two': 'Without this knowledge an important stop is generated in key activities or processes.',
    'characterization.applicability_three': "This knowledge will continue to be relevant for the development of future challenges foreseen in the organization's strategy.",

    'characterization.risk_one': 'The industry has a high demand for this knowledge (the labor market and the contracting of services is booming).',
    'characterization.risk_two': 'There are provisions or conditions that can accelerate the disengagement of people with experience in this knowledge (promotions, retirements, reconfiguration of the organization).',
    'characterization.risk_three': 'There are new trends (technological, market) that accelerate the obsolescence of this knowledge.',

    'activities.title': 'Activities',
    'activities.add': 'Add an activity',
    'activities.editing': 'Edit activity',
    'activities.head_title': 'Activity',
    'activities.saved': 'Activity has been created!',
    'activities.updated': 'Activity has been updated!',
    'activities.deleted': 'Activity has been deleted!',
    'challenges.saved': 'Challenge has been created!',
    'challenges.updated': 'Challenge has been updated!',
    'challenges.deleted': 'Challenge has been deleted!',
    'challenges.title': 'Challenges',
    'challenges.add': 'Add a challenge',
    'challenges.editing': 'Edit challenge',
    'challenges.head_title': 'Challenge',
    'challenges.impact': 'Impact level on value offer',
    'challenges.complexity': 'Complexity level',
    'challenges.need': 'Need for attention / improvement',

    'snapshots.title': 'Snapshots',
    'snapshots.add': 'Add an Snapshot',
    'snapshots.editing': 'Edit Snapshot',
    'snapshots.head_title': 'Snapshot',
    'snapshots.saved': 'Snapshot has been created!',
    'snapshots.updated': 'Snapshot has been updated!',
    'snapshots.deleted': 'Snapshot has been deleted!',

    'resources.human': 'Human Capital',
    'resources.structural': 'Structural Capital',
    'resources.relational': 'Relational Capital',
    'human.name': 'Name',
    'human.charge': 'Profile / Charge',
    'human.age': 'Age range',
    'human.senority': 'Seniority in the Organization',
    'human.experience': 'Acumulated experience',
    'human.risk': 'De-linking risk',
    'human.rotation': 'Rotation level',
    'human.skill': 'Competence level',
    'human.evaluation': 'Evaluation',
    'human.add': 'Add Human Capital',
    'human.editing': 'Edit Human Capital',
    'human.saved': 'Human Capital has been created!',
    'human.updated': 'Human Capital has been updated!',
    'human.deleted': 'Human Capital has been deleted!',
    'age.one': '20 - 25',
    'age.two': '25 - 30',
    'age.three': '30 - 40',
    'age.four': '40 - 50',
    'age.five': '> 50',
    'experience.one': '< 2 years',
    'experience.two': '2 - 5 years',
    'experience.three': '5 - 10 years',
    'experience.four': '> 10 years',
    'risk.one': 'No risk',
    'risk.two': 'Possible promotion / rotation',
    'risk.three': 'Possible down / day reduction',
    'risk.four': 'Retirement Disincorporation',
    'risk.five': 'Disincorporation due to other causes',
    'rotation.one': 'Very low',
    'rotation.two': 'Low',
    'rotation.three': 'Medium',
    'rotation.four': 'High',
    'skill.one': 'Does not apply',
    'skill.two': 'In develpment',
    'skill.three': 'Knows',
    'skill.four': 'Apply',
    'skill.five': 'Develop',
    'skill.six': 'Supervise / Improvement',
    'competence.one': 'Existing documents provide little information',
    'competence.two': 'Most information needs are covered with existing documents',
    'competence.three': 'The domain of knowledge is fully covered with this type of content',
    'competence.updated': 'Competence has been updated!',
    'agent.one': 'Cliente',
    'agent.two': 'Colaborador (socio tecnológico / centro de investigación / partner comercial)',
    'agent.three': 'Proveedor (de servicios o insumos)',
    'agent.four': 'Red / Foro / Asociación',
    'structural.name': 'Name',
    'structural.type': 'Resource Type',
    'structural.source': 'Source',
    'structural.access': 'Easy Access',
    'structural.reliability': 'Reliability',
    'structural.evaluation': 'Evaluation',
    'structural.coverage': 'Coverage Level',
    'structural.add': 'Add Structural Capital',
    'structural.editing': 'Edit Structural Capital',
    'structural.saved': 'Structural Capital has been created!',
    'structural.updated': 'Structural Capital has been updated!',
    'structural.deleted': 'Structural Capital has been deleted!',
    'type.one': 'Manual / Procedimiento',
    'type.two': 'Normativa / Leyes',
    'type.three': 'Especificaciones',
    'type.four': 'Modelos / Formulas',
    'type.five': 'Otros (artículos, cursos)',

    'dependency.updated': 'Dependency has been updated!',
    'coverage.updated': 'Coverage has been updated!',
    'source.one': 'Internal',
    'source.two': 'External',
    'access.one': 'It is difficult to access them (a few know where they are)',
    'access.two': 'It can be accessed with some ease',
    'access.three': 'It is easy to get them and take advantage of them',
    'reliability.one': 'The existing documents are not fully developed or updated',
    'reliability.two': 'There are several documents of this type but there is no clarity of the version',
    'reliability.three': 'The existing or available documents are well developed and we are certain of their validity / versioning',
    'relational.name': 'Name',
    'relational.agent': 'Agent Type',
    'relational.evaluation': 'Evaluation',
    'relational.frequency': 'Collaboration Frequency',
    'relational.competence': 'Competence Level',
    'relational.dependency': 'Dependency Level',
    'relational.add': 'Add Relational Capital',
    'relational.editing': 'Edit Relational Capital',
    'relational.saved': 'Relational Capital has been created!',
    'relational.updated': 'Relational Capital has been updated!',
    'relational.deleted': 'Relational Capital has been deleted!',
    'organization.title': 'Organization',
    'organizations.title': 'Organizations',
    'organizations.list_title': 'Listing organizations',
    'organizations.new': 'New Organization',
    'organizations.add': 'Add an Organization',
    'organizations.edit': 'Edit Organization',
    'organizations.delete': 'Delete Organization',
    'organizations.editing': 'Edit Organization',
    'organizations.name': 'Name',
    'organizations.name_message': 'Change organization name.',
    'organizations.description': 'Description',
    'organizations.saved': 'Organization has been created!',
    'organizations.updated': 'Organization has been updated!',
    'organizations.deleted': 'Organization has been removed!',
    'categories.title': 'Knowledge Categories',
    'category.title': 'Knowledge Category',
    'categories.list_title': 'Listing categories',
    'categories.new': 'New category',
    'categories.edit': 'Edit category',
    'categories.add': 'Add a Category',
    'categories.editing': 'Editing Category',
    'categories.saved': 'Category has been created!',
    'categories.updated': 'Category has been updated!',
    'categories.deleted': 'Category has been removed!',
    'users.title': 'Users',
    'users.list_title': 'Listing users',
    'users.new': 'New user',
    'users.fullname': 'Full Name',
    'users.email': 'Email',
    'users.password': 'Password',
    'users.password_confirmation': 'Password confirmation',
    'users.remember': 'Remember me',
    'users.edit': 'Edit user',
    'users.saved': 'User created, an invitation has been sent!',
    'permissions.edit': 'Edit',
    'permissions.view': 'View',
    'permissions.title': 'Permissions',
    'members': 'Members',
    'members.add': 'Invite',
    'profile.title': 'Profile',
    'profile.edit': 'Change your name',
    'profile.saved': 'Profile has been updated!',
    'password.edit': 'Change your password',
    'password.saved': 'Password has been updated!',
    'context.title': 'Context',
    'signup': 'Sign up',
    'signin': 'Sign in',
    'account': 'Account',
    'account.profile': 'Profile',
    'account.password': 'Password',
    'account.billing': 'Billing',
    'logout': 'Logout',
    'created': 'Created at',
    'back': 'Back',
    'save': 'Save',
    'delete': 'Delete',
    'cancel': 'Cancel',
    'close': 'Close',
    'more': 'View more',
    'switch': 'Switch',
    'rows_per_page': 'Rows per page:',
    'scale_values.low': 'Low',
    'scale_values.medium': 'Medium',
    'scale_values.high': 'High',
    'scale_values.very': 'Very high',
    'value': 'Value',
    'evaluation': 'Evaluation',
    'variable': 'Variable',
    'perpage': 'Rows per page:',
    'roletype.admin': 'Organization administrator',
    'roletype.spaces': 'Spaces Permissions',
    'analysis.ic': 'Criticidad de Dominios de Conocimiento',
    'analysis.if': 'Criticidad Funcional',
    'analysis.ispace': 'Matríz iSpace',
    'analysis.human': 'Capital Humano',
    'analysis.structural': 'Capital Estructural',
    'analysis.relational': 'Capital Relational',
    'analysis.radar': 'Detalle del índice de criticidad',
    'analysis.corporate': 'Conocimiento estructurado interno',
    'analysis.sectorial': 'Conocimiento público estructurado',
    'analysis.people': 'Know-How interno',
    'analysis.universal': 'Know how público',
    'level.one': 'Level 1',
    'level.two': 'Level 2',
    'level.three': 'Level 3',
    'level.four': 'Level 4',

    'accessibility.one': 'N/A',
    'accessibility.two': 'Muy baja',
    'accessibility.three': 'Deficiente',
    'accessibility.four': 'Mejorable',
    'accessibility.five': 'Adecuada',

    'coverage.one': 'N/A',
    'coverage.two': 'Muy baja',
    'coverage.three': 'Deficiente',
    'coverage.four': 'Mejorable',
    'coverage.five': 'Adecuada',

    'frequency.one': 'N/A',
    'frequency.two': 'Muy baja',
    'frequency.three': 'Esporádica',
    'frequency.four': 'Recurrente',
    'frequency.five': 'Continuada',

    'dependency.one': 'N/A',
    'dependency.two': 'Baja',
    'dependency.three': 'Media',
    'dependency.four': 'Alta',
    'dependency.five': 'Muy alta',

  },
  'es-ES': {
    'admin.title': 'Gestión',
    'dashboard.title': 'Dashboard',
    'spaces.title': 'Espacios',
    'spaces.list_title': 'Listando espacios',
    'spaces.new': 'Nuevo espacio',
    'spaces.name': 'Nombre',
    'spaces.description': 'Descripción',
    'spaces.type': 'Tipo',
    'spaces.purpose': 'Propósito / Función',
    'spaces.products': 'Productos que genera',
    'spaces.edit': 'Editar espacio',
    'spaces.domains': 'Dominios de Conocimiento',
    'spaces.info': 'Información',
    'spaces.activities': 'Actividades y Retos',
    'spaces.permissions': 'Usuarios y Permisos',
    'spaces.resources': 'Recursos de Conocimiento',
    'spaces.analysis': 'Análisis',
    'spaces.snapshots': 'Snapshots',
    'spaces.saved': 'Espacio creado!',
    'spaces.updated': 'Espacio actualizado!',
    'types.direction': 'Dirección',
    'types.area': 'Área/Departamento',
    'types.process': 'Proceso',
    'domains.new': 'Nuevo dominio',
    'domains.add': 'Añadir un dominio de conocimiento',
    'domains.editing': 'Editar dominio de conocimiento',
    'domains.head_title': 'Dominio de Conocimiento',
    'domains.title': 'Dominios de Conocimiento',
    'domains.list_title': 'Listando dominios',
    'domains.saved': 'Dominio de conocimiento creado!',
    'domains.updated': 'Dominio de conocimiento actualizado!',
    'domains.deleted': 'Dominio de conocimiento eliminado!',
    'interference.title': 'Interacción',
    'interference.subtitle': 'Relación de los dominios de conocimiento a las Actividades y Retos declaradas en el espacio.',
    'interference.asign': 'Asignar interacción',
    'interference.updated': 'Interacción actualizada!',


    'characterization.title': 'Caracterización',
    'characterization.subtitle': 'Cada dominio de conocimiento se caracterizará respondiendo a las siguientes variables.',
    'characterization.asign': 'Asignar Caracterización',
    'characterization.updated': 'Caracterización actualizada!',

    'characterization.complexity': 'Complejidad',
    'characterization.shortage': 'Escasez',
    'characterization.singularity': 'Singularidad',
    'characterization.applicability': 'Aplicabilidad',
    'characterization.risk': 'Riesgo',

    'characterization.complexity_one': 'Se requiere de mucha formación para desarrollar este conocimiento.',
    'characterization.complexity_two': 'Se requiere de mucha experiencia práctica para poder tener un nivel de dominio y aplicación adecuado de este conocimiento.',
    'characterization.complexity_three': 'La asimilación y aplicación de este conocimieno requiere de supervisión o apoyo de personas que conozcan el contexto particular de la organización.',

    'characterization.shortage_one': 'Este conocimiento lo dominan pocas personas en la organización.',
    'characterization.shortage_two': 'Existen pocos profesionales en el mercado con un nivel de  especialización adecuado.',
    'characterization.shortage_three': 'Externalizar este conocimiento es complejo, porque hay pocos proveedores, o los que hay están fuera de nuestro alcance, o porque necesitamos tener este know-how internamente.',

    'characterization.singularity_one': 'Este dominio de conocimiento está muy vinculado al propio saber-hacer y experiencia (es muy tácito o dificil de documentar).',
    'characterization.singularity_two': 'Aunque este conocimiento aunque puede ser documentado, no tenemos ninguna traza del mismo, lo que dificulta su divulgación.',
    'characterization.singularity_three': 'Este conocimiento está poco difundido en la industria, bien porque somos pioneros en su desarrollo, o porque está muy vinculado a condiciones particulares de nuestra organización.',

    'characterization.applicability_one': 'Este conocimiento es pertinente para el desarrollo de actividades clave de la organización, por lo que si no contamos con él se generarían paradas en procesos importantes.',
    'characterization.applicability_two': 'Disponer de este conocimiento nos aporta ventajas competitivas (nos diferencia de nuestros competidores).',
    'characterization.applicability_three': 'Este conocimiento seguirá siendo relevante para el desarrollo de retos futuros previstos en la estrategia de la organización.',

    'characterization.risk_one': 'La industria tiene una alta demanda este conocimiento (el mercado laboral y de contratación de servicios está en auge).',
    'characterization.risk_two': 'Existen previsiones o condiciones que pueden acelerar la desvinculación de personas con experiencia en este conocimiento (promociones, jubilaciones, reconfiguración de la organización).',
    'characterization.risk_three': 'Existen nuevas tendencias (tecnológicas, de mercado) que acelerarán la obsolesencia de este conocimiento.',

    'activities.title': 'Actividades',
    'activities.add': 'Añadir una actividad',
    'activities.editing': 'Editar actividad',
    'activities.head_title': 'Actividad',
    'activities.saved': 'Actividad creada!',
    'activities.updated': 'Actividad actualizada!',
    'activities.deleted': 'Actividad eliminada!',
    'challenges.saved': 'Reto creado!',
    'challenges.updated': 'Reto actualizado!',
    'challenges.deleted': 'Reto eliminado!',
    'challenges.title': 'Retos',
    'challenges.add': 'Añadir un reto',
    'challenges.editing': 'Editar reto',
    'challenges.head_title': 'Reto',
    'challenges.impact': 'Nivel de impacto en oferta de valor',
    'challenges.complexity': 'Nivel de complejidad',
    'challenges.need': 'Necesidad de atención / mejora',

    'snapshots.title': 'Snapshots',
    'snapshots.add': 'Crear Snapshot',
    'snapshots.editing': 'Editar Snapshot',
    'snapshots.head_title': 'Nombre del Snapshot',
    'snapshots.saved': 'Snapshot creado!',
    'snapshots.updated': 'Snapshot actualizado!',
    'snapshots.deleted': 'Snapshot eliminado!',

    'resources.profile': 'Perfiles',
    'resources.person': 'Personas',
    'resources.human': 'Capital Humano',
    'resources.structural': 'Capital Estructural',
    'resources.relational': 'Capital Relacional',
    'human.name': 'Nombre',
    'human.charge': 'Perfil / Cargo',
    'human.age': 'Rango de Edad',
    'human.senority': 'Antigüedad en la Organización',
    'human.experience': 'Experiencia Laboral Acumulada',
    'human.risk': 'Riesgo de Desvinculación',
    'human.rotation': 'Nivel de Rotación',
    'human.evaluation': 'Evaluación',
    'human.skill': 'Nivel de Competencia',
    'human.add': 'Nuevo Capital Humano',
    'human.editing': 'Editar Capital Humano',
    'human.saved': 'Capital Humano creado!',
    'human.updated': 'Capital Humano actualizado!',
    'human.deleted': 'Capital Humano eliminado!',

    'profile.name': 'Nombre',
    'profile.people': 'Número de personas estimadas para el espacio',
    'profile.skill': 'Nivel de Competencia',
    'profile.add': 'Nuevo Perfil',
    'profile.editing': 'Editar Perfil',
    'profile.created': 'Perfil creado!',
    'profile.updated': 'Perfil actualizado!',
    'profile.deleted': 'Perfil eliminado!',

    'age.one': '20 - 25',
    'age.two': '25 - 30',
    'age.three': '30 - 40',
    'age.four': '40 - 50',
    'age.five': '> 50',
    'experience.one': '< 2 años',
    'experience.two': '2 - 5 años',
    'experience.three': '5 - 10 años',
    'experience.four': '> 10 años',
    'risk.one': 'Sin riesgo',
    'risk.two': 'Posible promoción / rotación',
    'risk.three': 'Posible baja / reducción jornada',
    'risk.four': 'Desincorporación por Jubilación',
    'risk.five': 'Desincorporación por otras causas',
    'rotation.one': 'Muy bajo',
    'rotation.two': 'Bajo',
    'rotation.three': 'Medio',
    'rotation.four': 'Alto',
    'skill.one': 'No aplica',
    'skill.two': 'En desarrollo',
    'skill.three': 'Conoce',
    'skill.four': 'Aplica',
    'skill.five': 'Desarrolla',
    'skill.six': 'Supervisa / Mejora',
    'competence.one': 'Los documentos existentes aportan poca información',
    'competence.two': 'La mayoría de las necesidades de información están cubiertas con los documentos existentes',
    'competence.three': 'El dominio de conocimiento está plenamente cubierto con este tipo de contenido',
    'competence.updated': 'Competencia actualizada!',
    'agent.one': 'Cliente',
    'agent.two': 'Colaborador (socio tecnológico / centro de investigación / partner comercial)',
    'agent.three': 'Proveedor (de servicios o insumos)',
    'agent.four': 'Red / Foro / Asociación',
    'structural.name': 'Nombre',
    'structural.type': 'Tipo de Recurso',
    'structural.source': 'Fuente',
    'structural.access': 'Accesibilidad',
    'structural.reliability': 'Fiabilidad',
    'structural.evaluation': 'Evaluación',
    'structural.coverage': 'Cobertura',
    'structural.add': 'Nuevo Capital Estructural',
    'structural.editing': 'Editar Capital Estructural',
    'structural.saved': 'Capital Estructural creado!',
    'structural.updated': 'Capital Estructural actualizado!',
    'structural.deleted': 'Capital Estructural eliminado!',
    'relational.name': 'Nombre',
    'relational.agent': 'Tipo de Agente',
    'relational.evaluation': 'Evaluación',
    'relational.competence': 'Nivel de Competencia',
    'relational.frequency': 'Frecuencia de Colaboración',
    'relational.dependency': 'Dependencia',
    'relational.add': 'Nuevo Capital Relacional',
    'relational.editing': 'Editar Capital Relacional',
    'relational.saved': 'Capital Relacional creado!',
    'relational.updated': 'Capital Relacional actualizado!',
    'relational.deleted': 'Capital Relacional eliminado!',
    'type.one': 'Manual / Procedimiento',
    'type.two': 'Normativa / Leyes',
    'type.three': 'Especificaciones',
    'type.four': 'Modelos / Formulas',
    'type.five': 'Otros (artículos, cursos)',

    'dependency.updated': 'Dependencia actualizada!',
    'coverage.updated': 'Cobertura actualizada!',
    'source.one': 'Interna',
    'source.two': 'Externa',
    'access.one': 'Es dificil acceder a ellos (unos pocos saben donde estan)',
    'access.two': 'Se puede acceder con cierta facilidad',
    'access.three': 'Es facil conseguirlos y aprovecharlos',
    'reliability.one': 'Los documentos existentes no están del todo desarrollados o actualizados',
    'reliability.two': 'Existen varios documentos de este tipo pero no hay claridad del versionado',
    'reliability.three': 'Los documentos existentes o disponibles están bien desarrollados y tenemos certeza de su vigencia/versionado',
    'organization.title': "Organización",
    'organizations.title': 'Organizaciones',
    'organizations.list_title': 'Listando organizaciones',
    'organizations.new': 'Nueva Organización',
    'organizations.add': 'Añadir una Organization',
    'organizations.edit': 'Editar Organización',
    'organizations.delete': 'Eliminar Organización',
    'organizations.editing': 'Editar Organización',
    'organizations.name': 'Nombre',
    'organizations.name_message': 'Cambiar nombre de la organización.',
    'organizations.description': 'Descripción',
    'organizations.saved': 'Se ha creado la Organización!',
    'organizations.updated': 'Se ha actualizado la Organización!',
    'organizations.deleted': 'Se ha eliminado la Organización!',
    'categories.title': 'Categorías',
    'category.title': 'Categoría',
    'categories.list_title': 'Listando categorías',
    'categories.new': 'Nueva categoría',
    'categories.edit': 'Editar categoría',
    'categories.add': 'Añadir una categoría',
    'categories.editing': 'Editar categoría',
    'categories.saved': 'Se ha creado la Categoría!',
    'categories.updated': 'Se ha actualizado la Categoría!',
    'categories.deleted': 'Se ha eliminado la Categoría!',
    'users.title': 'Usuarios',
    'users.list_title': 'Listando usuarios',
    'users.fullname': 'Nombre Completo',
    'users.email': 'Correo Electrónico',
    'users.password': 'Contraseña',
    'users.password_confirmation': 'Repetir contraseña',
    'users.remember': 'Recordarme',
    'users.edit': 'Editar usuario',
    'users.new': 'Nuevo usuario',
    'users.saved': 'Usuario creado, se le ha enviado la invitación!',
    'users.permissions_message': 'Derechos en cada espacio',
    'permissions.title': 'Permisos',
    'permissions.edit': 'Editar',
    'permissions.view': 'Ver',
    'members.title': 'Miembros',
    'members.add': 'Invitar',
    'profile.title': 'Perfil',
    'profile.edit': 'Cambia tu nombre',
    'profile.saved': 'El perfil se ha actualizado!',
    'password.edit': 'Cambia tu contraseña',
    'password.saved': 'El password se ha actualizado!',
    'context.title': 'Contexto',
    'signup': 'Registrarse',
    'signin': 'Iniciar sesión',
    'account': 'Cuenta',
    'account.profile': 'Perfil',
    'account.password': 'Contraseña',
    'account.billing': 'Facturación',
    'logout': 'Cerrar sesión',
    'forgot': 'Recuperar contraseña',
    'privacy': 'Política de privacidad',
    'reset': 'Cambiar contraseña',
    'required': 'Requerido',
    'created': 'Creado',
    'back': 'Volver',
    'save': 'Guardar',
    'export': 'Exportar a Excel',
    'delete': 'Eliminar',
    'cancel': 'Cancelar',
    'close': 'Cerrar',
    'more': 'Ver más',
    'switch': 'Cambiar',
    'country': 'País',
    'sector': 'Sector',
    'rows_per_page': 'Registros por página:',
    'scale_values.low': 'Bajo',
    'scale_values.medium': 'Medio',
    'scale_values.high': 'Alto',
    'scale_values.very': 'Muy alto',
    'value': 'Valor',
    'evaluation': 'Evaluación',
    'variable': 'Variable',
    'perpage': 'Filas por página:',
    'roletype.admin': 'Administrador de la organización',
    'roletype.spaces': 'Permisos por espacio',
    'analysis.ic': 'Criticidad de Dominios de Conocimiento',
    'analysis.if': 'Criticidad Funcional',
    'analysis.ispace': 'Matríz iSpace',
    'analysis.human': 'Capital Humano',
    'analysis.structural': 'Capital Estructural',
    'analysis.relational': 'Capital Relacional',
    'analysis.radar': 'Detalle del índice de criticidad',
    'analysis.corporate': 'Conocimiento estructurado interno',
    'analysis.sectorial': 'Conocimiento público estructurado',
    'analysis.people': 'Know-How interno',
    'analysis.universal': 'Know-How público',
    'level.one': 'Nivel 1. Máxima criticidad (IC > 8,5).',
    'level.two': 'Nivel 2. Criticidad alta (IC de 6,5 a 8,5).',
    'level.three': 'Nivel 3. Criticidad media (IC de 4 a 6,5).',
    'level.four': 'Nivel 4. Criticidad baja (IC < 4).',

    'accessibility.one': 'N/A',
    'accessibility.two': 'Muy baja',
    'accessibility.three': 'Deficiente',
    'accessibility.four': 'Mejorable',
    'accessibility.five': 'Adecuada',

    'coverage.one': 'N/A',
    'coverage.two': 'Muy baja',
    'coverage.three': 'Deficiente',
    'coverage.four': 'Mejorable',
    'coverage.five': 'Adecuada',

    'frequency.one': 'N/A',
    'frequency.two': 'Muy baja',
    'frequency.three': 'Esporádica',
    'frequency.four': 'Recurrente',
    'frequency.five': 'Continuada',

    'dependency.one': 'N/A',
    'dependency.two': 'Baja',
    'dependency.three': 'Media',
    'dependency.four': 'Alta',
    'dependency.five': 'Muy alta',
  }
};