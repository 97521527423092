import auth from '../Auth';

class User {
  constructor() {
    this.getList = this.getList.bind(this)
    this.getOrganizationList = this.getOrganizationList.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.updateName = this.updateName.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'users', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async getOrganizationList(organization_id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations/' + organization_id + '/users/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async create(name, organization_id, email, roleType, permissions, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'users/invitation', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          user: { name: name, organization_id: organization_id, email: email, role_type: roleType, spaces: permissions }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async update(id, name, organization_id, email, roleType, permissions, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'users', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, user: { name: name, organization_id: organization_id, email: email, role_type: roleType, spaces: permissions }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async updatePermissions(id, name, roleType, permissions, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'users/' + id + '/update_permissions', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, user: { name: name, role_type: roleType, spaces: permissions }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async updateName(id, name, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'users/' + id + '/update_name', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, user: { name: name }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async updatePassword(id, password, password_confirmation, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'users/' + id + '/update_password', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, user: { password: password, password_confirmation: password_confirmation }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async show(id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'users/' + id, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async delete(ids, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'users', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'DELETE',
        body: JSON.stringify({
          user: { ids: ids }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

}

export default new User();