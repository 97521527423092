import auth from '../../Auth';

class Domain {
  constructor() {
    this.getList = this.getList.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getSpaceList(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'spaces/' + id + '/domains/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  create(title, category, space_id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'POST',
      body: JSON.stringify({
        domain: { name: title, category: category, space_id: space_id }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  async update(id, title, category, space_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'domains', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, domain: { name: title, category: category, space_id: space_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(ids, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        domain: { ids: ids }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  saveInterference(id, interferenceActivity, interferenceChallenge, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'interferences/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'PUT',
      body: JSON.stringify({
        id: id, domain: { interference_activity: interferenceActivity, interference_challenge: interferenceChallenge }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  saveCharacterization(id, characterization, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'characterizations/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'PUT',
      body: JSON.stringify({
        id: id, domain: { characterization: characterization }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  getInterference(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'interferences/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getCharacterization(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains/' + id + '/characterizations/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

}

export default new Domain();