import auth from '../Auth';

class Organization {
  constructor() {
    this.getList = this.getList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
  }

  async getList(cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async create(name, description, country, sector, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          organization: { name: name, description: description, country: country, sector: sector }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async update(id, name, description, country, sector, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, organization: { name: name, description: description, country: country, sector: sector }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async show(id, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations/' + id, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'GET'
      });
      const data = await res.json();
      return data;
    }
    catch (error) {
      return console.log(error);
    }
  }

  async delete(id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'organizations', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'DELETE',
        body: JSON.stringify({
          organization: { id: id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

}

export default new Organization();