import React from 'react';
import PropTypes from 'prop-types';
import { KMapContext } from '../KMapProvider';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import space from './Space';
import { FormattedMessage } from 'react-intl';

const styles = {
  card: {
    minWidth: 140,
    maxWidth: 280,
    minHeight: 230,
    maxHeight: 230,
    backgroundColor: '#e1f5fe'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  selectControl: {
    width: '100%',
  },
  dialogStyle: {
    borderRadius: 0
  }
};

let counter = 0;
function createData(oid, name, type, organization, purpose, products, created_at) {
  counter += 1;
  return { id: counter, oid, name, type, organization, purpose, products, created_at };
}

const types = [
  'Área',
  'Proceso',
  'Proyecto',
  'Tópico',
];

class SpaceForm extends React.Component{

  static contextType = KMapContext;

  constructor (props) {
    super(props);
    this.state = {
      name: '',
      organization_id: '',
      organizations: [],
      type: '',
      purpose: '',
      products: '',
      fullWidth: true,
      maxWidth: 'sm',
      submitActionTitle: <FormattedMessage id="spaces.new" />
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
    this.handlePurposeChange = this.handlePurposeChange.bind(this);
    this.handleProductsChange = this.handleProductsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  } 

  componentDidMount() {
    space.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) =>
      this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.name, n.type, n.organization, n.purpose, n.products, n.created_at)) }))
    );
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleTypeChange(event) {
    this.setState({
      type: event.target.value
    })
  }

  handleOrgChange(event) {
    this.setState({
      organization_id: event.target.value
    })
  }

  handlePurposeChange(event) {
    this.setState({
      purpose: event.target.value
    })
  }

  handleProductsChange(event) {
    this.setState({
      products: event.target.value
    })
  }

  handleClick = (event, id, oid) => {
    this.props.history.push("/spaces/" + oid);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.submitAction === 'new') {
      space.create(this.state.name, this.state.type, localStorage.getItem('currentOrganization'), this.state.purpose, this.state.products, () => {
        this.setState(data => ({
            id: '',
            name: '',
            organization_id: '',
            type: '',
            purpose: '',
            products: '',
            SnackMessage: <FormattedMessage id="spaces.saved" />,
            openSnack: true
          }));
          this.props.updateListAction();
          this.props.history.push('/spaces');
        }
      )
    } else {
      space.update(this.state.id, this.state.name, this.state.type, localStorage.getItem('currentOrganization'), this.state.purpose, this.state.products, () => {
        space.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) => {
          this.setState(data => ({
              data: result.map(n => createData(n._id.$oid, n.name, n.type, n.organization, n.purpose, n.products, n.created_at)),
              id: '',
              name: '',
              organization_id: '',
              type: '',
              purpose: '',
              products: '',
              SnackMessage: <FormattedMessage id="spaces.updated" />,
              openSnack: true
            }));
            this.props.actionClose();
          }
        )
      })
    }
  }

  handleDelete = () => {
    space.delete(this.state.OIDselected).then((result) =>
    space.getOrganizationList(localStorage.getItem('currentOrganization')).then((result) => 
        this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.name, n.type, n.organization, n.purpose, n.products, n.created_at)), selected: [] })
      ))
    );
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.props.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name"><FormattedMessage id="spaces.name" /></InputLabel>
                <Input id="name" value={this.state.name} onChange={this.handleNameChange} autoFocus />
              </FormControl>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="select-multiple"><FormattedMessage id="spaces.type" /></InputLabel>
                <Select
                  value={this.state.type}
                  onChange={this.handleTypeChange}
                  input={<Input name="type" id="type-helper" />}
                  MenuProps={{ classes: { paper: classes.dialogStyle } }}
                >
                  {types.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>          
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="purpose"><FormattedMessage id="spaces.purpose" /></InputLabel>
                <Input id="purpose" value={this.state.purpose} onChange={this.handlePurposeChange} multiline={true} rows="4" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="products"><FormattedMessage id="spaces.products" /></InputLabel>
                <Input id="products" value={this.state.products} onChange={this.handleProductsChange} multiline={true} rows="4" />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.actionClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

SpaceForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpaceForm);
